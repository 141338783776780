/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Button, ButtonGroup,
    Image,
    Carousel,
    Card,
}   from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import color from '../_common/colors.json'
// import {Offer} from './_common'

/**
 */
class Home_Offers extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )
    }

    /**
     */
    render()
    {
        // // console.log( "Home_Offers : render : props : ", this.props );

        if( !this.props.__biz.offers || !this.props.__biz.offersFeatured )
        {
            return null
        }

        return (
            <Carousel indicators={false} controls={false} style={{backgroundColor:"rgba(0,0,0,0.4)",padding:16}}>
            {
                this.props.__biz.offersFeatured.map( id => {
                    const x = this.props.__biz.offers.find( x => x._id === id )

                    if( !x )
                        return null

                    return (
                        <Carousel.Item>
                            <Card style={{backgroundColor:"rgba(0,0,0,0.4)"}}>
                                <div style={{display:"flex",flexDirection:"row",padding:16,justifyContent:"center"}}>
                                    <div style={{height:110,width:110}}>
                                        <Card.Img style={{height:100,objectFit:"cover"}}src="https://c1.staticflickr.com/5/4529/37785342245_49309ca731_b.jpg" alt="Card image" />
                                    </div>
                                    <div style={{flex:1,textAlign:"left",marginLeft:10}}>
                                        <h2>Special Offer</h2>
                                        <h4>
                                        {x.title}
                                        </h4>
                                        <h6>
                                        {x.price}
                                        </h6>
                                    </div>
                                </div>
                            </Card>
                        </Carousel.Item>
                        )
                } )
            }
            </Carousel>
            )
    }   // render
}   // Home_Offers

/**
 */
const mapStateToProps = (state) => {
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Home_Offers )





