import React from 'react'
import {
    Image,
    Button,
    Row,
    Col,
    Modal, Form , Table
} from 'react-bootstrap'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import NavHdr from '../_common/nav_hdr'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import color from '../_common/colors.json'
import '../../css/_common.css'
import Address_Details from './address'
import Card_Details from './card'
import Order_History from './orderHistory'
import {
    faEnvelope,
    faSave,
    faTimes,
    faPhoneVolume,
    faMapMarked,
    faClock,
    faEdit,
    faUtensils,
    faPowerOff,
    faUser
} from '@fortawesome/free-solid-svg-icons'
import {Core} from "../../api";
import {navigate} from "@reach/router";
import {TextInput} from "../_common/components";
import {PickerEx} from "../_common/picker";
// import Map from 'google-maps-react';
import MapContainer from '../_common/map_container';

const titleList = [
    {label: "Mr", val: "Mr", key: "Mr"},
    {label: "Ms", val: "Ms", key: "Ms"},
    {label: "Mrs", val: "Mrs", key: "Mrs"}

]
/**
 */
class Profile_Head extends React.PureComponent
{

    constructor( props )
    {
        super( props )
    }

    render()
    {
        // console.log("profile heaD PROPS",this.props);
        return (
            <div className={this.props.className}>
                <div>
                    {
                        this.props.img ?
                            <Image className="infoImage"
                                   src={this.props.img}
                                   rounded/>:
                            <div className="faviconDiv">
                                <div className="faviconText">{this.props.biz.biz_name.charAt(0)}</div>
                            </div>
                    }
                </div>
                <div className="infoMain">
                    {
                        this.props.userName ?
                            <div className="infoTitle"
                                 style={{color: color.colors.primary}}>{this.props.userName}</div>:
                            null
                    }
                    {
                        this.props.businessTitle ?
                            <div className="infoTitle"
                                 style={{fontSize:32}}>{this.props.businessTitle}</div>:
                            null
                    }
                    {
                        this.props.businessSubTitle ?
                            <h6 className="infoSubTitle"
                                style={{color: color.colors.light}}>{this.props.businessSubTitle}</h6>:
                            null
                    }
                    {
                        this.props.userPhone ?
                            <div className="commonFlex middlePhone">
                                <FontAwesomeIcon
                                    style={{marginRight: 8,color:"#303030"}} icon={faPhoneVolume} size="lg"/>
                                <div className="infoContact">{this.props.userPhone}</div>
                            </div>:
                            null
                    }
                    {
                        this.props.address ?
                            <div className="commonFlex middlePhone">
                                <FontAwesomeIcon
                                    style={{marginRight: 8,color:"#303030"}} icon={faMapMarked} size="lg"/>
                                <div className="infoSubTitle">{this.props.address}</div>
                            </div>:
                            null
                    }
                    {
                        this.props.userEmail ?
                            <h6 className="infoSubTitle" style={{color: color.colors.light}}>
                                <FontAwesomeIcon style={{marginRight: 16}} icon={faEnvelope} size="sm"/>
                                {this.props.userEmail}</h6> :
                            null
                    }
                    {
                        this.props.openingTime ?
                            <h6 className="infoSubTitle" style={{color: color.colors.light}}><FontAwesomeIcon
                                style={{marginRight: 16}} icon={faClock} size="sm"/>{this.props.openingTime}</h6> :
                            null
                    }
                    {
                        this.props.description ?
                            <div className="infoDescription">
                                {this.props.description}</div> :
                            null
                    }

                    {/*<h6 className="infoSubTitle" style={{color: color.colors.light}}><FontAwesomeIcon*/}
                        {/*style={{marginRight: 16}} icon={faCreditCard} size="sm"/>**** **** **** 4242</h6>*/}
                </div>
                <div className="infoDetails mapContainer" >
                    <MapContainer {...this.props} />
                    {/* <Map
                        google={this.props.google}
                        // style={style}
                        initialCenter={{
                            lat: 40.854885,
                            lng: -88.081807
                        }}
                        zoom={15}
                        // onClick={this.onMapClicked}
                    /> */}
                </div>
            </div>
        )
    }
}

/**
 */
class Details_Box extends React.PureComponent
{
    constructor( props )
    {
        super( props )
    }

    render()
    {
        return (
            <div
                onClick={this.props.activeItem}
                className="profileDetailsDiv"
                style={this.props.style}
            >
                <div className="innerProfileDetails">
                    <FontAwesomeIcon icon={this.props.icon} size="lg"
                                     color={this.props.active ? "#fff" : "#7E808C"}/>
                    <div className="infoDescription" style={{color:this.props.active ? "#fff" : "#7E808C"}}>{this.props.title}</div>
                </div>
            </div>
        )
    }
}

/**
 */
class Data_Details_Box extends React.PureComponent
{
    constructor( props )
    {
        super( props )
    }

    //
    render()
    {
        const {item} = this.props

        return (
            <Col xs="12" md="12" lg="12" key={this.props.key}>
                <div className="bodyDetailsDiv">
                    {
                        item.line1 ?
                        <div style={{flex: 1}}>
                            <div className="infoDescription">itemess</div>
                            <div className="valueProfile"> {item.line1}{item.line2}</div>
                        </div> :
                        null
                    }
                    {
                        item.city ?
                        <div style={{flex: 1}}>
                            <div className="infoDescription">City</div>
                            <div className="valueProfile">{item.city}</div>
                        </div> :
                        null
                    }
                    {
                        item.county || item.countY ?
                            <div style={{flex: 1}}>
                                <div className="infoDescription">County</div>
                                <div className="valueProfile">
                                    {item.county ? item.county : item.countY }
                                </div>
                            </div> : null
                    }
                    {
                        item.card && item.card.brand ?
                        <div style={{flex: 1}}>
                            <div className="infoDescription">Brand</div>
                            <div className="valueProfile">{item.card.brand.toUpperCase()}</div>
                        </div> :
                        null
                    }
                    {
                        item.card && item.card.last4 ?
                        <div style={{flex: 1}}>
                            <div className="infoDescription">Card Number</div>
                            <div className="valueProfile">**** **** **** {item.card.last4}</div>
                        </div> :
                        null
                    }
                    {
                        item.card && item.card.exp_month  ?
                            <div style={{flex:1}}>
                                <div className="infoDescription">Expiry Date</div>
                                <div className="valueProfile">{item.card.exp_month}/{item.card.exp_year}</div>
                            </div>:
                            null
                    }
                    {
                        item.order_id?
                        <div style={{flex: 1}}>
                            <div className="infoDescription">Order Id</div>
                            <div className="valueProfile">{item.order_id.replace(/^.+_/,'')}</div>
                        </div> : null
                    }
                    {
                        this.props.date ?
                            <div style={{flex: 1}}>
                                <div className="infoDescription">Date</div>
                                <div className="valueProfile">{this.props.date}</div>
                            </div> :
                            null
                    }
                    {
                        item.postal_code ?
                            <div style={{flex: 1}}>
                                <div className="infoDescription">Postal Code</div>
                                <div className="valueProfile">{item.postal_code}</div>
                            </div> :
                            null
                    }
                    <div>
                        {
                            this.props.edit ?
                                <Button variant="warning" className="bodyDetailsButton">
                                    Edit
                                </Button> :
                                null
                        }

                        {
                            this.props.delete ?
                                <Button onClick={ ()=> this.props.OnDelete(item)}
                                        variant="danger"
                                        className="bodyDetailsButton"
                                >
                                    Delete
                                </Button>
                                :
                                null
                        }
                    </div>
                </div>
            </Col>
        )
    }
}

/**
 */
class History_Details_Box extends React.PureComponent
{
    constructor( props )
    {
        super( props )
    }

    //
    render()
    {
        const {item} = this.props

        return (
            <Col xs="12" md="12" lg="12" key={this.props.key}>
                <div className="bodyDetailsDiv">

                    {
                        item.order_id?
                            <div style={{flex: 1}}>
                                <div className="infoDescription">Order Id</div>
                                <div className="valueProfile">{item.order_id.split('_')[3]}</div>
                            </div> : null
                    }
                    {
                        this.props.date ?
                            <div style={{flex: 1}}>
                                <div className="infoDescription">Date</div>
                                <div className="valueProfile">{this.props.date}</div>
                            </div> :
                            null
                    }
                    {
                        item.invoice ?
                            <div style={{flex: 1}}>
                                <div className="infoDescription">Price</div>
                                <div className="valueProfile">£{item.invoice.Total.toFixed(2)}</div>
                            </div> :
                            null
                    }
                    {
                        item.respond ?
                            <div style={{flex: 1}}>
                                <div className="infoDescription">Status</div>
                                <div className="valueProfile">{item.respond.charAt(0).toUpperCase() + item.respond.slice(1)}</div>
                            </div> :
                            null
                    }

                </div>
            </Col>
        )
    }
}

/**
 */
class ProImage extends React.PureComponent
{

    constructor( props )
    {
        super( props )
    }

    render()
    {
        return (
            <div>
                {
                    this.props.img ?
                        <Image className="infoImage"
                               src={this.props.img}
                               rounded
                        /> :
                        <FontAwesomeIcon
                            style={{marginRight: 8,color:"#303030"}} icon={faUser} size="3x"/>
                }
            </div>


        )
    }
}
/**
 */
class Name extends React.PureComponent
{

    constructor( props )
    {
        super( props )
        this.state = {
            b_edit : false
        }
    }

    render()
    {
        const {title ,first , last , username} = this.props

        return (

            <div>

                {
                    this.state.b_edit ?

                        <div>
                            <div className="textBoxDiv">
                                <PickerEx
                                    items={titleList}
                                    prompt={"Title"}
                                    val={title}
                                    bSearch={true}
                                    onChange={(val) => this.props.onChange('title', val) }
                                    className="textBoxProfile"
                                />
                                <TextInput
                                    placeholder="First"
                                    type="text"
                                    size="lg"
                                    val={first}
                                    onChange={(val) => this.props.onChange('first', val.target.value) }
                                    className="textBoxProfile"
                                />
                                <TextInput
                                    placeholder="Last"
                                    type="text"
                                    size="lg"
                                    val={last}
                                    onChange={(val) => this.props.onChange('last', val.target.value) }
                                    className="textBoxProfile"
                                />
                            </div>
                            <div className="buttonDiv">
                                <div
                                    onClick={this.SaveName}
                                    className="iconButton">
                                    <FontAwesomeIcon icon={faSave} color="green" size="md"/>
                                </div>
                                <div
                                    onClick={() => this.setState({b_edit: false}) }
                                    className="iconButton">
                                    <FontAwesomeIcon icon={faTimes} color="red" size="md"/>
                                </div>

                                    {/*<Button onClick={this.props.SaveName} variant="success">*/}
                                    {/*Save*/}
                                {/*</Button>*/}

                                {/*<Button onClick={() => this.setState({b_edit: false}) } variant="danger">*/}
                                    {/*Cancel*/}
                                {/*</Button>*/}
                            </div>
                        </div>

                        :

                        <div className="nameDiv">
                            <div className="infoTitle">
                                { username.title || username.first || username.last ? [username.title, username.first, username.last].join(' ') : "No Name Provided" }
                            </div>

                            <FontAwesomeIcon
                                onClick={() => {
                                this.props.editName()
                                this.setState({b_edit: true})
                            }} style={{marginLeft: 16,cursor:"pointer"}} icon={faEdit} color="#808080" size="md"/>
                            {/*<Button onClick={() =>{ this.props.editName()*/}
                            {/*this.setState({b_edit: true} )}}*/}
                            {/*variant="success"*/}
                            {/*>*/}
                            {/*Edit*/}
                            {/*</Button>*/}

                        </div>


                }

            </div>
        )
    }

    SaveName = ()=>{

        this.setState({b_edit: false})
        this.props.SaveName()
    }
}
/**
 */
class Email extends React.PureComponent
{

    constructor( props )
    {
        super( props )
        this.state = {
            b_edit : false
        }
    }

    render()
    {

        const {edit_email , email} = this.props

        return (

            <div>

                {
                    this.state.b_edit ?

                        <div>
                            <div className="textBoxDiv">
                                <TextInput
                                        placeholder="Email"
                                        type="text"
                                        size="lg"
                                        val={edit_email}
                                        onChange={(val) => this.props.onChange('edit_email', val.target.value) }
                                        className="textBoxProfile"
                                    />
                            </div>
                            <div className="buttonDiv">
                                <div
                                    onClick={this.SaveEmail}
                                    className="iconButton">
                                    <FontAwesomeIcon icon={faSave} color="green" size="md"/>
                                </div>
                                <div
                                    onClick={() => this.setState({b_edit: false}) }
                                    className="iconButton">
                                    <FontAwesomeIcon icon={faTimes} color="red" size="md"/>
                                </div>
                            </div>
                            {/*<Button onClick={this.props.SaveEmail} variant="success">*/}
                            {/*Save*/}
                            {/*</Button>*/}

                            {/*<Button onClick={() => this.setState({b_edit: false}) } variant="danger">*/}
                            {/*Cancel*/}
                            {/*</Button>*/}
                        </div>

                        :

                        <div  className="nameDiv">
                            <div className="infoTitle" style={{color: color.colors.primary}}>
                                {this.props.email ? email : "No Email Saved"}
                            </div>

                            <Form.Group>
                                <FontAwesomeIcon
                                    onClick={() => {
                                        this.props.emailEdit()
                                        this.setState({b_edit: true})
                                    }} style={{marginLeft: 16,cursor:"pointer"}} icon={faEdit} color="#808080" size="md"/>

                            </Form.Group>

                        </div>


                }

            </div>
        )
    }

    SaveEmail = ()=>{

        this.setState({b_edit: false})
        this.props.SaveEmail()

    }
}


/**
 */
class Opening_Time extends React.PureComponent
{
    constructor( props )
    {
        super( props )
        this.days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    }

    render()
    {
        const {ot} = this.props

        console.log("otttttt", ot)

        return (

            <div style={{margin: 20}} >

                <div style={{margin: 10}} className="infoTitle" >Opening Time</div>

                <Table responsive  size={'sm'}>
                    <tbody>

                       {/* <tr>
                            <td>
                                <th>days</th>
                            </td>
                            <td colspan="4">
                                <th>Midnight</th>
                            </td>
                            <td>
                                <th>Breakfast</th>
                            </td>
                            <td>
                                <th>Lunch</th>
                            </td>
                            <td>
                                <th>Dinner</th>
                            </td>
                        </tr>*/}
                {
                    Object.keys(ot.days).map(obj => (


                        <tr key={obj} >
                            <th className="otDays">{this.days[obj]}</th>

                        {
                            ot.days[obj].map(opt => (


                            <td className="otDiv" key={opt.name} >
                                <th className="otSession" style={{display:!opt.if_open ? "none":"block"}}>{opt.name ? opt.name: "closed"}</th>
                                {/*{*/}
                                    {/*!opt.if_open ?*/}
                                        {/*<tr>*/}
                                            {/*{"Closed"}*/}
                                        {/*</tr> : null*/}

                                {/*}*/}

                                {
                                    opt.if_open ?
                                        <tr className="otTime">{ opt.open.join(':') +" to " + opt.close.join(':') }</tr> : null

                                }
                            </td>

                            ))
                        }
                        </tr>

                    ))

                }
                    </tbody>
                </Table>


            </div>
        )
    }

}
//
// <Table striped bordered hover variant="dark">
//     <thead>
//     <tr>
//         <th>#</th>
//         <th>First Name</th>
//         <th>Last Name</th>
//         <th>Username</th>
//     </tr>
//     </thead>
//     <tbody>
//     <tr>
//         <td>1</td>
//         <td>Mark</td>
//         <td>Otto</td>
//         <td>@mdo</td>
//     </tr>
//     <tr>
//         <td>2</td>
//         <td>Jacob</td>
//         <td>Thornton</td>
//         <td>@fat</td>
//     </tr>
//     <tr>
//         <td>3</td>
//         <td colSpan="2">Larry the Bird</td>
//         <td>@twitter</td>
//     </tr>
//     </tbody>
// </Table>
/**
 */
export{Profile_Head,Details_Box,Data_Details_Box, Name , Email , ProImage , Opening_Time,History_Details_Box}
