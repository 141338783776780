import React from 'react'
import {
    Image,
    Button,
    Row,
    Col,
    Form,
    Modal
} from 'react-bootstrap'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import '../../css/_common.css'

import AddNew_Card from './addNewCard'
import {Lambda, Utils} from "../../api";
// import elml_cfg from "../../_config/elml_cfg";
import ContentLoader from 'react-content-loader'
import {Data_Details_Box} from './_common'
import {Profile} from "./profile"
import Loader from 'react-loader-spinner'

/**
 */
class Card_Details extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )

        this.state = {
                newCard:false,
                cardList: [],
                bloading: false,
            }
    }   // cstr

    /**
     */
    componentDidMount ()
    {
        this.LoadCardList()
            .then( data=> {} )
            .catch( err => {} )
    }

    /**
     */
    LoadCardList = async () =>
    {
        try
        {
            this.setState({bloading:true})

            const cfg = this.props.__cfg

            const profile = new Profile( {} )

            const card_list = await profile.GetCardList(cfg)

            // console.log( 'Card_Details: LoadCardList: card_list: ', card_list )

            this.setState( {bloading: false, cardList: card_list} )

            return { msg: 'OK' }
        }
        catch( err )
        {
            console.warn( 'Checkout_Payment: LoadCardList: err: ', err );

            this.setState({bloading: false})

            return Promise.reject( err );
        }
    }   // LoadCardList

    /**
     */
    OnDelete = ( x ) =>
    {
        if (window.confirm('Are you sure you wish to delete this card?'))
            this.Delete(x)
                .then()
                .catch()
        // Alert.alert(
        //     'Delete this address?',
        //     [x.line1, x.line2, x.city, x.postal_code].join(' '),
        //     [
        //         {
        //             text: 'Cancel',
        //             onPress: () => {},
        //             style: 'cencel'
        //         },
        //         {
        //             text: 'OK',
        //             onPress: () => {
        //                 this.Delete( x )
        //                     .then( data => {} )
        //                     .catch( data => {} )
        //             }
        //         },
        //     ],
        //     {cancelable: false}
        // )
    }

    /**
     */
    Delete = async ( x ) =>
    {
        try
        {
            this.setState( {bRefresh: true} )

            const {__cfg} = this.props;


            // call lambda to delete this address.
            const aws_lambda = new Lambda( {} )

            const params = {
                actType: 'pay'
                ,	act: 'card:shared:rem'
                ,	stage: __cfg.stage
                ,	usr: 'beta'
                ,	payment_method: {id: x.id}
                ,	b_shared: true
            }

            // console.log( 'AddressList: Delete: p_lambda: ', params )

            const resp_lambda = await aws_lambda.Invoke( params, __cfg.lambda( 'beta', __cfg.stage ) );

            // console.log( 'AddressList: Delete: resp_lambda: ', resp_lambda )

            if( resp_lambda.errorMessage )
                throw new Error( resp_lambda.errorMessage )

            // refresh address list
            await this.LoadCardList()

            //
            // this.setState( {bRefresh: false} ) already called in this.Init()

            //
            return {msg: 'OK'}
        }
        catch( err )
        {
            this.setState( {bRefresh: false} )

            console.warn( 'AddressList: AddNew: err: ', err )

            return Promise.reject( err )
        }
    }

    /**
     */
    render()
    {
        if(this.state.bloading)
        {
            return(
                <div className="centerScreen" style={{marginTop:32}}>
                    <Loader
                        type="Bars"
                        color="#009432"
                        height={200}
                        width={200}
                        timeout={30000000}

                    />
                </div>
            )
        }

        return (
            <div className="profileBodyDiv">
                <div>
                    <div style={{flexDirection: "row", display: "flex"}}>
                        <h3 style={{flex: 1}}> Card Details</h3>
                        <Button onClick={() => this.setState({newCard: true})} variant="info">
                            Add New Card
                        </Button>
                    </div>
                    <div>
                        <Row>
                        {
                            this.state.cardList.length === 0
                        ?   <div className="imageText">
                                <Image
                                    className="orderCompleteImage"
                                    style={{height:250,width:"auto"}}
                                    src={require('../assets/img/placeholder/noCreditCard.png')}
                                    rounded
                                />
                                <div className="alertMessage">
                                    No card added
                                </div>
                            </div>

                        :   this.state.cardList.map( (card, idx) => (
                                    <Data_Details_Box
                                        key={"c" + idx}
                                        item = {card}
                                        delete={true}
                                        OnDelete={this.OnDelete}

                                    />
                                ) )
                        }
                        </Row>
                    </div>
                </div>

                <>
                {
                    this.state.newCard
                ?   <AddNew_Card {...this.props}
                                 hideModal={()=> {
                                    this.setState({newCard: false}) }}
                                 LoadCardList={this.LoadCardList}
                    />

                :   null
                }
                </>
            </div>
        )
    }   // render
}   // Card_Details

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Card_Details )

