/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'
import {
    Container,
    Button,
    Alert,
    Form,
} from 'react-bootstrap'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import NavHdr from '../_common/nav_hdr'
import color from '../_common/colors.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPlusSquare,
    faTrashAlt,
    faCheck,
    faCircle,
    faAlignJustify,
    faTimes,
    faTruckMoving,
    faShoppingBag,
    faMoneyBill,
    faArrowDown,
    faCreditCard,
    faPlus,
    faMinus,
    faTicketAlt
} from '@fortawesome/free-solid-svg-icons'
import '../../css/_common.css'
// import elml_cfg from '../../_config/elml_cfg'
import {Lambda,Utils, Currency} from "../../api"
import ContentLoader from 'react-content-loader'
import AddNew_Address from "../profile/addNewAddress";
import {Profile} from "../profile/profile";

/**
 */
class Checkout_Delivery extends React.PureComponent
{
    /**
     */
    constructor(props)
    {
        super( props )

        this.state = {
                errMsg : ""
            }
    }

    /**
     */
    delSelect = (val) =>
    {
        this.props.Rdx_Checkout_delivery({deliveryType: val })

        if(val.toLowerCase() === "collection")
        {
            this.props.Rdx_Checkout_delivery({deliveryAddress: ""})
            this.props.setDeliveryCharge(0.0);
        }
    }

    setGenericDiscount = () =>
    {
        // console.log( "Checkout_Delivery : setGenericDiscount: this.props: ", this.props );

        let b_found = false;

        let disc_applied = {
                _idx: 0,
                disc_type: 'generic',
                type: "",
                name: "",
                amount: 0.0,
                b_accept_others: false
            }

        const subtotal = this.props.__checkout.SubTotal();

        // console.log( "Checkout_Delivery : setGenericDiscount: subtotal: ", subtotal );

        if( this.props.__checkout.delivery.deliveryType && this.props.__checkout.delivery.deliveryType.toLowerCase() === "delivery" )
        {
            const discount_p = this.props.__biz.discount
            const discount_obj = discount_p && discount_p.delivery
                ?   discount_p.delivery : {discount: []}

            for( let i = 0; i < discount_obj.discount.length; i++ )
            {
                if( subtotal > discount_obj.minRequirement[i] )
                {
                    if( discount_obj.type === 'percentage' && discount_obj.discount[i] > 0 )
                    {
                        disc_applied.amount = parseFloat(subtotal * ((discount_obj.discount[i]) / 100)).toFixed(2);
                        if (discount_obj.discountLimit[i] > 0 && discount_obj.discountLimit[i] < disc_applied.amount) {
                            disc_applied.amount = discount_obj.discountLimit[i];
                        }
                    }
                    else if( discount_obj.type === 'amount' )
                    {
                        disc_applied.amount = discount_obj.discount[i];
                    }

                    disc_applied._idx = 1;
                    disc_applied.name = "Delivery"
                    disc_applied.type = "delivery";

                    b_found = true;
                }
            }
        }
        else if( this.props.__checkout.delivery.deliveryType && this.props.__checkout.delivery.deliveryType.toLowerCase() === "collection" )
        {
            const discount_p = this.props.__biz.discount
            const discount_obj = discount_p && discount_p.collection
                ?   discount_p.collection : {discount: []}

            for( let i = 0; i < discount_obj.discount.length; i++ )
            {
                if( subtotal > discount_obj.minRequirement[i] )
                {
                    if( discount_obj.type === 'percentage' && discount_obj.discount[i] > 0 )
                    {
                        disc_applied.amount = parseFloat(subtotal * ((discount_obj.discount[i]) / 100)).toFixed(2);
                        if (discount_obj.discountLimit[i] > 0 && discount_obj.discountLimit[i] < disc_applied.amount) {
                            disc_applied.amount = discount_obj.discountLimit[i];
                        }
                    }
                    else if( discount_obj.type === 'amount' )
                    {
                        disc_applied.amount = discount_obj.discount[i];
                    }

                    disc_applied._idx = 2;
                    disc_applied.name = "Collection"
                    disc_applied.type = "collection";

                    b_found = true;
                }
            }
        }

        if( b_found )
        {
            this.props.Rdx_Checkout_SetGenericDiscount({...disc_applied});
        }
        else
        {
            // console.log( "No generic discount!" );
            return;
        }
    }

    /**
     */
    showPayment = () =>
    {
        if(!this.props.__checkout.delivery.deliveryType)
        {
            this.setState({errMsg: "Please select a type"});
            return;
        }

        if(this.props.__checkout.delivery.deliveryType.toLowerCase() === "delivery" && !this.props.__checkout.delivery.deliveryAddress )
        {
            this.setState({errMsg: "Please select an address and press next"});
            return;
        }

        this.setGenericDiscount();

        this.props.showDelivery(false);
    }

    /**
     */
    render()
    {
        // console.log( "Checkout_Delivery : render : props : ", this.props );

        const {__checkout,__core, __cfg} = this.props;

        return (
            <div>
                <div className="orderProcessBody">
                    <div>
                    {
                        this.state.errMsg
                    ?   <Alert variant="danger" onClose={() => this.setState({errMsg: ""})} dismissible>
                            {this.state.errMsg}
                        </Alert>

                    :   null
                    }
                    </div>

                    <div style={{flexDirection: 'row', display: "flex"}}>
                    {
                        this.props.__biz.settings.services.delivery
                        ?
                            <div style={{flex: 1}} key={"Delivery"}>
                                <div onClick={()=>this.delSelect("Delivery")}>
                                    <div
                                        className="selectionContainer"
                                        style={{backgroundColor: __checkout.delivery.deliveryType === "Delivery" ? color.colors.primary : '#ffffff'}}>
                                        <div style={{marginBottom: 5}}>
                                            <FontAwesomeIcon icon={faTruckMoving} size="2x"
                                                                        color={__checkout.delivery.deliveryType === "Delivery" ? "#fff" : color.colors.light}/>
                                        </div>
                                        <div>
                                            <div className="infoSubTitle"
                                                    style={{color: __checkout.delivery.deliveryType === "Delivery" ? '#ffffff' : color.colors.primary}}>{"Delivery"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        null
                    }
                    {
                        this.props.__biz.settings.services.collection
                        ?
                            <div style={{flex: 1}} key={"Collection"}>
                                <div onClick={()=>this.delSelect("Collection")}>
                                    <div
                                        className="selectionContainer"
                                        style={{backgroundColor: __checkout.delivery.deliveryType === "Collection" ? color.colors.primary : '#ffffff'}}>
                                        <div style={{marginBottom: 5}}>
                                            <FontAwesomeIcon icon={faShoppingBag} size="2x"
                                                                        color={__checkout.delivery.deliveryType === "Collection" ? "#fff" : color.colors.light}/>
                                        </div>
                                        <div>
                                            <div className="infoSubTitle"
                                                style={{color: __checkout.delivery.deliveryType === "Collection" ? '#ffffff' : color.colors.primary}}
                                            >
                                                    {"Collection"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        null
                    }
                    {
                        // __cfg.delivery_opt.map((x) => {
                        //     return (
                        //         <div style={{flex: 1}} key={x}>
                        //             <div onClick={()=>this.delSelect(x)}>
                        //                 <div
                        //                     className="selectionContainer"
                        //                     style={{backgroundColor: __checkout.delivery.deliveryType === x ? color.colors.primary : '#ffffff'}}>
                        //                     <div style={{marginBottom: 5}}>
                        //                         {
                        //                             'Delivery' === x
                        //                                 ? <FontAwesomeIcon icon={faTruckMoving} size="2x"
                        //                                                     color={__checkout.delivery.deliveryType === x ? "#fff" : color.colors.light}/>

                        //                                 :
                        //                                 <FontAwesomeIcon icon={faShoppingBag} size="2x"
                        //                                                     color={__checkout.delivery.deliveryType === x ? "#fff" : color.colors.light}/>
                        //                         }
                        //                     </div>
                        //                     <div>
                        //                         <div className="infoSubTitle"
                        //                                 style={{color: __checkout.delivery.deliveryType === x ? '#ffffff' : color.colors.primary}}>{x}</div>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //         </div>
                        //     )
                        // })
                    }
                    </div>

                    <div>
                        <div>
                            
                            {
                                //  @todo
                                __checkout.delivery.deliveryType === "Delivery"
                            ?
                                <div>
                                    <DeliveryInfo delivery={this.props.__biz.delivery} __biz={this.props.__biz.delivery} />

                                    <AddrList {...this.props} setErrMessage={ (errMsg) => this.setState({errMsg: errMsg}) } />
                                </div>

                            :   null
                            }
                            

                            <div className="processButtons">
                                <Button
                                    className="confirmButton"
                                    onClick={() => this.showPayment() }>
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}  // class Checkout_Delivery

/**
 */
class DeliveryInfo extends React.PureComponent
{
    constructor( props )
    {
        super( props )

        console.log( "DeliveryInfo: cstr: props: ", this.props );
    }

    render()
    {
        const pr_bi = this.props.__biz
        const c_sign = Currency.Sign( pr_bi.biz_currency )

        return (
            <Alert variant='light'>
                <div>
                {
                    this.props.delivery.range_max
                ?   <div>
                    Maximum distance of delivery is
                    <Alert.Link> {this.props.delivery.range_max + ' ' + this.props.delivery.dist_unit} </Alert.Link>
                    from The Restaurant
                    </div>

                :   null
                }
                </div>

                
                {
                    'postcode' === this.props.delivery.method
                ?   <div>
                        For
                        <Alert.Link> {this.props.delivery.postcodes.map( x => '('+x.join(', ')+')' ).join(', ')} </Alert.Link>
                        postcodes
                        minimum order value is
                        <Alert.Link> {this.props.delivery.min_total.map( x => c_sign+x.toFixed(2) ).join(', ')} </Alert.Link>
                        and
                        will be charged
                        <Alert.Link> {this.props.delivery.charges.map( x => c_sign+x.toFixed(2) ).join(', ')} </Alert.Link>
                        respectively.
                    </div>

                :   'range' === this.props.delivery.method
                ?   <div>
                        For
                        <Alert.Link> {this.props.delivery.ranges.join(', ')} {this.props.delivery.dist_unit} </Alert.Link>
                        radius
                        minimum order value is
                        <Alert.Link> {this.props.delivery.min_total.map( x => c_sign+x.toFixed(2) ).join(', ')} </Alert.Link>
                        and
                        will be charged
                        <Alert.Link> {this.props.delivery.charges.map( x => c_sign+x.toFixed(2) ).join(', ')} </Alert.Link>
                        respectively.
                    </div>

                :   null
                }
                

                <div>
                    *Please note that minimum order amount might apply.
                </div>
            </Alert>
            )
    }
}   // DeliveryInfo

/**
 */
class AddrList extends React.PureComponent
{
    /**
     */
    constructor(props)
    {
        super(props)

        this.state = {
                selected:  this.props.__checkout.delivery.deliveryAddress ,
                addrList: [],
                bloading: false,
                addNewAddress: false,

        };

        this._p = {
              inRange: []
            , outRange: []
            , last: null
        };
    }

    /**
     */
    componentDidMount()
    {
        this.loadUserAddress()
            .then()
            .catch()
    }

    /**
     */
    loadUserAddress = async () =>
    {
        try
        {
            const {__cfg} = this.props
            this.setState({bloading: true});

            const profile = new Profile( {} )

            const add_list = await profile.GetAddressList(__cfg)

            this.setState({bloading: false, addrList: add_list});

            return {msg: 'OK'}
        }
        catch (err)
        {
            this.setState({bloading: false});
            return Promise.reject( err )
        }
    }

    /**
     */
    render()
    {
        const {__biz} = this.props

        this.state.addrList = this.state.addrList.map( addr => {
                return Addr.Prep(addr, __biz.delivery)
            })

        this._p.inRange = []
        this._p.outRange = []

        this.state.addrList.forEach(addr => {
                if (addr.fees.b_delivery)
                    this._p.inRange.push(addr);
                else
                    this._p.outRange.push(addr);
            });

        // sort in_range
        this._p.inRange.sort((a, b) => {
                return a.fees.charge - b.fees.charge;
            });

        // check selected
        if( this.state.selected )
        {
            const selected_id = this.state.selected._id;
            // find the selected address and make b_selected = true

            this._p.inRange = this._p.inRange.map(addr => {
                    if (addr._id === selected_id)
                        addr.b_selected = true;
                    else
                        addr.b_selected = false;

                    return addr;
                });
        }

        return this.UI_List();
    }   // render

    /**
     */
    UI_List = () =>
    {
        // return null;

        return (
            <div>
                
                {
                    this.state.addNewAddress
                ?   <AddNew_Address {...this.props}
                        hideModal={()=>this.setState({ addNewAddress: false })}
                        loadUserAddress={this.loadUserAddress}
                    />

                :   null
                }
                

                
                {
                    this.state.bloading
                ?   <div style={{
                        padding: 32,
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: 300,
                        width: 600
                    }}>
                        <ContentLoader
                            height={80}
                            width={200}
                            speed={1}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                        >
                            <rect x="0" y="13" rx="4" ry="4" width="400" height="9"/>
                            <rect x="0" y="29" rx="4" ry="4" width="100" height="8"/>
                            <rect x="0" y="50" rx="4" ry="4" width="400" height="10"/>
                            <rect x="0" y="65" rx="4" ry="4" width="400" height="10"/>
                            <rect x="0" y="79" rx="4" ry="4" width="100" height="10"/>
                            <rect x="0" y="99" rx="5" ry="5" width="400" height="200"/>
                        </ContentLoader>
                    </div>

                :    null
                }
                

                <div className="processDetailsDiv">
                    <div className="processDetailText">
                        Select Delivery Address
                    </div>
                    <div className="processDetailsButton"
                         onClick={() => this.setState({addNewAddress:true})}
                    >
                        Add New Address
                    </div>
                </div>
                { this.state.addrList && this.state.addrList.length > 0 ?
                    null:
                    <Alert  variant= 'danger'>
                        <Alert.Link href="#"> No Saved Address Found . Add a new Address </Alert.Link>
                    </Alert>
                }

                { this._p.inRange  && this._p.inRange.length ?
                    null:
                    <Alert  variant= 'danger'>
                        <Alert.Link href="#"> No Address Found inside delivery range </Alert.Link>
                    </Alert>
                }

                {__InRange(this._p.inRange, this.OnSelect)}

                {
                    this._p.outRange  && this._p.outRange.length
                ?   <div className="processDetailText" style={{color:"#ddd"}}>Out of Range Address</div>

                :   null
                }

                {__OutRange(this._p.outRange)}
            </div>
        );

        function __InRange(in_range, on_select)
        {
            return in_range.map(addr => {
                return (
                    <div>
                        {
                            in_range && in_range.length ?
                                null :
                                <div>
                                    null
                                </div>
                        }
                        <Addr addr={addr} OnSelect={on_select} key={addr._id} b_selected={addr.b_selected}/>
                    </div>
                );
            });
        }

        function __OutRange(out_range)
        {
            return out_range.map(addr => {
                return (
                    <Addr  addr={addr} key={addr._id}/>
                );
            });
        }
    }

    /**
     */
    OnSelect = (addr) =>
    {
        this.setState({selected: addr});

        if (addr && addr.fees.b_delivery)
        {
            if( addr.fees.min_total && addr.fees.min_total > this.props.__checkout.Total() )
            {
                this.props.setErrMessage( "We don't deliver for less than £" + addr.fees.min_total + " to your address." );
            }
            else
            {
                this.props.Rdx_Checkout_delivery( { deliveryAddress: addr } );
                this.props.setDeliveryCharge(addr.fees.charge ? addr.fees.charge : 0.0);
            }
        }
    }
}   // class AddrList

/**
 */
class Addr extends React.PureComponent
{
    /**
     */
    constructor(props)
    {
        super(props);
    }

    /**
     */
    render()
    {
        // // console.log('Addr: render: this.props: ', this.props);
        // // console.log('Addr: render: this.props: ', this.state);

        if (this.props.addr.fees.b_delivery)
        {
            return (
                <div
                    className="pickerItem"
                    key={this.props.addr._id} onClick={this.OnSelect}>
                    {this.UI_Addr()}
                </div>
                )
        }
        else
        {
            return this.UI_Addr()
        }
    }

    /**
     */
    UI_Addr = () =>
    {
        let style_addr = "";

        if (this.props.addr.fees.b_delivery)
        {
            if (0 === this.props.addr.fees.charge)
                style_addr = "addressSelect";
            else
                style_addr = "addressSelect";
        }
        else
        {
            style_addr = "addressSelect address_out_range";
        }

        return (
            <div>
            {
                this.props.b_selected
            ?   <div className="commonFlex" style={{alignItems:"center"}}>
                    <FontAwesomeIcon style={{marginRight: 8}} icon={faCheck} size="lg" color="green"/>
                    <div className={style_addr}
                            style={{
                                color: color.colors.black,
                                fontWeight: 700,
                                // border: "1px solid",
                                // borderRadius: 10,
                                // borderColor: color.colors.primary
                            }}>
                        {this.props.addr.line1} {this.props.addr.line2}, {this.props.addr.city}, {this.props.addr.postal_code}
                    </div>
                </div>

            :   <div className= {style_addr}
                        style={{color: color.colors.light}}>
                    <FontAwesomeIcon style={{marginRight: 8}} icon={faCircle} size="lg" color="#C4C4C4"/>

                    {this.props.addr.line1} {this.props.addr.line2}, {this.props.addr.city}, {this.props.addr.postal_code}
                </div>
            }
            </div>
            )
    }   // UI_Addr

    /**
     */
    OnSelect = () =>
    {
        this.props.OnSelect(this.props.addr);
    }

    /**
     */
    static Prep = (addr, delivery_obj) =>
    {
        // return null;
        if (!addr._id) {
            addr._id = Utils.MD5([
                addr.line1, addr.line2
                , addr.city, addr.county, addr.country
                , addr.postcode
            ].join(':'));
        }

        if (!addr.ts_prep)
        {
            addr.fees = Addr.Delivery_Fees(addr, delivery_obj);
            // addr.min_total = ;

            // addr.ts_prep = Date.now();
        }

        return addr;
    }

    static Delivery_Fees = (addr, delivery_obj) =>
    {
        let fees_tmp = 0;

        if ('range' === delivery_obj.method)
            fees_tmp = Addr.Delivery_Fees_Range(addr, delivery_obj);
        else if ('postcode' === delivery_obj.method)
            fees_tmp = Addr.Delivery_Fees_Postcode(addr, delivery_obj);
        else
            fees_tmp = -1;

        return fees_tmp;
    }

    /**
     */
    static Delivery_Fees_Range = (addr, delivery_obj) =>
    {
        const user_distance = Addr.Delivery_Dist(addr, delivery_obj);

        let idx_range = -1;

        //
        for (let i = 0; i < delivery_obj.ranges.length; ++i)  // iterate through all the delivery ranges check for different minimum order amount
        {
            if (user_distance < delivery_obj.ranges[i])
            {
                idx_range = i;
                break;
            }
        }

        if (-1 === idx_range)
        {
            const msg_ = "We don't deliver at your address. Please call the restaurant directly to enquire!";

            return {b_delivery: false, msg: msg_};
        }
        else
        {
            return {
                b_delivery: true
                , min_total: delivery_obj.min_total[idx_range]
                , min_free: delivery_obj.min_free[idx_range]
                , charge: delivery_obj.charges[idx_range]
                };
        }
    }   // Delivery_Fees_Range

    /**
     */
    static Delivery_Fees_Postcode = (addr, delivery_obj) =>
    {
        if( delivery_obj.range_max && 0 < delivery_obj.range_max )
        {
            const user_distance = Addr.Delivery_Dist(addr, delivery_obj);

            if( user_distance > delivery_obj.range_max )
            {
                const msg_ = "We don't deliver at your address. Please call the restaurant directly to enquire!";

                return {b_delivery: false, msg: msg_};
            }
        }

        // // console.log("geocode");
        const post_code = Addr.Postcode_Split(addr);

        if (!post_code)
        {
            const msg_ = 'Wrong PostCode provided.';

            // this.props.updatePopup({type: "err", msg: 'Wrong PostCode provided.'});
            // this.props.setDeliveryCharge(home_delivery_charge);

            return {b_inrange: false, msg: msg_};
        }

        let idx_found = -1;

        for( let i = 0; i < delivery_obj.postcodes.length; i++ )
        {
            for( let j = 0; j < delivery_obj.postcodes[i].length; j++ )
            {
                if( delivery_obj.postcodes[i][j].pre && delivery_obj.postcodes[i][j].pre === post_code.pre )
                {
                    if( !delivery_obj.postcodes[i][j].post )
					{
						idx_found = i;
                        break
                    }

                    for( let k = 0; k < delivery_obj.postcodes[i][j].post.length; ++k )
                    {
                        if( post_code.post.startsWith( delivery_obj.postcodes[i][j].post[k] ) )
                        {
                            idx_found = i;
                            break
                        }
                    }   // for k
                }

                else if (delivery_obj.postcodes[i][j] === post_code.pre)
                {
                    idx_found = i;
                    break;
                }

                else
                {
                    //
                }

                if( -1 < idx_found )
                {
                    break
                }
            }   // for j

            if( -1 < idx_found )
            {
                break
            }
        }   // ofr i

        if (-1 === idx_found)
        {
            const msg_ = "We don't deliver at your address. Please call the restaurant directly to enquire!";

            return {b_delivery: false, msg: msg_};
        }
        else
        {
            return {
                b_delivery: true
                , min_total: delivery_obj.min_total[idx_found]
                , min_free: delivery_obj.min_free[idx_found]
                , charge: delivery_obj.charges[idx_found]
                };
        }
    }   // Delivery_Fees_Postcode

    /**
     */
    static Postcode_Split = p =>
    {
        let postcode_pre = '', postcode_post;

        // p = typeof addr === "string" ? JSON.parse(addr) : addr;

        p.postal_code = p.postal_code.replace(/\s/g, '');

        if (6 === p.postal_code.length)
        {
            postcode_pre = p.postal_code.substr(0, 3).toUpperCase();
            postcode_post = p.postal_code.substr(4, 7).toUpperCase();
        }
        else if (7 === p.postal_code.length)
        {
            postcode_pre = p.postal_code.substr(0, 4).toUpperCase();
            postcode_post = p.postal_code.substr(5, 8).toUpperCase();
        }
        else
        {
            // const msg_ = 'Wrong PostCode provided.';

            // this.props.updatePopup({type: "err", msg: 'Wrong PostCode provided.'});
            // this.props.setDeliveryCharge(home_delivery_charge);

            return null;
        }

        return {
            pre: postcode_pre
            , post: postcode_post
            };
    }

    /**
     */
    static Delivery_Dist = (addr, delivery_obj) =>
    {
        // // console.log("Delivery_dist function start : ");

        let user_distance = parseFloat(Addr.Distance_inKM(
            delivery_obj.restaurant_geocode.latitude
            , delivery_obj.restaurant_geocode.longitude
            , addr.longlat.latitude
            , addr.longlat.longitude
            )).toFixed(2);

        // // console.log("user_distance after getting : ", user_distance);

        if (delivery_obj.dist_unit == 'miles')
        {
            user_distance = Addr.KM2Mile(user_distance);
        }

        // // console.log("user_distance after conversion : ", user_distance);

        return user_distance;
    }

    /**
     */
    static KM2Mile = src_km =>
    {
        return src_km * 0.621371;
    }

    /**
     */
    static Distance_inKM = (lat1, lon1, lat2, lon2) =>
    {
        const R = 6371;                                                           // Radius of the earth in km
        const dLat = Addr.Deg2Rad(lat2 - lat1);                                          // Convert Latitude to Radian value
        const dLon = Addr.Deg2Rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(Addr.Deg2Rad(lat1)) * Math.cos(Addr.Deg2Rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return  R * c; // Distance in km
    }

    /**
     */
    static Deg2Rad = deg => {
        return deg * (Math.PI / 180);
    }
}   // class Addr

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Checkout_Delivery )


