/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Container,
    Button,
    Form,
    Image, Alert,
    Modal,
} from 'react-bootstrap'

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ContentLoader from 'react-content-loader'
import IframeComm from 'react-iframe-comm'

// import {
//     faPlusSquare,
//     faTrashAlt,
//     faCheck,
//     faCircle,
//     faAlignJustify,
//     faTimes,
//     faTruckMoving,
//     faShoppingBag,
//     faMoneyBill,
//     faArrowDown,
//     faCreditCard,
//     faPlus,
//     faMinus,
//     faTicketAlt,
//     faDotCircle
// } from '@fortawesome/free-solid-svg-icons'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import {Lambda,Utils} from "../../api";
// import elml_cfg from '../../_config/elml_cfg'

// import color from '../_common/colors.json'
import '../../css/_common.css'

/**
 */
class Checkout_Confirmation extends React.PureComponent
{
    //
    constructor( props )
    {
        super( props )

        this.state = {
                bModal: true,
                bLoading: false,
                // strJs: '',
                pi: null,
            }
    }

    //
    render()
    {
        // // console.log( 'Checkout_Confirmation: render: this.state: ', this.state )

        // if( this.state.pi )
        //     // console.log( 'Checkout_Confirmation: render: url: ', this.state.pi.next_action.redirect_to_url.url )

        return (
            <div>
            {
                this.state.bModal
            ?   <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={true} onHide={()=>this.props.hideView()} animation={true}
                    style={{zIndex: 1111111111}}
                >
                {
                    this.state.pi && !this.state.bLoading
                // ?   <iframe
                //         src={'/checkout_confirmation.html'}
                //         originwhitelist={['*']}
                //         // injectedjavascript={this.state.strJs}
                //         // javascriptenabled= "true"
                //         // domstorageenabled= "true"
                //         frameBorder="0"
                //         width="450px"
                //         height="450px"
                //     />
                ?   <IframeComm
                        attributes={{
                                src: '/checkout_confirmation.html',
                                width: "450px",
                                height: "450px"
                            }}
                        handleReceiveMessage={ this.OnMsg }
                    />

                :   <div className="overlayContainer">
                        <ContentLoader
                            height={160}
                            width={200}
                            speed={1}
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                        >
                            <rect x="0" y="13" rx="4" ry="4" width="400" height="9"/>
                            <rect x="0" y="29" rx="4" ry="4" width="100" height="8"/>
                            <rect x="0" y="50" rx="4" ry="4" width="400" height="10"/>
                            <rect x="0" y="65" rx="4" ry="4" width="400" height="10"/>
                            <rect x="0" y="79" rx="4" ry="4" width="100" height="10"/>
                            <rect x="0" y="99" rx="5" ry="5" width="400" height="200"/>
                        </ContentLoader>
                    </div>
                }
                </Modal>

            :   null
            }
            </div>
            )
    }   // render

    //
    componentDidMount()
    {
        this.Init()
            .then( data => {} )
            .catch( err => {} )
    }

    //
    OnMsg = async ( event ) =>
    {
        try
        {
            // const resp = JSON.parse( event.nativeEvent.data )
            // const resp_event = event.nativeEvent.data

            // // console.log( 'Checkout_Confirmation: OnMsg: resp_event: ', resp_event )

            this.setState( {bLoading: true} )

            //
            const cfg = this.props.__cfg
            const aws_lambda = new Lambda({})

            const p_lambda = {
                    stage: cfg.stage,
                    usr: "beta",
                    actType: "pay",
                    act: "card:confirm-retr",
                    pi: this.state.pi.id,
                    client_secret: this.state.pi.client_secret,
                    // return_url: return_url,
                }

            // // console.log( 'Checkout_Confirmation: OnMsg: p_lambda: ', p_lambda )

            const {resp} = await aws_lambda.Invoke( p_lambda, cfg.lambda('beta', cfg.stage) )

            // console.log( 'Checkout_Confirmation: OnMsg: resp: ', resp )

            this.setState( {bModal: false, bLoading: false,} )

            if( this.props.OnClose )
                this.props.OnClose()

            if( this.props.OnConfirm )
                this.props.OnConfirm( resp.status )

            //
            return {}
        }
        catch( err )
        {
            console.warn( 'Checkout_Confirmation: OnMsg: err: ', err )

            return {err}
        }
    }

    //
    Close = () =>
    {
        this.setState( {bModal: false} )

        if( this.props.OnClose )
            this.props.OnClose()
    }   // Close

    //
    Init = async () =>
    {
        try
        {
            // // console.log( 'Checkout_Confirmation: Init: this.props: ', this.props )

            const return_url = 'https://s3-eu-west-1.amazonaws.com/rat.elmelo.com/ratatouille/checkout_confirmation.html'

            // confirm payment intent ...
            const cfg = this.props.__cfg
            const aws_lambda = new Lambda({})

            const p_lambda = {
                    stage: cfg.stage,
                    usr: "beta",
                    actType: "pay",
                    act: "card:confirm",
                    pi: this.props.pi.id,
                    return_url: return_url,
                }

            // console.log( 'Checkout_Confirmation: Init: p_lambda: ', p_lambda )

            const resp = await aws_lambda.Invoke( p_lambda, cfg.lambda('beta', cfg.stage) )

            console.log( 'Checkout_Confirmation: Init: resp: ', resp )

            if( resp.errorMessage )
            {
                console.error( 'Checkout_Confirmation: Init : err : ', resp );
                // throw new Error( resp.errorMessage )

                if( this.props.OnClose )
                    this.props.OnClose()

                if( this.props.ShowError )
                    this.props.ShowError( resp.errorMessage )

            }

            if( 'succeeded' === resp.resp.status || 'requires_payment_method' === resp.resp.status )
            {
                if( this.props.OnClose )
                    this.props.OnClose()

                if( this.props.OnConfirm )
                    // this.props.OnConfirm( resp.resp.status )
                    this.props.OnConfirm( resp.resp )

                return {}
            }

            this.setState( {pi: resp.resp} )

            return {}
        }
        catch( err )
        {
            console.warn( 'Checkout_Confirmation: Init: err: ', err )

            return Promise.reject( err )
        }
    }   // Init
}   // Checkout_Confirmation

/**
 */
const mapStateToProps = (state) =>
{
    return state;
}   //

/**
 */
export default connect(mapStateToProps, actions)( Checkout_Confirmation );





