/**
 *  @copyright  Elmelo Ltd.
 */

import {Init, User_GetTotUnread} from '../../api/chat/chat_engine'
import { Core} from "../../api";

/**
 */
export const Rdx_ChatInit_InProgress = () =>
{
    return {
        type: 'chat:init_inprogress'
    // ,   payload: selected
    };
}

/**
 */
export const Rdx_ChatInit_Complete = () =>
{
    return {
        type: 'chat:init_complete'
    // ,   payload: selected
    };
}

/**
 */
export const Rdx_Chat = ( chat_obj ) =>
{
    return {
            type: 'chat:elml'
        ,   payload: chat_obj
        }
}
/**
 */
export const Rdx_Chat_Channel = ( obj ) =>
{
    return {
            type: 'chat:channel'
        ,   payload: obj
        }
}
//
export const Rdx_Pubid_Init = () =>
{
    return async (dispatch, gs) =>
    {
        try
        {
            const aws_core = new Core( {} )
            const user = await aws_core.currentuser() ;

            const p_init = {
                usr: 'beta',
                phone_no: user.attributes.phone_number
            }

            const resp_init = await Init( p_init, gs().__cfg )

            dispatch( Rdx_Chat_User_ID(resp_init.resp.usr_id ) )


            //
            return { msg: 'OK' }
        }
        catch( err )
        {
            console.warn( 'action/chat: Rdx_Pubid: err: ', err )
            return { err }
        }
    }   // return ...
}   // Rdx_Pubid
/**
 */
export const Rdx_Chat_User_ID = ( user_id ) =>
{
    return {
            type: 'chat:user-id'
        ,   payload: user_id
        }
}


// //
// export const Rdx_Iot_Init = () =>
// {
//     return async (dispatch, gs) =>
//     {
//         try
//         {
//             dispatch(Rdx_ChatInit_InProgress())
//
//             const p_iot = {
//                 beta_id: gs().__core.betaId,
//                 cfg: gs().__cfg,
//                 OnMsg: OnMsg
//
//             }
//             let _iot = null
//
//             _iot = new ChatIoT(p_iot)
//
//             const resp_init_iot = await _iot.Init()
//
//             // // console.log( 'AppMenu_Custom : InitChat : resp_init_iot: ', resp_init_iot )
//
//             if (resp_init_iot)
//             {
//                 //
//             }
//
//             const resp_conn = await _iot.Connect('app')
//
//             await _iot.Subscribe('chat', '__all__')
//
//             // // console.log( 'AppMenu_Custom : InitChat : resp_conn: ', resp_conn )
//
//             if (resp_conn)
//             {
//                 //
//             }
//
//             dispatch(Rdx_Chat(_iot))
//
//             dispatch(Rdx_ChatInit_Complete())
//
//             dispatch(Rdx_Chat_totUnRead_Req())
//
//
//             //
//             return { msg: 'OK' }
//         }
//         catch( err )
//         {
//             dispatch(Rdx_ChatInit_InProgress())
//             console.warn( 'action/chat: Rdx_Iot: err: ', err )
//             return { err }
//         }
//     }   // return ...
// }   //
//
// /**
//  */
// const OnMsg = async (topic, msg) =>
// {
//     try {
//         // console.log('AppMenu_Custom: OnMsg: topic: ', topic)
//         // console.log('AppMenu_Custom: OnMsg: msg: ', msg)
//
//         this.Rdx_Chat_totUnRead_Req()
//
//         return {}
//     } catch (err) {
//         console.warn('AppMenu_Custom: OnMsg: err: ', err)
//
//         return {err}
//     }
// }	// OnEvent



// /**
//  */
// export const Rdx_SendBird = ( sb_obj ) =>
// {
//     return {
//             type: 'chat:sendbird'
//         ,   payload: sb_obj
//         }
// }

/**
 */
export const Rdx_Chat_totUnRead_Upd = ( n_tot ) =>
{
    return {
            type: 'chat:tot_unread_upd'
        ,   payload: n_tot
        }
}   // Rdx_Chat_totUnRead_Upd

/**
 */
export const Rdx_Chat_totUnRead_ReqCnt = ( req_cnt ) =>
{
    return {
            type: 'chat:tot_unread_req_cnt'
        ,   payload: req_cnt
        }
}   // Rdx_Chat_totUnRead_ReqCnt

/**
 */
export const Rdx_Chat_totUnRead_ReqStatus = ( req_status ) =>
{
    return {
            type: 'chat:tot_unread_req_status'
        ,   payload: req_status
        }
}   // Rdx_Chat_totUnRead_ReqStatus

/**
 */
export const Rdx_Chat_totUnRead_Req = ( req_cnt ) =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            if( 'in_progress' === gs().__chat.totUnRead_ReqStatus )
            {
                // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: ReqStatus: ', 'in_progress: exiting' )

                dispatch( Rdx_Chat_totUnRead_ReqCnt( gs().__chat.totUnRead_ReqCnt+1 ) )

                return {msg: 'OK'}
            }

            dispatch( Rdx_Chat_totUnRead_ReqStatus('in_progress') )

            // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: ReqStatus: ', 'in_progress' )

            do
            {
                dispatch( Rdx_Chat_totUnRead_ReqCnt( 0 ) )

                // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: ReqCnt: ', 0 )

                // const tot_unread = await gs().__chat.sb.UnRead_Cnt_Ch()

                // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: tot_unread: ', tot_unread )

                // dispatch( Rdx_Chat_totUnRead_Upd( await gs().__chat.sb.UnRead_Cnt_Ch() ) )
                const cnt_unread = await User_GetTotUnread( gs().__cfg )

                // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: cnt_unread: ', cnt_unread )

                dispatch( await Rdx_Chat_totUnRead_Upd( cnt_unread ) )

                // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: cnt updated' )
            }   while( gs().__chat.totUnRead_ReqCnt )

            dispatch( Rdx_Chat_totUnRead_ReqStatus('stale') )

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'actions/chat: Rdx_Chat_totUnRead_Req: err: ', err )

            return {err}
        }
    }   // return ...
}   // Rdx_Chat_totUnRead_Req

// /**
//  */
// export const Rdx_Chat_nUnRead = ( ch_url, n_unread ) =>
// {
//     return {
//             type: 'chat:n_unread'
//         ,   payload: {ch: ch_url, nUnRead: n_unread}
//         }
// }

// /**
//  */
// export const Rdx_Chat_MsgLast = ( ch_url, msg_last ) =>
// {
//     return {
//             type: 'chat:msg_last'
//         ,   payload: {ch: ch_url, msgLast: msg_last}
//         }
// }


