/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Nav, Navbar,
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import { navigate } from "@reach/router"
import '../../css/_common.css'

/**
 */
class NavHdr extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )
    }

    /**
     */
    render()
    {
        return (
            <Navbar sticky="top" fixed="top" expand="lg" variant="light" className="navbarDiv" style={this.props.style} >
                <Navbar.Brand href='/' >
                {
                    this.props.__biz.logo
                ?   <img src={this.props.__biz.logo.uri}
                        width={this.props.__biz.logo.width ? this.props.__biz.logo.width : 30}
                        height={this.props.__biz.logo.height ? this.props.__biz.logo.height : 30}
                        alt=''
                    />
                :   this.props.__biz.title ?
                        <div className="brandNameDiv" style={{display:this.props.__biz.subTitle ? "block": "flex"}}>
                            <div className="brandDiv">
                                <div className="brandName">{this.props.__biz.title}</div>
                                {
                                    this.props.__biz.subTitle ?
                                        <div className="brandSubtitle">{ this.props.__biz.subTitle }</div>
                                        : null
                                }
                            </div>
                        </div>
                        :
                        <div className="brandName">Brand Name</div>

                }
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {/*<Nav className="mr-auto">*/}
                        {/*<Nav.Link href='gallery'>Gallery</Nav.Link>*/}
                        {/*<Nav.Link href='menu'>Menu</Nav.Link>*/}
                    {/*</Nav>*/}
                    <Nav className="mr-auto">
                        {/*<Nav.Link href='reserve'>Reserve</Nav.Link>*/}
                        {/*<Nav.Link href='mobileOrder'>Mobile Order</Nav.Link>*/}
                        {/*<Nav.Link href="#" onSelect={ ()=>navigate('cart') } >Cart</Nav.Link>*/}
                        {/*<Nav.Link href='profile'>Profile</Nav.Link>*/}
                    </Nav>
                    <Nav>
                        {/* {
                            this.props.__core.authStatus && 'auth' === this.props.__core.authStatus ?
                                <Nav.Link href="#" onSelect={ () => navigate('chat') } className="activeNav" style={{position:"relative",color:this.props.chat ? "#009432" :"#b7b7b7"}}>
                                    Chat
                                    {
                                        this.props.__chat.totUnRead.totUnreadMsg ?
                                            <span className="chatCounter">{this.props.__chat.totUnRead.totUnreadMsg}</span>
                                            : null
                                    }
                                </Nav.Link>
                                : null
                        } */}
                        <Nav.Link href="#" onSelect={ () => navigate('gallery') } className="activeNav" style={{color:this.props.gallery ? "#009432" :"#b7b7b7"}}>Gallery</Nav.Link>
                        {/* <Nav.Link href="#" onSelect={ () => navigate('offer') } className="activeNav" style={{color:this.props.offer ? "#009432" :"#b7b7b7"}}>Offers</Nav.Link> */}
                        {
                            this.props.__biz.settings && this.props.__biz.settings.services && this.props.__biz.settings.services.reservation
                            ?
                            <Nav.Link href="#" onSelect={ () => navigate('reserve') } className="activeNav" style={{color:this.props.reserve ? "#009432" :"#b7b7b7"}}>Reservation</Nav.Link>
                            :
                            null
                        }
                        <Nav.Link href="#" onSelect={ () => navigate('information') } className="activeNav" style={{color:this.props.information ? "#009432" :"#b7b7b7"}}>Information</Nav.Link>
                        <Nav.Link href="#" onSelect={ () => navigate('order') } className="activeNav" style={{color:this.props.order ? "#009432" :"#b7b7b7"}}>Order Online</Nav.Link>

                    </Nav>
                    {
                        this.props.__core.authStatus ?
                        'auth' === this.props.__core.authStatus ?
                        <Nav>
                            <Nav.Link href="#" onSelect={ () => navigate('profile') } style={{color:this.props.profile ? "#009432" :"#b7b7b7"}}  className="activeNav">Profile</Nav.Link>
                        </Nav>
                            :
                        'veri_reqd' === this.props.__core.authStatus ?
                        <Nav>
                            <Nav.Link href='verification'>Complete SignUp</Nav.Link>
                        </Nav> :
                        <Nav>
                                <Nav.Link href="#" onSelect={ () => navigate('signin') } style={{color:this.props.signin ? "#009432" :"#b7b7b7"}}  className="activeNav">Sign In</Nav.Link>
                        </Nav> :
                            null
                    }
                </Navbar.Collapse>
            </Navbar>
            )
    }
}   // NavHdr

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( NavHdr )
