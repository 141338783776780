/**
 * @copyright Elmelo Ltd.
 */
import React from 'react'
import {
    Container,
    Button,
    Image,
    Form,
} from 'react-bootstrap'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import NavHdr from '../_common/nav_hdr'
import color from '../_common/colors.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPlusSquare,
    faTrashAlt,
    faCheck,
    faCircle,
    faAlignJustify,
    faTimes,
    faTruckMoving,
    faShoppingBag,
    faMoneyBill,
    faArrowDown,
    faCreditCard,
    faPlus,
    faMinus,
    faTicketAlt
} from '@fortawesome/free-solid-svg-icons'
import '../../css/_common.css'

// import {
//     Checkout_Personal, Checkout_Payment,Checkout_Summary, Checkout_Delivery
// }from './index'
import Checkout_Personal from './personal'
import Checkout_Delivery from './delivery'
import Checkout_Payment from './payment'
import Checkout_Summary from './summary'
// import Checkout_Success from './success'

import { navigate } from "@reach/router"

/**
 */
class Checkout_Home extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )
        this.state={
            customer:true,
            delivery:false,
            payment:false,
            customerCom:false,
            deliveryCom:false,
            paymentCom:false,
        }

        this.myRef = React.createRef();
    }

    /**
     */
    componentDidMount()
    {
    }

    showCustomer =(val) =>
    {
        this.setState({customer:val,delivery:true ,customerCom: true })
    }

    showDelivery =(val, h_p) =>
    {
        this.myRef.current.scrollIntoView( {
            behavior: 'smooth',
            block: 'start',
        } );

        this.setState({delivery:val, payment: this.state.paymentCom ? false : h_p ? false : true , deliveryCom: h_p ? false : true});
    }

    showPayment =(val,h_d) =>
    {
        this.myRef.current.scrollIntoView( {
            behavior: 'smooth',
            block: 'start',
        } );
        
        this.setState({payment:val , paymentCom: h_d ? false : true })

        if(h_d)
            this.setState({delivery:false})

    }

    onClose = () => {

        this.props.Rdx_Checkout_Clear();
        navigate('order')

    }
    /**
     */
    render()
    {
        // // console.log("states of customer",this.state);
        // // console.log("props of checkout",this.props);

        return (
            <div style={{paddingTop: 0}}>
                <NavHdr />
                <div className="overlay">
                    <div className="orderProcessHeader">
                        <h3 style={{flex:1}}>Checkout Order</h3>
                        <Button onClick={()=> this.onClose() } variant="light">
                            <FontAwesomeIcon
                                style={{width:50}}
                                icon={faTimes} size="lg" color="#ef5350"/>
                        </Button>
                    </div>
                    <div className="orderProcessContainer">
                        <div className="orderInfo">
                            
                            <div ref={this.myRef}></div>
                            <div className="processBox">
                                {!this.state.customerCom ?
                                    <div className="processTabSelect">
                                        <FontAwesomeIcon icon={faShoppingBag} size="lg" color="#009432"/>
                                        <div className="processText" style={{color:"#009432"}}>Customer Details</div>
                                    </div> :
                                    <div className="processTab">
                                        <FontAwesomeIcon icon={faCheck} size="lg" color="green"/>
                                        <div className="processText">Customer Details</div>
                                    </div>
                                }
                                {!this.state.deliveryCom  ?
                                    <div className="processTabSelect">
                                        <FontAwesomeIcon icon={faTruckMoving} size="lg" color="#009432"/>
                                        <div className="processText" style={{color:"#009432"}}>Delivery Details</div>
                                    </div> :
                                    <div className="processTab">
                                        <FontAwesomeIcon icon={faCheck} size="lg" color="green"/>
                                        <div className="processText">Delivery Details</div>
                                    </div>
                                }
                                {
                                    !this.state.paymentCom ?
                                        <div className="processTabSelect">
                                            <FontAwesomeIcon icon={faMoneyBill} size="lg" color="#009432"/>
                                            <div className="processText" style={{color: "#009432"}}>Payment Details
                                            </div>
                                        </div> :
                                        <div className="processTab">
                                            <FontAwesomeIcon icon={faCheck} size="lg" color="green"/>
                                            <div className="processText">Payment Details</div>
                                        </div>
                                }
                            </div>

                            {
                                this.state.customer ?
                                    <Checkout_Personal showCustomer={this.showCustomer}/>
                                    : null
                            }
                            {
                                this.state.delivery ?
                                    <div>
                                        <Checkout_Delivery showDelivery={this.showDelivery}/>
                                    </div>:
                                    null
                            }
                            {
                                this.state.payment ?
                                    <div>
                                        <Checkout_Payment showPayment={this.showPayment}/>
                                    </div>:null
                            }
                            {
                                ( this.state.customerCom && this.state.deliveryCom && this.state.paymentCom) ?
                                    <div className="orderSuccessContainer">
                                        {/*<Image src={require('../assets/img/opc.png')} style={{height:300,width:"auto"}}/>*/}
                                        {/* <div className="infoDescription" style={{marginVertical: 16}}>
                                            Order Process Completed
                                        </div> */}
                                        <div className="infoTitle" style={{marginVertical: 16}}>
                                            Place your order from the Summary Tab
                                            <div className="infoDescription" style={{marginVertical: 16,textAlign:"center"}}>
                                                and enjoy your Meal
                                            </div>

                                        </div>
                                    </div>:
                                    null
                            }
                        </div>
                        <div className="summery">
                            <Checkout_Summary
                                showDelivery={this.showDelivery}
                                showPayment={this.showPayment}
                                confirmPayment={ this.state.customerCom && this.state.deliveryCom && this.state.paymentCom  ? true : false }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}   // class Info_Home
/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Checkout_Home )


