/**
 *	@copyright	Elmelo Ltd.
 */

// import elml_cfg from '../../_config/elml_cfg'
import {AStorage} from '../../api/Utils'

import * as checkout from './checkout'

import ONT from '../../components/order/ont'//'./ont'

/**
 */
export const Rdx_Order_MenuReq = ( cfg ) =>
{
    return async (dispatch, gs) =>
    {
        try
        {
            if( 'inprogress' === gs().__order.menuReqStatus )
                return {msg: 'OK'}

            dispatch( Rdx_Order_MenuReqStatus('inprogress') )

            //

            dispatch( Rdx_Order_MenuReqStatus('stale') )

            return {msg: 'OK'}
        }
        catch( err )
        {
            dispatch( Rdx_Order_MenuReqStatus('stale') )

            console.warn( 'reducers/core: Rdx_Core_CfgReq: err: ', err )

            return {err}
        }
    }   // return ...
}   // Rdx_Order_MenuReq

/**
 */
const Rdx_Order_MenuReqStatus = (cfg_status) =>
{
    return {
        type: 'order:menu-req-status'
    ,   payload: cfg_status
    }
}

/**
 */
export const Rdx_Order_AddToCart = ( item, sect, cnt_change ) =>
{
    return async (dispatch, gs) =>
    {
        try
        {
            dispatch( Rdx_Order_UpdateCart( item, sect, cnt_change ) )

            //
            const total_obj = ONT.GetTotal(gs().__order.cart_sects);

            dispatch( checkout.Rdx_Checkout_SetSubtotal( total_obj.total ) )

            dispatch( checkout.Rdx_Checkout_SetItemCount( total_obj.item_cnt ) )

            //
            await AStorage.Set( 'cart', gs().__order.cart_sects )

            //
            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'reducers/core: Rdx_Core_CfgReq: err: ', err )

            return {err}
        }
    }   //
}   // Rdx_Order_AddToCart

/** order:Old
 */
export const Rdx_Old_Order = ( obj ) =>
{
    return {
        type: 'order:old-order',
        payload: obj
    }
}

/**
 */
export const Rdx_Order_UpdateCart = (item, sect, cnt_change) =>
{
	return {
		type: 'order:cart-upd'
		,   payload: {item, sect, cnt_change}
	};
}

// /**
//  */
// export const Rdx_Order_ClearCart = payload =>
// {
//     return ( dispatch, gs ) => {
//         console.warn( 'reducers/core: Rdx_Order_ClearCart: gs(): ', gs() )

//         gs().__order.cart_sects.forEach( sect => {
//             sect.data.forEach( item => {
//                     // gs().__core.menu_prep.items[item._id].
//                     // // console.log( 'reducers/core: Rdx_Core_Init: item: ', item );
    
//                     // const obj = gs().__core.menu.menu_prep.items[item._id]
    
//                     // fullMenu_prep.items[item._id]._cnt = item._cnt
    
//                     // gs().__core.menu.menu_prep.items[item._id]._cnt = 0
//                     // gs().__core.menu.menu_prep.items[item._id]._mods = []
//                     // gs().__core.menu.menu_prep.items[item._id]._modex = []
//                     // gs().__core.menu.menu_prep.items[item._id]._opts = []
//                     // item._cnt = 0
    
//                     // item._mods = null
//                     // item._modex = null
//                     // item._opts = null
//                     // if( item._mods )
//                     //     fullMenu_prep.items[item._id]._mods = item._mods
    
//                     // if( item._modex )
//                     //     fullMenu_prep.items[item._id]._modex = item._modex
    
//                     // if( item._opts )
//                     //     fullMenu_prep.items[item._id]._opts = item._opts
    
//                     // // console.log( 'reducers/core: Rdx_Core_Init: obj: ', obj )
//                     // gs().
//                     // dispatch( order.Rdx_Order_AddToCart( item, sect, item._cnt ) )
//                 } )
//         } )

//         dispatch( ClearCart( payload ) )
//     }   // renturn ...
// }   // Rdx_Order_ClearCart

/**
 */
export const Rdx_Order_ClearCart = ( payload ) =>
{
    return async (dispatch, gs) => {
        try
        {
            dispatch( ClearCart( payload ) )

            await AStorage.RemoveItem( 'cart')
        }
        catch( err )
        {
            console.warn( 'reducers/core: Rdx_Order_ClearCart: err: ', err )

            return {err}
        }
    }
    // return {
    //     type: 'order:clear-cart'
    //     ,   payload: payload
    // }
}

function ClearCart( payload )
{
    return {
        type: 'order:clear-cart'
        ,   payload: payload
    }
}

