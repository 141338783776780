/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import { Router } from "@reach/router"

import RsrvHome from './home'
import RsrvHist from './history'
import RsrvNew from './new'
import RsrvDetails from './details'

import P404 from '../_common/404'

/**
 */
class App_Rsrv extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <Router>
                <RsrvHome path='home' />
                <RsrvHist path='history' />
                <RsrvNew path='new' />
                <RsrvDetails path='details' />

                <P404 default />
            </Router>
            )
    }   // render
}   // class App_Rsrv

/**
 */
export {App_Rsrv, RsrvHome, RsrvHist, RsrvNew, RsrvDetails}
