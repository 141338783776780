/**
 * @copyright    Elmelo Ltd.
 */


export function ItemPrice(item,order_type) {

	// order_type = "collection"
	//
	// console.log("order_type", order_type)
	// console.log("item", item)

	// let item_price = item.price && item.price.def ? item.price.def : 0 ;
	//
	// if(order_type && order_type === "dinein")
	// {
	// 	if(item.price.in)
	// 		item_price = item.price.in ? item.price.in : 0
	// 	else
	// 		item_price = item.price.def ?  item.price.def : 0
	// }
	//
	// return Number( item_price );

	let item_price =  0 ;

	if(order_type && order_type.trim().toLowerCase() === "instore")
		item_price = item.price.in ? item.price.in : item.price.def ? item.price.def : 0
	else if(order_type && order_type.trim().toLowerCase() === "collection")
		item_price = item.price.coll ?  item.price.coll : item.price.def ? item.price.def : 0
	else if(order_type && order_type.trim().toLowerCase() === "delivery")
		item_price = item.price.delivery ?  item.price.delivery : item.price.def ? item.price.def : 0
	else
		item_price = item.price && item.price.def ? item.price.def : 0

	return Number( item_price );
}



// /**
//  * @copyright    Elmelo Ltd.
//  */
//
// export class ItemPrice {
//     /**
//      *    {
//      *      region: 'eu-west-1'         // optional
//      *  }
//      */
//     constructor(p) {
//
//         this.order_type = p.order_type;
//
//         // console.log("order type", this.order_type);
//     }
//
//     getPrice(item) {
//
//         // console.log("get price", item);
//         let item_price = item.Price;
//         if (this.order_type === "dinein") {
//
//             if (item.Inside_Price) {
//                 item.Inside_Price && item.Inside_Price != "" && item.Inside_Price != undefined ?
//                     item_price = item.Inside_Price : item.Price
//             }
//             if (item.Inside_price) {
//                 item.Inside_price && item.Inside_price != "" && item.Inside_price != undefined ?
//                     item_price = item.Inside_price : item.Price
//             }
//
//         }
//         return item_price;
//     }
//
//
// }
//
