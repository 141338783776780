/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Button, ButtonGroup,
    Image,
    Row,
    Col,
    Card,
    Modal,
    Carousel,
    Container,
}   from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import color from '../_common/colors.json'
import '../../css/_common.css'
import NavHdr from '../_common/nav_hdr'
import Loader from "react-loader-spinner";
import Info_Offer from "../info/offer";
// import {Profile_Head , Opening_Time} from '../profile/_common'

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faMapMarked, faPhoneVolume} from '@fortawesome/free-solid-svg-icons'

// import {Offer} from './_common'
// import Info_Offer from './offer'
// import ContentLoader from "react-content-loader";
/**
 */
class Offer_Home extends React.PureComponent
{
    /**
     */
    constructor(props)
    {
        super(props)
        this.state={
            openOffer:false,

            imageLink: null
        }
    }

    /**
     */
    render()
    {
        // // console.log( 'Info_Home : render: props: ', this.props )

         console.log( '----- ', this.props )

        return (
            <div style={{paddingTop: 0}}>
                <NavHdr offer={true} />

                {
                    this.state.openOffer ?
                        <Modal
                            show={true}
                            animation={true}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={()=>this.setState({openOffer:false})}
                        >

                            <Image
                                style={{height:"100%",width:"100%"}}
                                src={this.state.imageLink}
                            />

                        </Modal>:
                        null
                }

                {
                    this.props.__biz.initStatus === "inprogress" ?
                        <div className="centerScreen" style={{marginTop:32}}>
                            <Loader
                                type="Bars"
                                color="#009432"
                                height={200}
                                width={200}
                                timeout={30000000}

                            />
                        </div> :

                        <div className="screenWrapper">
                            <div style={{margin:16}}>
                                <div className="headerTitle">Current Offers</div>
                            </div>
                            <div className="offerDiv">
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer1.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer1.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Complimentary Cans and Lager
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer2.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer2.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Take Away Offer
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer3.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer3.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Easter Offer
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer4.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer4.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Tuesday Banquet Night Offer
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer5.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer5.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Welcome Offer
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer6.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer6.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Lovers Weekend Offer
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer7.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer7.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Festive Feast Offer
                                    </div>
                                </div>
                            </div>
                        </div>

                }



            </div>
        )
    }   // render

    // /**
    //  */
    // componentDidMount()
    // {
    // }
}   // class Info_Home


/**
 */
const mapStateToProps = (state) => {
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(Offer_Home)


