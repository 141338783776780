/**
 *	@copyright	Elmelo Ltd.
 */

export default ( state = {
    authStatus: '',   // 'auth' / 'unauth' / 'veri_reqd'
    bNetwork: true, bInternet: true,

    // alphaId: null,
    initStatus: 'stale',
    cfg: {}, menu: {},
    pubId: null,
    betaId: null,

}, action ) =>
{
    switch( action.type )
    {
    case 'core:services':
        return {...state, menu: action.payload}

    case 'core:auth_status':
        return {...state, authStatus: action.payload}

    case 'core:b_network':
        return {...state, bNetwork: action.payload}

    case 'core:b_internet':
        return {...state, bInternet: action.payload}

    // case 'core:alpha_id':
    //     return {...state, alphaId: action.payload}

    case 'core:init-status':
        return {...state, initStatus: action.payload}

    case 'core:cfg':
        return {...state, cfg: action.payload}

    case 'core:menu':
        return {...state, menu: action.payload}
    case 'core:pub_id':
        return {...state, pubId: action.payload}

    case 'core:beta_id':
        return {...state, betaId: action.payload}

    default:
        return state;
    }	// switch action.type
}


