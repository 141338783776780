/**
 *	@copyright	Elmelo Ltd
 *
 *  @note   To setup aws-iot follow instructions on https://github.com/aws/aws-iot-device-sdk-js/issues/86
 */

import AwsIot from 'aws-iot-device-sdk'
import cuid from 'cuid'
import {Core, Lambda} from '../AWS'

/**
 */
class ChatIoT
{
    /**
     */
    constructor( p )
    {
        this.cfg = p.cfg
        this.betaId = p.beta_id

        this.iot = null
        this.bReady = false
        this.bInitialising = false

        this.on = {
                Conn: p.OnConn,
                Err: p.OnErr,
                Msg: p.OnMsg,
                Event: p.OnEvent,
            }

        this.subscribed = []

        // // console.log( 'chat_iot/cstr: this.betaId: -> ', this.betaId )
    }   // cstr

    /**
     */
    Publish = async ( p ) =>
    {
        try
        {
            // // console.log( "ChatIoT : Publish : p : ", p );
            // const iot_topic = [this.betaId, this.cfg.stage, topic].join( '/' )
            // const iot_topic = topic

            const aws_lambda = new Lambda()

            const p_lambda = {
                    stage: this.cfg.stage,
                    actType: 'iot',
                    act: 'pub',
                    platform: this.cfg.pf ? this.cfg.pf : 'hs',

                    usr_id: p.usr_id,
                    topic: p.topic,
                    ch_id: p.ch_id,
                    payload: JSON.stringify( p.data ),
                }

            // // console.log( 'chat_iot/Publish: p_lambda: ', p_lambda )

            const resp_lambda = await aws_lambda.Invoke( p_lambda, this.cfg.lambda('chat', this.cfg.stage) )

            // // console.log( 'chat_iot/Publish: resp_lambda: ', resp_lambda )

            if( resp_lambda.errorMessage )
            {
                throw new Error( resp_lambda.errorMessage )
            }

            return resp_lambda
        }
        catch( err )
        {
            console.warn( 'chat_iot/ChatIoT: Publish: err: ', err )

            return Promise.reject( err )
        }
    }   // Publish

    /**
     */
    Subscribe = async ( topic, ch_id ) =>
    {
        try
        {
            // // console.log( 'chat_iot/Subscribe: this.betaId: -> ', this.betaId )

            const iot_topic = [this.betaId, this.cfg.platform, topic, ch_id].join( '/' )
            // const iot_topic = topic

            // // console.log( 'chat_iot/Subscribe: iot_topic: -> ', iot_topic )

            if( this.subscribed.find( x => x === iot_topic ) )
            {
                return { msg: 'OK' }
            }

            this.iot.subscribe( iot_topic )
            // const resp_sub = await IoT_Subscribe( this.iot, iot_topic )

            // // console.log( 'chat_iot/Subscribe: resp_sub: ', resp_sub )

            this.subscribed = [ ...this.subscribed, iot_topic ]

            // // console.log( 'chat_iot/Subscribe: this.subscribed: ', this.subscribed )

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'chat_iot/ChatIoT: Subscribe: err: ', err )

            return Promise.reject( err )
        }
    }   // Subscribe

    /**
     */
    Subscribe_Raw = async ( topic ) =>
    {
        try
        {
            // const iot_topic = [this.betaId, this.cfg.stage, topic].join( '/' )
            const iot_topic = topic

            // // console.log( 'chat_iot/Subscribe_Raw: iot_topic: ', iot_topic )

            if( this.subscribed.find( x => x === iot_topic ) )
            {
                return { msg: 'OK' }
            }

            this.iot.subscribe( iot_topic )
            // const resp_sub = await IoT_Subscribe( this.iot, iot_topic )

            // // console.log( 'chat_iot/Subscribe_Raw: resp_sub: ', resp_sub )

            this.subscribed = [ ...this.subscribed, iot_topic ]

            // // console.log( 'chat_iot/Subscribe_Raw: this.subscribed: ', this.subscribed )

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'chat_iot/ChatIoT: Subscribe_Raw: err: ', err )

            return Promise.reject( err )
        }
    }   // Subscribe_Raw

    /**
     */
    UnSubscribe = async ( topic ) =>
    {
        try
        {
            const iot_topic = [this.betaId, this.cfg.stage, topic].join( '/' )
            // const iot_topic = topic

            // // console.log( 'chat_iot/UnSubscribe: iot_topic: ', iot_topic )

            const idx_topic = this.subscribed.findIndex( x => x === iot_topic )

            if( idx_topic === -1 )
            {
                return { msg: 'OK' }
            }

            this.iot.unsubscribe( iot_topic )
            // const resp_unsub = await IoT_UnSubscribe( this.iot, iot_topic )

            // // console.log( 'chat_iot/UnSubscribe: resp_unsub: ', resp_unsub )

            this.subscribed.splice( idx_topic, 1 )

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'chat_iot/ChatIoT: UnSubscribe: err: ', err )

            return Promise.reject( err )
        }
    }   // UnSubscribe

    /**
     */
    Connect = (client_id) =>
    {
        return new Promise( async ( sol, rej ) => {
            try
            {
                if( this.bInitialising )
                {
                    console.warn( 'chat_iot/Connect: exitong: bInitialising' )

                    return {msg: 'in_progress'}
                }

                if( this.iot )
                {
                    console.warn( 'chat_iot/Connect: exitong: this.iot' )

                    return {msg: 'connected', client: this.iot}
                }

                this.bInitialising = true

                // // perform backend tasks
                // const aws_lambda = new Lambda()

                // const p_lambda = {
                //         stage: this.cfg.stage,
                //         actType: 'iot',
                //         act: 'init',
                //         platform: this.cfg.pf ? this.cfg.pf : 'hs',
                //         usr: 'alpha',
                //     }

                // // // console.log( 'chat_iot/Init: p_lambda: ', p_lambda )

                // const resp_lambda = await aws_lambda.Invoke( p_lambda, this.cfg.lambda('chat', this.cfg.stage) )

                // // // console.log( 'chat_iot/Init: resp_lambda: ', resp_lambda )

                // if( resp_lambda.errorMessage )
                // {
                //     throw new Error( resp_lambda.errorMessage )
                // }

                const aws_core = new Core()

                const usr_cred = await aws_core.Credentials()

                // // console.log( 'chat_iot/Init: usr_cred: ', usr_cred )

                // do the actual initialisation
                // const beta_id = usr_cred.identityId
                const uuid = cuid()
                // const random_no = Math.floor((Math.random() * 100000) + 1)
                const iot_endpt = [this.cfg.iot.prefix, 'iot', this.cfg.aws.roi, 'amazonaws.com'].join( '.' )

                const tmp_client_id = [uuid, client_id].join( ':' )


                const p_iot = {
                        host: iot_endpt,
                        protocol: 'wss',
                        clientId: tmp_client_id,
                        // clientId: [usr_cred.identityId, Date.now()].join(),
                        // clientId: usr_cred.identityId,

                        accessKeyId: usr_cred.accessKeyId,
                        secretKey: usr_cred.secretAccessKey,
                        sessionToken: usr_cred.sessionToken,
                    }

                // // console.log( 'chat_iot/Connect: p_iot: ', p_iot )

                const iot_client = AwsIot.device( p_iot )

                // // console.log( 'chat_iot/Connect: iot_client: ', iot_client )

                // // iot_client.list
                // const list_attached = await IoT_Policy_ListAttached( this.cfg )

                // // console.log( 'chat_iot/Init: list_attached: ', list_attached )

                // return sol({});

                iot_client.on( 'connect', () => {
                        // // console.log( 'chat_iot/Init: on.connect: connected ...' )

                        this.iot = iot_client
                        this.bInitialising = false
                        this.bReady = true

                        this.iot.on( 'message', this.OnMsg )

                        return sol( {msg: 'connected', client: this.iot} )
                    } )

                iot_client.on( 'error', (err) => {
                        console.warn( 'chat_iot/Init: OnErr: err: ', err )

                        if( !this.iot )
                        {
                            this.bInitialising = false
                            return rej( err )
                        }
                        // else if()   // check connection error and manage
                        // {
                        //     //
                        // }
                        else if( this.on.Err )
                        {
                            this.on.Err( err )
                        }
                        else
                        {
                            // no action on error
                        }
                    } )

                iot_client.on( 'reconnect', () => {
                        // console.log( 'chat_iot/Init: reconnect' )
                        this.bReady = true
                    } )

                iot_client.on( 'close', () => {
                        // console.log( 'chat_iot/Init: close' )
                        this.bReady = false
                    } )

                iot_client.on( 'disconnect', () => {
                        // console.log( 'chat_iot/Init: disconnect' )
                        this.bReady = false
                    } )

                iot_client.on( 'offline', () => {
                        // console.log( 'chat_iot/Init: offline' )
                        this.bReady = false
                    } )

                iot_client.on( 'end', () => {
                        // console.log( 'chat_iot/Init: end' )
                        this.bReady = false
                    } )

                // // console.log( 'chat_iot/Connect: exit: client_id: ', client_id )

                //
                return {client: iot_client}
            }
            catch( err )
            {
                console.warn( 'chat_iot/ChatIoT: Connect: err: ', err )

                return Promise.reject( err )
            }
        } )
    }   // Connect

    /**
     */
    Disconnect = async () =>
    {
        try
        {
            this.iot.end()

            this.iot = null
            this.bInitialising = false

            return {}
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }   // Disconnect

    /**
     */
    OnMsg = ( topic, msg ) =>
    {
        try
        {
            // // console.log( "chat_iot : OnMsg : topic : ", topic );
            // // console.log( "chat_iot : OnMsg : msg : ", msg );

            const data_recv = JSON.parse( msg )

            // // console.log( "chat_iot : OnMsg : data_recv : ", data_recv );

            if( 'msg:new' === data_recv.e )
            {
                if( this.on.Msg )
                    this.on.Msg( topic, data_recv )
            }
            else
            {
                if( this.on.Event )
                    this.on.Event( topic, data_recv )
            }

            return {}
        }
        catch( err )
        {
            console.warn( 'chat_iot/ChatIoT: OnMsg: err: ', err )

            return Promise.reject( err )
        }
    }   // OnMsg

    /**
     */
    On = ( p ) =>
    {
        if( p.OnConn )
        {
            this.on.Conn = p.OnConn
        }

        if( p.OnMsg )
        {
            this.on.Msg = p.OnMsg
        }
        if( p.OnEvent )
        {
            this.on.Event = p.OnEvent
        }

        if( p.OnErr )
        {
            this.on.Err = p.OnErr
        }
    }   // On

    /**
     */
    // required to be done only once ...
    Init = async () =>
    {
        try
        {
            const aws_lambda = new Lambda()

            const p_lambda = {
                    stage: this.cfg.stage,
                    actType: 'iot',
                    act: 'init',
                    platform: this.cfg.pf ? this.cfg.pf : 'hs',
                }

            // // console.log( 'chat_iot/Publish: p_lambda: ', p_lambda )

            const resp_lambda = await aws_lambda.Invoke( p_lambda, this.cfg.lambda('chat', this.cfg.stage) )

            // // console.log( 'chat_iot/Publish: resp_lambda: ', resp_lambda )

            if( resp_lambda.errorMessage )
            {
                throw new Error( resp_lambda.errorMessage )
            }

            return resp_lambda
        }
        catch( err )
        {
            console.warn( 'chat_iot/ChatIoT: Init: err: ', err )

            return Promise.reject( err )
        }
    }   // Init
}   // class ChatIoT

//
export default ChatIoT








