/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import { Router } from "@reach/router"

import CheckoutHome from './home'
import CheckoutPersonal from './personal'
import CheckoutDelivery from './delivery'
import CheckoutPayment from './payment'
import CheckoutSummary from './summary'
import CheckoutSuccess from './success'

import P404 from '../_common/404'

/**
 */
class App_Checkout extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <Router>
                <CheckoutHome path='home' />
                <CheckoutPersonal path='personal' />
                <CheckoutDelivery path='delivery' />
                <CheckoutPayment path='payment' />
                <CheckoutSummary path='summary' />
                <CheckoutSuccess path='success' />
                <P404 default />
            </Router>
            )
    }   // render
}   // class App_Checkout

/**
 */
export {CheckoutSuccess, CheckoutHome, CheckoutPersonal,CheckoutDelivery, CheckoutPayment, CheckoutSummary}
