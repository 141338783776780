/**
 *  @copyright   Elmelo Ltd
 */

import {AStorage} from '../../api/Utils'
import Utils_Menu from '../../api/Utils_Menu'
import {Colour} from '../../api/Utils_UI'
import {DDB,} from '../../api/AWS'

import ONT from '../../components/order/ont'

import * as Utils from './_utils'
import {Rdx_Checkout_SetSubtotal, Rdx_Checkout_SetItemCount,} from './checkout'

/**
 */
function Rdx_RsrvInitStatus( init_status )
{
    return {
            type: 'rsrv:init-status'
        ,   payload: init_status
        }
}

/** rsrv:menu
 */
function Rdx_RsrvBizId( biz_id )
{
    return {
            type: 'rsrv:biz_id'
        ,   payload: biz_id
        }
}

/** rsrv:menu
 */
function RsrvMenu( menu )
{
    // console.log( "RsrvMenu : menu : ", menu );
    return {
            type: 'rsrv:menu'
        ,   payload: menu
        }
}

/** rsrv:menu
 */
export const Rdx_RsrvInit = (ret_menu) =>
{
    return async (dispatch, gs) =>
    {
        try
        {
            if('inprogress' === gs().__rsrv.initStatus
                // &&  biz_id === gs().__rsrv.bizId
                )
            {
                return {msg: 'OK'}
            }

            dispatch( Rdx_RsrvBizId( gs().__biz.biz_id ) )
            dispatch( Rdx_RsrvInitStatus('inprogress') )
            //// console.log("GS",gs);

            const key_menu = Utils.Key( 'serv:menu', gs )
            const key_cart = Utils.Key( 'serv:cart', gs )

            // // console.log("GS",key_cart);
            const menu_obj_stored = localStorage.getItem( key_menu )
            const cart_sects = localStorage.getItem( key_cart )

            // console.log( 'reducers/rsrv: Rdx_Core_Init: menu_obj_stored: ', menu_obj_stored )
            // console.log( 'reducers/rsrv: Rdx_Core_Init: cart_sects: ', cart_sects )

            //
            if( menu_obj_stored )
            // if( false )
            {
                const fullMenu = JSON.parse( menu_obj_stored )

                const fullMenu_prep = Utils_Menu.Prep({...fullMenu}, gs().__rsrv.bizId);

                // console.log( 'reducers/rsrv: Rdx_Core_Init: fullMenu_prep: ', fullMenu_prep )

                //
                const menu_sects = /*await */Rsrv_PrepMenu(fullMenu, fullMenu_prep, 0);

                // // console.log( 'reducers/core: Rdx_Core_Init: menu_sects: ', menu_sects )

                const menu_obj_new = {menu: menu_sects, menu_prep: fullMenu_prep, menu_full: fullMenu}

                // // console.log( 'reducers/core: Rdx_Core_Init: gs(): ', gs() )

                if(cart_sects)
                {
                    // // console.log( 'reducers/core: Rdx_Core_Init: cart_sects: ', cart_sects )

                    cart_sects.forEach(sect => {
                        sect.data.forEach(item => {
                            fullMenu_prep.items[item._id]._cnt = item._cnt

                            if (item._mods)
                                fullMenu_prep.items[item._id]._mods = item._mods

                            if (item._modex)
                                fullMenu_prep.items[item._id]._modex = item._modex

                            if (item._opts)
                                fullMenu_prep.items[item._id]._opts = item._opts

                            // // console.log( 'reducers/core: Rdx_Core_Init: obj: ', obj )

                            dispatch(Rdx_RsrvCartUpd(item, sect, item._cnt))
                        })
                    })
                }   // if cart_sects

                // // console.log( 'reducers/core: Rdx_Core_Init: menu_obj_new: ', menu_obj_new )

                dispatch( RsrvMenu( menu_obj_new ) )

                if(ret_menu)
                {

                    dispatch( Rdx_RsrvInitStatus(   'stale') )

                    return
                }



            }   // if menu_obj_stored

            //
            const aws_ddb = new DDB( {} )

            let arr_resp = [];

            let p_query = {
                    TableName: gs().__cfg.db('serv', gs().__cfg.stage),
                    KeyConditionExpression: '#a = :a',
                    // ProjectionExpression: '',
                    ExpressionAttributeNames: {'#a': 'biz_id'},
                    ExpressionAttributeValues: {':a': gs().__rsrv.bizId},
                }

            while( true )
            {
                // console.log( 'reducers/rsrv: Rdx_Core_Init: p_query: ', p_query )

                const resp_query = await aws_ddb.Query( p_query )

                // console.log( 'reducers/rsrv: Rdx_Core_Init: resp_query: ', resp_query )

                arr_resp = [...arr_resp, ...resp_query.Items]

                if( !resp_query.LastEvaluatedKey )
                    break

                p_query.ExclusiveStartKey = resp_query.LastEvaluatedKey
            }   // while true

            // console.log( 'reducers/rsrv: Rdx_Core_Init: arr_resp: ', arr_resp )

            const fullMenu = arr_resp.reduce( (a, c) => {
                    const key_ = 'item' === c._t ? 'items'
                        :   'cat' === c._t ? 'cats'
                        :   'subcat' === c._t ? 'subcats'
                        :   'mod' === c._t ? 'mods'
                        :   'addon' === c._t ? 'addons'
                        :   'tag' === c._t ? 'tags'
                        // :   'allergen' === c._t ? 'allergens'
                        // :   'ingredient' === c._t ? 'ingredients'
                        :   'na'

                    if( !a[key_] )
                    {
                        // a[key_] = []
                        return a;
                    }

                    a[key_] = [...a[key_], c]

                    return a;
                }, {format_st: 'v1', biz_id: gs().__rsrv.bizId, items: [], cats: [], subcats: [], mods: [], addons: [], tags: [], allergens: [], ingredients: {}} );

            // Object.keys( menu_obj ).forEach( x => x.filter( (a, b) => (a-b) ) )

            // console.log( 'reducers/rsrv: Rdx_Core_Init: menu_obj: ', fullMenu )

            //
            const fullMenu_prep = Utils_Menu.Prep({...fullMenu}, gs().__rsrv.bizId);

            // // console.log( 'reducers/core: Rdx_Core_Init: fullMenu_prep: ', fullMenu_prep )

            //
            const menu_sects = /*await */Rsrv_PrepMenu(fullMenu, fullMenu_prep, 0/*data_menu.last_upd_menu*/);

            // console.log( 'reducers/core: Rdx_Core_Init: menu_sects: ', menu_sects )

            const menu_obj_new = {menu: menu_sects, menu_prep: fullMenu_prep, fullMenu: fullMenu}

            // console.log( 'reducers/core: Rdx_Core_Init: menu_obj_new: ', menu_obj_new )

            // // console.log( 'reducers/core: Rdx_Core_Init: gs(): ', gs() )

            //
            // gs().__rsrv.forEach( sect => {
            gs().__rsrv.cart_sects.forEach( sect => {
                    sect.data.forEach( item => {
                            // gs().__core.menu_prep.items[item._id].
                            // // console.log( 'reducers/core: Rdx_Core_Init: item: ', item );

                            // const obj = gs().__core.menu.menu_prep.items[item._id]

                            fullMenu_prep.items[item._id]._cnt = item._cnt

                            if( item._mods )
                                fullMenu_prep.items[item._id]._mods = item._mods

                            if( item._modex )
                                fullMenu_prep.items[item._id]._modex = item._modex

                            if( item._opts )
                                fullMenu_prep.items[item._id]._opts = item._opts

                            // // console.log( 'reducers/core: Rdx_Core_Init: obj: ', obj )
                            // gs().
                            // dispatch( rsrv.Rdx_Rsrv_AddToCart( item, sect, item._cnt ) )
                        } )
                } )

            //
            // localStorage.setItem( Core_StoreId(gs, 'menu'), JSON.stringify(menu_obj_new) )
            // localStorage.setItem( Core_StoreId(gs, 'menu'), JSON.stringify(fullMenu) )
            // console.log( 'reducers/core: Rdx_Core_Init: fullMenu: ', fullMenu )
            localStorage.setItem( key_menu, JSON.stringify(fullMenu) )

            //
            dispatch( RsrvMenu( menu_obj_new ) )
            dispatch( Rdx_RsrvInitStatus('stale') )

            //
            return { msg: 'OK' }
        }
        catch( err )
        {
            dispatch( Rdx_RsrvInitStatus('stale') )

            console.warn( 'reducers/rsrv: Rdx_RsrvMenuInit: err: ', err )

            return { err }
        }
    }   // return ...
}   // Rdx_RsrvMenuInit

/**
 */
export const Rdx_RsrvCartUpd = ( item, sect, cnt_change ) =>
{
    return async (dispatch, gs) => {
        try
        {
            dispatch( RsrvCartUpd( item, sect, cnt_change ) )

            // // console.log( 'reducers/rsrv: Rdx_RsrvCartUpd: gs(): ', gs() )

            const total_obj = ONT.GetTotal( gs().__rsrv.cart_sects, gs().__rsrv.type )

            // // console.log( 'reducers/rsrv: Rdx_RsrvCartUpd: total_obj: ', total_obj )

            dispatch( Rdx_Checkout_SetSubtotal(total_obj.total) )
            dispatch( Rdx_Checkout_SetItemCount(total_obj.item_cnt) )

            const key_store = Utils.Key( 'cart', gs )

            // // console.log( 'reducers/rsrv: Rdx_RsrvCartUpd: key_store: ', key_store )

            await AStorage.Set( key_store, gs().__rsrv.cart_sects )

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'reducers/rsrv: Rdx_RsrvCartUpd: err: ', err )

            return {err}
        }
    }   // return ...
}   // Rdx_RsrvCartUpd

/**
 */
const RsrvCartUpd = (item, sect, cnt_change) =>
{
    return {
            type: 'rsrv:cart_upd'
        ,   payload: {item, sect, cnt_change}
        };
}   // RsrvCartUpd

/**
 */
export const Rdx_RsrvInfoType = ( data ) =>
{
    return {
            type: 'rsrv:info_type'
        ,   payload: data
        };
}   // Rdx_RsrvInfoType

/**
 */
export const Rdx_RsrvInfoCust = ( type, data ) =>
{
    return {
            type: 'rsrv:info_cust'
        ,   payload: {type, data}
        }
}   // Rdx_RsrvInfoCust

// export const Rdx_RsrvCartAdd = ( item, cnt_2_add ) =>
// {
//     return {
//         type: 'rsrv:cart_add'
//     ,   payload: {item, cnt_2_add: cnt_2_add ? cnt_2_add : 1}
//     };
// }

// export const Rdx_RsrvCartRem = ( item, cnt_2_rem ) =>
// {
//     return {
//         type: 'rsrv:cart_rem'
//     ,   payload: {item, cnt_2_rem: cnt_2_rem ? cnt_2_rem : 1}
//     };
// }



/**
 */
export const Rdx_RsrvDiscAdd = ( disc_obj ) =>
{
    return {
            type: 'rsrv:disc_add'
        ,   payload: disc_obj
        }
}

/**
 */
export const Rdx_RsrvDiscRem = ( disc_obj ) =>
{
    return {
            type: 'rsrv:disc_rem'
        ,   payload: disc_obj
        }
}

/**
 */
export const Rdx_RsrvPay = ( pay_obj ) =>
{
    return {
            type: 'rsrv:pay'
        ,   payload: pay_obj
        }
}

/**
 */
export const Rdx_Rsrv_invoice = ( incoice ) =>
{
    return {
            type: 'rsrv:invoice'
        ,   payload: incoice
        }
}

/** cart clear*/
export const RDX_ClearRsrvCart = (payload) => {
    return {
            type: "rsrv:cart_clear",
            payload: payload
        }
}

/**
 */
export const Rdx_RsrvUpdateItem = payload => {
    return {
            type: "rsrv:update",
            payload: payload
        }
}   // Rdx_RsrvUpdateItem

/**
 */
export const Rdx_RsrvClear = payload =>
{
    return {
            type: 'rsrv:clear',
            payload: payload
        }
}   // Rdx_RsrvClear

// /**
//  */
// function RsrvMenu( menu_obj )
// {
//     return {
//             type: 'rsrv:menu'
//         ,   payload: menu_obj
//         }
// }

/**
 */
function Rsrv_PrepMenu(menu, menu_prep)
{
    let menu_obj = [], tmp_menu_obj = {};

    //
    menu.items.forEach(item => {
        try
        {
            const sec_id = [item.cat.id, item.subcat.id].join(':');

            if (!tmp_menu_obj[sec_id])
            {
                // // console.log( 'sec_id: ', sec_id );
                const col_ = Colour.FromStr([item.cat.id, item.subcat.id].join(':'));

                // const cat_obj = menu.

                tmp_menu_obj[sec_id] = {
                    data: []
                    , title: [item.cat.name, item.subcat.name].join(':')
                    , cat: item.cat.name
                    , sub_cat: item.subcat.name
                    , cat_id: item.cat.id
                    , sub_cat_id: item.subcat.id
                    , cat_idx: menu_prep.cats[item.cat.id].idx
                    , sub_cat_idx: menu_prep.subcats[item.subcat.id].idx
                    , col: col_
                    , col_inv: Colour.Invert(col_)
                    // , tag: Core_CreateTag(item.Sub_Cat)
                    };

                // // console.log( sec_id );
            }

            //
            tmp_menu_obj[sec_id].data.push(item);
        }
        catch( err )
        {
            console.warn( 'reducers/core: Rsrv_PrepMenu: err: ', err )
        }
    });	// menu.items

    // // console.log( 'PrepMenu: tmp_menu_obj', tmp_menu_obj );

    //
    Object.keys(tmp_menu_obj).forEach(x => {
        menu_obj.push(tmp_menu_obj[x]);
    });

    // // console.log( 'PrepMenu: menu_obj 1', menu_obj );

    // sort menu_obj
    menu_obj.sort((a, b) => {
        if (a.cat_idx < b.cat_idx)
            return -1;
        else if (a.cat_idx > b.cat_idx)
            return 1;
        else if (a.sub_cat_idx < b.sub_cat_idx)
            return -1;
        else if (a.sub_cat_idx > b.sub_cat_idx)
            return 1;
        else
            return 0;
    });

    // sort data in menu obj
    menu_obj = menu_obj.map(sect => {
        // // console.log( 'sect: ', sect );

        sect.data.sort((a, b) => (a.idx - b.idx));

        // // console.log( 'sect.data: ', sect.data );

        sect.data = sect.data.map((x, idx) => {
            x._v_idx = idx;
            x._cnt = 0;

            // // console.log('x.Name: ', x, ', x.Mods', x.Mods ? 'true' : 'false', ', x.Mods.length: ');

            //
            if (x.setm_items)
                x._type = 'setmeal';
            else if (x.mod.name)
                x._type = 'mod';
            else
                x._type = 'basic';

            /// @todo check for mod_ex
            if ( (x.secmods && x.secmods.length) || (x.addons && x.addons.length) )
            {
                x._type = 'mod_ex'
            }
            // if (x.Addons && x.Addons.length)
            // {
            //     x._type = 'mod_ex'
            // }

            /// @todo check for setmeal

            // modifiers
            if (x._type === 'mod' || x._type === 'mod_ex')
            {
                // // console.log( '+++++++++++++ mod_ex: x: ', x );

                if (menu_prep.mods)
                {
                    x._mods = menu_prep.mods[x.mod.id]
                        ? menu_prep.mods[x.mod.id]
                        : null
                        ;

                    // if( !x._mods )
                    //     return mod;

                    // Making deep copies and initiating values ...
                    if (x._mods)
                    {
                        x._mods = {...x._mods};
                        x._mods.opts = [...x._mods.opts];
                        x._mods.opts = x._mods.opts.map( opt => {
                                opt._cnt = 0;
                                return opt;
                            });
                    }   // if x._mods
                }   // if menu_prep.mods
            }   // if x._type === mod

            // secondary modifiers and addons
            if (x._type === 'mod_ex')
            {
                // // console.log('************ mod_ex: x: ', x);

                // // console.log('************ menu_prep: ', menu_prep);

                if (menu_prep.mods && x.secmods && x.secmods.length)
                {
                    x._secmods = x.secmods.reduce((acc, sec_id) => {
                        if (!menu_prep.mods[sec_id])
                            return acc;

                        const sec_mod = {...menu_prep.mods[sec_id]};

                        // deep copy ...
                        sec_mod.opts = [...sec_mod.opts];
                        sec_mod.opts = sec_mod.opts.map( opt => {
                                opt._cnt = 0;
                                return opt;
                            });

                        // return acc.push( sec_mod );
                        return [...acc, sec_mod];
                    }, []);
                }   // if menu_prep.sec_mods

                if (menu_prep.addons && x.Addons && x.Addons.length)
                {
                    x._addons = x.Addons.reduce((acc, addon) => {
                        // addon = Utils.MD5([menu_prep.biz_id, addon, '0'].join(':'));

                        if (!menu_prep.addons[addon])
                            return acc;

                        const addon_obj = {...menu_prep.addons[addon]};
                        addon_obj.opts = [...addon_obj.opts];
                        addon_obj.opts = addon_obj.opts.map( opt => {
                                opt._cnt = 0;
                                return opt;
                            });

                        // return acc.push( addon_obj );
                        return [...acc, addon_obj];
                    }, []);
                }   // if menu_prep.addons
            }   // x._type === 'mod_ex'
            //
            return x;
        });

        // // console.log( 'sect.data: ', sect.data );
        return sect;
    });

    menu_obj = menu_obj.map((x, idx) => {
        x.sect_idx = idx;

        return x;
    });

    return menu_obj;
}	// Rsrv_PrepMenu


