import React from 'react'
import {
    Image,
    Button,
    Row,
    Col,
    Form,
    Modal, Alert
} from 'react-bootstrap'


import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import '../../css/_common.css'
// import elml_cfg from '../../_config/elml_cfg'
import {Lambda,Utils} from "../../api"
import AddNew_Address from './addNewAddress'
import ContentLoader from 'react-content-loader'
import {Data_Details_Box} from './_common'
import {Profile} from './profile'
// import Loader from "./card";
import Loader from 'react-loader-spinner'

class Address_Details extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )
        this.state={
            newAddress:false,
            addrList : [],
            bloading: false,

        }
    }

    addNewAddress= (data) =>{

        if(data)
        {

            this.setState( { addrList: [...this.state.addrList, data], newAddress: false })
        }
    }
    /**
     */
    componentDidMount()
    {
        this.loadUserAddress()
            .then()
            .catch()
    }
    /**
     */
    loadUserAddress = async ()=>{

        try {

            this.setState({bloading: true})

            const {__cfg} = this.props

            const profile = new Profile( {} )

            const add_list = await profile.GetAddressList(__cfg)

            if (!add_list.errorMessage)
            {
                this.setState({addrList: add_list});
            }

            this.setState({bloading: false})

        }catch (err) {

            return Promise.reject( err )
        }
    }
    /**
     */
    render()
    {
        // console.log("render data", this.state.addrList);

        if(this.state.bloading){

            return(
                <div className="centerScreen" style={{marginTop:32}}>
                    <Loader
                        type="Bars"
                        color="#009432"
                        height={200}
                        width={200}
                        timeout={30000000}

                    />
                </div>
            )
        }
        return (
            <div className="profileBodyDiv">
                <div>
                    <div style={{flexDirection: "row", display: "flex"}}>
                        <h3 style={{flex: 1}}> Address Details</h3>
                        <Button onClick={() => this.setState({newAddress:true})} variant="info">
                            Add New Address
                        </Button>
                    </div>
                    <div>
                        <Row>
                            {

                                this.state.addrList.length === 0 ?
                                    <div className="imageText">
                                        <Image
                                            className="orderCompleteImage"
                                            style={{height:250,width:"auto"}}
                                            src={require('../assets/img/placeholder/noAddress.png')}
                                            rounded
                                        />
                                        <div className="alertMessage">
                                            No address added
                                        </div>
                                    </div>
                                    :
                                this.state.addrList.map((addr,idx)=>(
                                    <Data_Details_Box
                                        key={idx}
                                        item={addr}
                                        edit={false}
                                        delete={true}
                                        OnDelete={this.OnDelete}
                                    />
                                ))

                            }


                        </Row>
                    </div>
                    {
                        this.state.newAddress ?
                            <AddNew_Address {...this.props}
                                            hideModal={()=> this.setState({newAddress: false}) }
                                            loadUserAddress={this.loadUserAddress}/> : null
                    }
                </div>
            </div>
        )
    }

    /**
     */
    OnDelete = ( x ) =>
    {
        // console.log(x)

        if (window.confirm('Are you sure you wish to delete this address?'))
            this.Delete(x)
                .then()
                .catch()
        // Alert.alert(
        //     'Delete this address?',
        //     [x.line1, x.line2, x.city, x.postal_code].join(' '),
        //     [
        //         {
        //             text: 'Cancel',
        //             onPress: () => {},
        //             style: 'cencel'
        //         },
        //         {
        //             text: 'OK',
        //             onPress: () => {
        //                 this.Delete( x )
        //                     .then( data => {} )
        //                     .catch( data => {} )
        //             }
        //         },
        //     ],
        //     {cancelable: false}
        // )
    }

    /**
     */
    Delete = async ( x ) =>
    {
        try
        {
            this.setState( {bRefresh: true} )

            const {__cfg} = this.props;


            // call lambda to delete this address.
            const aws_lambda = new Lambda( {} )

            const params = {
                actType: 'profile'
                ,	act: 'address:del'
                ,	stage: __cfg.stage
                ,	usr: 'beta'
                ,	address: x
                ,	b_shared: true
            }

            // // console.log( 'AddressList: Delete: p_lambda: ', p_lambda )

            const resp_lambda = await aws_lambda.Invoke( params, __cfg.lambda( 'beta', __cfg.stage ) );

            // // console.log( 'AddressList: Delete: resp_lambda: ', resp_lambda )

            if( resp_lambda.errorMessage )
                throw new Error( resp_lambda.errorMessage )

            // refresh address list
            await this.loadUserAddress()

            //
            // this.setState( {bRefresh: false} ) already called in this.Init()

            //
            return {msg: 'OK'}
        }
        catch( err )
        {
            this.setState( {bRefresh: false} )

            console.warn( 'AddressList: AddNew: err: ', err )

            return Promise.reject( err )
        }
    }
}
/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Address_Details )
