/**
 *	@copyright	Elmelo Ltd.
 */

import elml_biz from '../../_config/elml_biz'

export default ( state = {...elml_biz,
        initStatus: 'stale',

        suspend: {},
        ot: {},
        contact: {},
        addr: {},

        delivery: {},
        settings: {},

        discount: {},

        imagesFeatured: [],
        imagesGallery: [],

        offers: [],
        offersFeatured: [],

        awards: []
    }, action ) =>
{
    switch( action.type )
    {
        case 'biz:init-status':
            return {...state, initStatus: action.payload}

        case 'biz:offers-featured':
            return {...state, offersFeatured: action.payload}

        case 'biz:offers':
            return {...state, offers: action.payload}

        case 'biz:images-featured':
            return {...state, imagesFeatured: action.payload}

        case 'biz:images-gallery':
            return {...state, imagesGallery: action.payload}

        case 'biz:suspend':
            return {...state, suspend: action.payload}

        case 'biz:ot':
            return {...state, ot: action.payload}

        case 'biz:contact':
            return {...state, contact: action.payload}

        case 'biz:addr':
            return {...state, addr: action.payload}

        case 'biz:delivery':
            return {...state, delivery: action.payload}

        case 'biz:settings':
            return {...state, settings: action.payload}

        case 'biz:discount':
            return { ...state, discount: action.payload }

        case 'biz:offers-featured':
            return {...state, offersFeatured: action.payload}

        case 'biz:offers':
            return {...state, offers: action.payload}

        case 'biz:awards':
            return {...state, awards: action.payload}

        default: return state
    }   // switch
}

