/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Button, ButtonGroup,
    Image,
    Row,
    Col,
    Card,
    Modal,
    Carousel,
    Container,
}   from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import color from '../_common/colors.json'

/**
 */
class Info_Gallery extends React.PureComponent
{
    /**
     */
    constructor(props)
    {
        super(props)

        // console.log("gallery_home props", props)

        this.state = {
            bModal: false,
            idxModal: 0,
            imgModal: null,
            width: window.innerWidth,
        }
    }

    /**
     */
    render()
    {
        // // console.log( 'Info_Gallery : render : props : ', this.props )

        // if (!this.props.__biz.imagesGallery)
        // {
        //     return null
        // }

        // // console.log( 'Info_Gallery: render: this.props.__biz.imagesGallery: ', this.props.__biz.imagesGallery )

        return (
            <div className="offerContainer">
                {/*<div>*/}
                    {/*<div className="headerTitle">Gallery</div>*/}
                {/*</div>*/}
                <div>
                    <Row className="itemImage">
                    {
                        this.props.__biz.imagesGallery.map((x, i) => (
                            <Col style={{padding: 0}} xs="12" md="6" lg="4"
                                    onClick={() => {
                                        // console.log('gallery: image: i: ', i);
                                        // console.log('gallery: image: x: ', x);
                                        this.setState({bModal: true, idxModal: i, imgModal: x})
                                    }}
                            >
                                <img className="galleryImage" src={x.url} />
                                {/*// console.log("image urls", x.url)*/}
                            </Col>
                        ))
                    }
                    </Row>

                    <div>
                    {
                        this.state.bModal
                    ?   <Modal
                            centered
                            size={ this.state.width > 1366 ? "xl" :
                                this.state.width > 992 ? "lg" : "md"}
                            show={this.state.bModal}
                            onHide={() => this.setState({bModal: false, idxModal: 0, imgModal: null})}>
                            <div>
                                <img style={{width: "100%", height: "100%"}} src={this.state.imgModal.url}/>
                            </div>
                            {
                                this.state.imgModal.info && this.state.imgModal.info.title && this.state.imgModal.info.desc
                                ?
                                    <div>
                                    {
                                        this.state.imgModal.info && this.state.imgModal.info.title
                                    ?   <div className="modalImgTitle" style={{color: color.colors.primary,}}>{this.state.imgModal.info.title}</div>
                                    :   null
                                    }
                                    {
                                        this.state.imgModal.info && this.state.imgModal.info.desc
                                    ?   <div className="modalImgDescription">{this.state.imgModal.info.desc}</div>
                                    :   null
                                    }
                                    </div>
                                :
                                null
                            }
                        </Modal>

                    :   null
                    }
                    </div>
                </div>
            </div>
        )
    }   // render

    // /**
    //  */
    // componentDidMount()
    // {
    // }
}   // Info_Gallery

/**
 */
const mapStateToProps = (state) => {
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(Info_Gallery)

