/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Container,
} from 'react-bootstrap'

import NavHdr from './nav_hdr'

/**
 */
class P404 extends React.PureComponent
{
    // constructor( props )
    // {
    //     super( props )
    // }

    render()
    {
        return (
            <div>
                <NavHdr />
                <Container>
                    <h1>Page not found</h1>
                </Container>
            </div>
            )
    }
}   // P404

/**
 */
export default P404
