/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Container,
    Button,
    Modal
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import NavHdr from '../_common/nav_hdr'
import color from '../_common/colors.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faShoppingCart,faListUl,faPlusSquare, faTrashAlt, faTimes, faPlus, faMinus} from '@fortawesome/free-solid-svg-icons'
import '../../css/_common.css'
import {MenuPage_Box} from './_common'
import Item from './items'
import Order_Cart from './cart'
// import Loader from "../home/home";
import Loader from 'react-loader-spinner'


// import ONT from './ont'

/**
 */
class Order_Home extends React.PureComponent
{
    /**
     */
    constructor(props)
    {
        super(props)

        this.state = {
                height: window.innerHeight - (56 + 200),
                catheight: window.innerHeight - 56,
                showCategory:false,
                showCart:false,
                sHeight:window.innerHeight-72,
                sWidth:window.innerWidth,
                // ssWidth:window.innerWidth*.8,
            };

        this.refsCreated = false;
        this.subCatRefs = [];
    }

    updateWidthAndHeight = () => {
        this.setState({sWidth:window.innerWidth});
        this.setState({sHeight:window.innerHeight});
    };
    /**
     */
    render()
    {
        // console.log( 'Order_Home.render: this.props: ', this.props );

        if(this.props.__core.initStatus === "inprogress" )
        {
            return(
                <div>
                    <NavHdr order={true}/>
                    <div className="centerScreen" style={{height:this.state.sHeight}}>
                        <Loader
                            type="Bars"
                            color="#009432"
                            height={this.state.sHeight/3}
                            width={this.state.sWidth/3}
                            timeout={30000000}

                        />
                    </div>
                </div>

            )

        }

        if( !this.refsCreated && this.props.__core.menu.menu )
        {
            this.subCatRefs = this.props.__core.menu.menu.reduce((acc, value) => {
                acc[ [value.cat_id , value.sub_cat_id].join(":") ] = React.createRef();
                return acc;
            }, {});

            this.refsCreated = true;
        }

        return (
            <div style={{paddingTop: 0}}>
                <NavHdr order={true}/>

                <div className="orderWrapper">
                    <div className="orderContainer">
                        {/*left categories*/}
                        {
                            this.state.showCategory ?
                                <div className="overlayModal">
                                   <div className="phoneCat">
                                       <div className="commonFlex phoneCatHead">
                                           <div>
                                               Category
                                           </div>
                                           <div
                                               className="catClose"
                                               onClick={() => this.showCategory(false)}>
                                               <FontAwesomeIcon icon={faTimes} color="red" size="lg"/>
                                           </div>
                                       </div>
                                       <div style={{marginTop:46,paddingLeft:16,paddingRight:16}}>
                                           {
                                               this.props.__core.menu && this.props.__core.menu.menu
                                                   ? this.props.__core.menu.menu.map((sect, sect_idx) => (
                                                   <MenuPage_Box
                                                       className="categoryDiv"
                                                       style={{borderColor: color.colors.light}}
                                                       key={[sect.cat_id, sect.sub_cat_id].join(':')}
                                                       onPress={() => this.scrollToSubcat([sect.cat_id, sect.sub_cat_id].join(":"))}
                                                       title={sect.title}
                                                   />
                                               ))

                                                   : null
                                           }
                                       </div>
                                   </div>
                                </div>
                                :
                                <div className="categoryContainer"
                                     style={{height: this.state.catheight}}
                                >
                                    {
                                        this.props.__core.menu && this.props.__core.menu.menu
                                            ? this.props.__core.menu.menu.map((sect, sect_idx) => (
                                            <MenuPage_Box
                                                className="categoryDiv"
                                                style={{borderColor: color.colors.light}}
                                                key={[sect.cat_id, sect.sub_cat_id].join(':')}
                                                onPress={() => this.scrollToSubcat([sect.cat_id, sect.sub_cat_id].join(":"))}
                                                title={sect.title}
                                            />
                                        ))

                                            : null
                                    }
                                </div>
                        }

                        {/*middle menu items*/}
                        <div className= "csItemRightAfter">
                            <div className="itemContainer">
                                
                                    {
                                        this.props.__core.menu && this.props.__core.menu.menu
                                            ? this.props.__core.menu.menu.map((sect, sect_idx) => (
                                                // <h4 style={{color: color.colors.primary, marginBottom: 20}}>{sect.title}</h4>
                                                <div
                                                    key={[sect.cat_id, sect.sub_cat_id].join(':')}
                                                    ref={this.subCatRefs[[sect.cat_id, sect.sub_cat_id].join(":")]}
                                                >
                                                    {/*{*/}
                                                    {/*    console.log(sect)*/}
                                                    {/*}*/}
                                                    <MenuPage_Box
                                                        className="itemDiv"
                                                        style={{borderColor: color.colors.black, paddingTop: 70}}
                                                        id={sect.sub_cat_id}
                                                        title={sect.title}
                                                        desc={sect.desc}
                                                        key={sect.sub_cat_id}
                                                        item={
                                                            sect.data.map((item, item_idx) => (
                                                                // "t" === item.vis && item.vis.online ? item.vis.online : (item.vis && item.vis.def ? item.vis.def : null) ?
                                                                //console.log(item.vis && item.vis.online ? item.vis.online : (item.vis && item.vis.def ? item.vis.def : null),item.name):
                                                                //     :null

                                                                "t" === (item.vis && item.vis.online ? item.vis.online : (item.vis && item.vis.def ? item.vis.def : null) )?
                                                                    "t" === (item.avail && item.avail.online ? item.avail.online : (item.avail && item.avail.def ? item.avail.def :null))?
                                                                <div key={item._id}>
                                                                    <Item item={item} item_idx={item_idx} sect={sect}
                                                                        //   addToCart={this.addToCart}
                                                                    />
                                                                </div>
                                                                    :null
                                                                    :null
                                                            ))
                                                        }
                                                    />
                                                </div>
                                            ))

                                            : null
                                    }
                                

                                {/*phone cart and category */}
                                <div
                                    onClick={() => this.showCart(true)}
                                    className="cartMobile"
                                    style={{background: "linear-gradient(to right, #009432, #009432)"}}>
                                    <div className="cartTotalDiv">
                                        Total <span style={{
                                        fontSize: 20,
                                        marginLeft: 10,
                                        letterSpacing: 0.5,
                                        fontWeight: 700
                                    }}>£{this.props.__checkout.charges.subtotal.amount.toFixed(2)}</span>
                                    </div>
                                    <div className="cartIcon">
                                        <FontAwesomeIcon
                                            icon={faShoppingCart} size="lg"
                                            color={color.colors.primary}
                                        />
                                        <div className="cartCount">{this.props.__checkout.cntItem}</div>
                                    </div>
                                </div>

                                <div
                                    onClick={() => this.showCategory(true)}
                                    className="categoryFloat"
                                >
                                    <FontAwesomeIcon style={{marginRight: 5}} icon={faListUl} size="xs"
                                                     color={color.colors.primary}
                                    />
                                    category
                                </div>
                            </div>
                        </div>
                        <div style={{right:this.state.sWidth > 1999 ? (this.state.sWidth*0.15):0}} className={this.state.showCart ? "mobileCart" : "cartContainer"}>
                            <Order_Cart showCart={this.showCart}/>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    /**
     */
    componentDidMount()
    {
        window.addEventListener('resize',this.updateWidthAndHeight)
    }
    /**
     */
    componentWillMount()
    {
        window.removeEventListener('resize',this.updateWidthAndHeight)

    }

    /**
     */
    showCategory = (val)=>
    {
        this.setState({showCategory:val});
        val?
            document.body.classList.add('bodyOf') :
            document.body.classList.remove('bodyOf')
    }

    /**
     */
    showCart = (val)=>
    {
        this.setState({showCart:val});
        val?
            document.body.classList.add('bodyOf') :
            document.body.classList.remove('bodyOf')
    }

    /**
     */
    scrollToSubcat = subcatID =>
    {
        this.subCatRefs[subcatID].current.scrollIntoView( {
                behavior: 'smooth',
                block: 'start',
            } );
    }



}   // class Order_Home

/**
 */
const mapStateToProps = (state) => {
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(Order_Home)


