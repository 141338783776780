/**
 *	@copyright	Elmelo Ltd.
 */

/**
 */
class Order
{
	/**
	 */
	constructor()
	{
		this.cart_obj = {} ;
		this.cart_sects = [];
		this.ts_change = Date.now();
		this.email = "";
		this.name = {};
		this.phone = "";
		this.type = "";
		this.addr = "";
	}   // cstr

	/**
	 */
	updateCart = (item, sect, cnt_change) =>
	{
		// // console.log( "Reducers : Order : addToCart : item : ", item );
		// // console.log( "Reducers : Order : addToCart : sect : ", sect );
		// // console.log( "Reducers : Order : addToCart : cnt_change : ", cnt_change );

		// add to cart
		let 	cart_obj   = this.cart_obj
			,	cart_sects = this.cart_sects
			;

		if( !cart_obj[sect.cat_id] )
		{
			cart_obj[sect.cat_id] = {
					cat_id: sect.cat_id
				,	cat_idx: sect.cat_idx
				,	cat: sect.cat
				,	data: []
				,	data_obj: {}
				};

			// add to array
			cart_sects.push( cart_obj[sect.cat_id] );
			// sort
			cart_sects.sort( (a, b) => (a.cat_idx-b.cat_idx) );
		}

		if( !cart_obj[sect.cat_id].data_obj[item._id] )
		{
			cart_obj[sect.cat_id].data_obj[item._id] = item;
			cart_obj[sect.cat_id].data.push( item );
		}

		// check for zeros in cart items
		if( !cnt_change  )
		{
			// const idx_item = cart_obj[sect.cat_id].data.find( x => x._id === item._id );

			const idx_item = cart_obj[sect.cat_id].data.findIndex(function (_item) {
					// // console.log(item);
					return item._id === _item._id;
				});

			// // console.log("adsada ", idx_item);
			// // console.log("adsada ", cart_obj[sect.cat_id].data);
			cart_obj[sect.cat_id].data.splice( idx_item,1);

			// // console.log("cart_sects 1",cart_sects);

			cart_sects = cart_sects.filter( x => x.data.length );

			delete cart_obj[sect.cat_id].data_obj[item._id];

			if(!cart_obj[sect.cat_id].data.length)
			{
				delete cart_obj[sect.cat_id]
			}
			// if( !cart_obj[sect.cat_id].data.length )
			// if( Object.entries(cart_obj[sect.cat_id].data_obj).length === 0 )
			// {
			//     // console.log("adsada 1");
			//
			//     delete cart_obj[sect.cat_id];
			// }
		}

		if(item._type === "mod_ex")
		{
			if(item._opts)
			{
				Object.keys(item._opts).map((key, idx) => {
						const opt_obj = item._opts[key];
						// // console.log("opt_obj opt_obj", opt_obj);
						
						if (!opt_obj._cnt) {
							
							delete item._opts[key];
						}
					});
			}
		}

		if(item._type === "setmeal")
		{
			if(item._setm)
			{
				Object.keys(item._setm).map((key, idx) => {
						const opt_obj = item._setm[key];
						// // console.log("opt_obj opt_obj", opt_obj);

						if (!opt_obj._cnt)
						{
							delete item._setm[key];
						}
					});
			}
		}

		// sort out items in cart
		if( cart_obj[sect.cat_id] )
		{
			cart_obj[sect.cat_id].data.sort( (a, b) => {
				// if (a.Cat_Idx < b.Cat_Idx)
				// 	return -1;
				// else if (a.Cat_Idx > b.Cat_Idx)
				// 	return 1;
				// else if (a.Sub_Cat_Idx < b.Sub_Cat_Idx)
				// 	return -1;
				// else if (a.Sub_Cat_Idx > b.Sub_Cat_Idx)
				// 	return 1;
				if (a.idx < b.idx)
					return -1;
				else if (a.idx > b.idx)
					return 1;
				else if (a._v_idx < b._v_idx)
					return -1;
				else if (a._v_idx > b._v_idx)
					return 1;
				else
					return 0;
			} );
		}

		// // console.log( "Rdx_Order : OnCnt : cart_Obj : ", cart_obj );
		// // console.log( "Rdx_Order : OnCnt : cart_sects : ", cart_sects );

		this.cart_obj = cart_obj ;
		this.cart_sects = cart_sects;
	}	// addToCart

	/**
	 */
	clearCart = () =>
	{
		try
		{
			let cart_sects = this.cart_sects;

			// // console.log( "Rdx_Order : clearCart: cart_Obj : ", cart_obj );
			// // console.log( "Rdx_Order : clearCart: cart_sects : ", cart_sects );

			cart_sects.forEach( sect => {
					// // console.log( "Rdx_Order : clearCart: sect : ", sect )

					sect.data.forEach( item => {
							// // console.log( "Rdx_Order : clearCart: item : ", item )

							// this.updateCart( item, sect, 0 )
							item._cnt = 0

							if( item._mods )
							{
								item._mods.opts.forEach( opt => {opt._cnt = 0} )
							}

							if( item._opts )
							{
								item._opts = []
							}

							if( item._setm )
							{
								item._setm = {}
							}
						} )
				} )

			this.cart_obj = {};
			this.cart_sects = [];
		}
		catch( err )
		{
			console.warn( 'Rdx_Order : clearCart: err: ', err )
		}
	}

	/**
	 */
	Update = () =>
	{
		return {...this, ts_change: Date.now()};
	}

	/**
	 */
	ClearAll = () =>
	{
		this.type = "";
		this.addr = "";
	}
	/**
	 */
	SetOldOrder = ( payload ) =>
	{
		// // console.log("SetOldOrder" , payload)

		this.cart_obj = payload.cart_obj
		this.cart_sects = payload.cart_sects
	}
}	// Order

/**
 */
export default ( state = new Order, action ) =>
{
    switch( action.type )
    {
	    case 'core:menu':
	        return {...state, menu: action.payload.menu, menuTs: action.payload.menu_ts}
	
	    case 'order:menu-req-status':
	        return {...state, menuReqStatus: action.payload}
	        
	    // case 'order:add-to-cart':
		//     state.updateCart(action.payload.item, action.payload.sect, action.payload.cnt_change);
		//     return state.Update();
		    
	    case 'order:cart-upd':
		    state.updateCart(action.payload.item, action.payload.sect, action.payload.cnt_change);
		    return state.Update();
	    	
	    case 'order:clear-cart':
	    	state.clearCart();
	    	return state.Update();;

	    case 'order:clear-info':
	    	state.ClearAll();
	    	return state.Update();

		case 'order:old-order':
			state.SetOldOrder( action.payload )
			return state.Update()

	    default:
	        return state;
    }	// switch action.type
}


