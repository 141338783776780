/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

/**
 */
class Rsrv_Details extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <div>
                <h1>Rsrv_Details ...</h1>
            </div>
            )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Rsrv_Details

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Rsrv_Details )


