/**
 *  @copyright   Elmelo Ltd
 */

/**
 */
export const Key = ( key, gs ) =>
{
    const biz_id = gs().__biz.biz_id /* ? gs().__core.bizInfo.biz_id : gs().__core.bizInfo.biz_id */

    return [biz_id, key].join(':')
}   // KeyStore


