/**
 *	@copyright	Elmelo Ltd
 */

import {Utils, Time, AStorage} from '../../api/Utils';
import {ItemPrice} from "./itemPrice";

/**
 */
class ONT
{
	/**
	 */
	static Paidify( order )
	{
		if( /*!order.paym_arr ||*/ !order.order_amount_info )
		{
			return order;
		}
		
		//
		const total_disc = order.disc_arr
			?	order.disc_arr.reduce( (acc, cur) => {
				try{
					const amount = parseFloat( cur.amount );
					
					if( 'static' === cur.type )
						return acc + amount;
					else if( 'percent' === cur.type )
						return acc + (amount*order.order_amount_info.SubTotal) / 100.0;
					else
						return acc;
				}
				catch( err ){
					return acc;
				}
			}, 0 )
			: 	0;
			
		order.order_amount_info.discount = total_disc;
		
		const total_paid = order.paym_arr
			?	order.paym_arr.reduce( (acc, cur) => {
				return acc + (cur.amount ? cur.amount : 0.0)
			}, 0 )
			: 	0;
		
		order.order_amount_info.paid = total_paid;
		
		if( !total_paid )
		{
			order.paid_info = 'not_paid';
		}
		else if( (total_paid - total_disc) != order.order_amount_info.Total )
		{
			order.paid_info = 'partial';
		}
		else
		{
			order.paid_info = 'paid';
		}
		
		return order;
	}	// Order_Paidify
	
	/*static OnCnt = (item, sect, cnt_change ,cart_obj , cart_sects   ) =>
	{
		try{
			if( !cart_obj[sect.cat_id] )
			{
				cart_obj[sect.cat_id] = {
					cat_id: sect.cat_id
					,	cat_idx: sect.cat_idx
					,	cat: sect.cat
					,	data: []
					,	data_obj: {}
				};

				// add to array
				cart_sects.push( cart_obj[sect.cat_id] );

				// sort
				cart_sects.sort( (a, b) => (a.cat_idx-b.cat_idx) );
			}

			if( !cart_obj[sect.cat_id].data_obj[item._id] )
			{
				cart_obj[sect.cat_id].data_obj[item._id] = item;

				cart_obj[sect.cat_id].data.push( item );
			}

			// check for zeros in cart items
			if( !cnt_change  )
			{
				// const idx_item = cart_obj[sect.cat_id].data.find( x => x._id === item._id );

				const idx_item = cart_obj[sect.cat_id].data.findIndex(function (_item) {
					// // console.log(item);
					return item._id === _item._id;
				});

				// // console.log("adsada ", idx_item);
				// // console.log("adsada ", cart_obj[sect.cat_id].data);
				cart_obj[sect.cat_id].data.splice( idx_item,1);

				// // console.log("cart_sects 1",cart_sects);

				cart_sects = cart_sects.filter( x => x.data.length );

				delete cart_obj[sect.cat_id].data_obj[item._id];

				// if( !cart_obj[sect.cat_id].data.length )
				// if( Object.entries(cart_obj[sect.cat_id].data_obj).length === 0 )
				// {
				//     // console.log("adsada 1");
				//
				//     delete cart_obj[sect.cat_id];
				// }
			}
			if(item._type === "mod_ex"){

				if(item._opts) {
					Object.keys(item._opts).map((key, idx) => {
						const opt_obj = item._opts[key];
						// // console.log("opt_obj opt_obj", opt_obj);

						if (!opt_obj._cnt) {

							delete item._opts[key];
						}

					});
				}
			}
			if(item._type === "setmeal"){

				if(item._setm) {
					Object.keys(item._setm).map((key, idx) => {
						const opt_obj = item._setm[key];
						// // console.log("opt_obj opt_obj", opt_obj);

						if (!opt_obj._cnt)
						{
							delete item._setm[key];
						}
					});
				}
			}

			// sort out items in cart
			if( cart_obj[sect.cat_id] )
			{
				cart_obj[sect.cat_id].data.sort( (a, b) => {
					if(a.Cat_Idx < b.Cat_Idx)
						return -1;
					else if( a.Cat_Idx > b.Cat_Idx )
						return 1;
					else if( a.Sub_Cat_Idx < b.Sub_Cat_Idx )
						return -1;
					else if( a.Sub_Cat_Idx > b.Sub_Cat_Idx )
						return 1;
					else if( a._v_idx < b._v_idx )
						return -1;
					else if( a._v_idx > b._v_idx )
						return 1;
					else
						return 0;
				} );
			}

			return { cart_obj, cart_sects }
		}
		catch( err ){
			console.warn( 'ONT: CartSave: err: ', err );

			return null;
		}
	}	// CartSave*/
	
	/**
	 */
	static CartSave = ( cart_sects ) =>
	{
		try{
			// deep copy of last cart
			const cart_last = Utils.CopyDeep( cart_sects );
			
			// // console.log( 'ONT: CartSave: cart_last: ', cart_last );
			
			//
			return JSON.stringify( cart_last );
		}
		catch( err ){
			console.warn( 'ONT: CartSave: err: ', err );
			
			return null;
		}
	}	// CartSave
	
	/**
	 */
	static CartDiff = ( cart_sects, cart_sects_last ) =>
	{
		try{
			// // console.log( 'EPOS_Cart: OnSave: cart_sects', cart_sects );
			
			let cart_diff_ = [];
			
			// get diff
			cart_sects.reduce( (cart_diff, cart_sect) => {
				// // console.log( 'EPOS_Cart: OnSave: cart_sect', cart_sect );
				// // console.log( 'EPOS_Cart: OnSave: cart_diff', cart_diff );
				
				const sect_idx = cart_sects_last.findIndex( x => x.cat_id === cart_sect.cat_id );
				
				// // console.log( 'EPOS_Cart: OnSave: sect_idx', sect_idx );
				if( sect_idx >= 0 )
				{
					const sect_last = cart_sects_last[sect_idx];
					
					// // console.log( 'EPOS_Cart: OnSave: sect_last', sect_last );
					
					let items_diff = [];
					
					cart_sect.data.reduce( (items_diff_, cart_item)=>{
						// // console.log( 'EPOS_Cart: OnSave: cart_item', cart_item );
						
						const item_idx = sect_last.data.findIndex( x => x._id === cart_item._id );
						
						// // console.log( 'EPOS_Cart: OnSave: item_idx', item_idx );
						
						if( item_idx >= 0 )
						{
							/// @todo find and add the differences ...
							let item_last = sect_last.data[item_idx];
							
							// // console.log( 'EPOS_Cart: OnSave: item_last', item_last );
							
							if( item_last._cnt != cart_item._cnt )
							{
								// // console.log("inside cnt");
								try{
									item_last = JSON.parse(JSON.stringify(item_last));
									
									item_last._cnt = cart_item._cnt - item_last._cnt;
									
									/// @todo for mods ...
									
									/// @todo for mods_ex ...
									
									items_diff_.push( item_last );
								}
								catch( err ){
									console.error( err );
								}
							}   // if ...
						}
						else
						{
							const diff_sect_idx = cart_diff.findIndex( x => x.cat_id === cart_sect.cat_id );
							
							// // console.log( 'EPOS_Cart: OnSave: diff_sect_idx', diff_sect_idx );
							
							const diff_sect = cart_diff[diff_sect_idx];
							
							// // console.log( 'EPOS_Cart: OnSave: diff_sect', diff_sect );
							
							try{
								const cart_item_tmp = JSON.parse(JSON.stringify(cart_item));
								
								items_diff_.push( cart_item_tmp );
							}
							catch( err ){
								console.error( err );
							}
						}
						
						return items_diff_;
					}, items_diff );
					
					try{
						items_diff = JSON.parse( JSON.stringify(items_diff) );
					}
					catch( err ){
						//
					}
					
					// // console.log( 'EPOS_Cart: OnSave: items_diff', items_diff );
					
					if( items_diff.length )
					{
						const diff_sect_idx = cart_diff.findIndex( x => x.cat_id === sect_last.cat_id );
						
						if( diff_sect_idx >= 0 )
						{
							const diff_sect = cart_diff[diff_sect_idx];
							
							// // console.log( 'EPOS_Cart: OnSave: diff_sect', diff_sect );
							
							diff_sect.data = [...diff_sect.data, ...items_diff];
						}
						else
						{
							try{
								let temp_sect = JSON.parse( JSON.stringify(sect_last) );
								
								// // console.log( 'EPOS_Cart: OnSave: temp_sect', temp_sect );
								
								temp_sect.data = items_diff;
								
								cart_diff.push( temp_sect );
							}
							catch( err ){
								console.error( err );
							}
						}
					}   // if item_diff.length
				}
				else
				{
					// // console.log( 'EPOS_Cart: OnSave: cart_sect: push', cart_sect );
					
					try{
						cart_diff.push( JSON.parse(JSON.stringify(cart_sect)) );
					}
					catch( err ){
						console.error( err );
					}
				}
				
				return cart_diff;
			}, cart_diff_ );
			
			//
			return cart_diff_;
		}
		catch( err ){
			console.warn( 'ONT: OnSave: err: ', err );
			
			return [];
			// return Promise.reject( err );
		}
	}	// CartDiff
	
	/**
	 *	trans_obj = { type: 'cash'/'card'/'hs'
	 				, amount: 0.0
	 				, ts: epoch_time
	 				, trans_tid: '', trans_bid: ''
	 				}
	 */
	static Trans_Pay( props, trans_obj )
	{
		return new Promise( async (res, rej) => {
			try
			{
				const params = {
					_topic_name: "pos_pay"
					,	beta_user_id: props._bid
					,	transid : props._oid
					,	trans_obj: trans_obj
					,	stage: props.biz_info.stage
				};
				
				// // console.log( 'ONT: Trans_Pay: params: ', params );
				
				const data_lambda = await props._lambda.Invoke( params );
				
				// // console.log( 'ONT: Trans_Pay: data_lambda: ', data_lambda );
				
				if( data_lambda.err )
				{
					return res( { msg: 'ERR', errorMessage: data_lambda.err } );
				}
				
				return res({ msg: 'OK' });
			}
			catch( err )
			{
				console.warn( 'ONT: Trans_Pay: err: ', err );
				
				return rej( err );
			}
		} );
	}	// static Trans_Pay
	
	/**
	 *	disc_obj = { type: 'cash'/'card'/'hs'
	 				, amount: 0.0
	 				, ts: epoch_time
	 				, trans_tid: '', trans_bid: ''
	 				}
	 */
	static Trans_Discount( props, disc_obj )
	{
		return new Promise( async (res, rej) => {
			try
			{
				const params = {
					_topic_name: "pos_disc"
					,	beta_user_id: props._bid
					,	transid : props._oid
					,	disc_obj: disc_obj
					,	stage: props.biz_info.stage
				};
				
				// // console.log( 'ONT: Trans_Discount: params: ', params );
				
				const data_lambda = await props._lambda.Invoke( params );
				
				// // console.log( 'ONT: Trans_Discount: data_lambda: ', data_lambda );
				
				if( data_lambda.err )
				{
					return res( { msg: 'ERR', errorMessage: data_lambda.err } );
				}
				
				return res({ msg: 'OK' });
			}
			catch( err )
			{
				console.warn( 'ONT: Trans_Discount: err: ', err );
				
				return rej( err );
			}
		} );
	}	// static Trans_Discount
	
	/**
	 */
	static Order_Invoice( state, props, save_obj, dt_str, dt_now_epoch )
	{
		const total_order = ONT.GetTotal( state.cart_sects );
		
		const invoice = {
			beta_user_id: props._bid,
			transid: props._oid,
			paid_info: "not_paid",				/// @todo Merge with payment ...
			invoice_data: save_obj.last,
			date_time : dt_str,
			order_amount_info: {
				Total: total_order.total,
				SubTotal: total_order.total,
				vat: 0							/// @todo Not used for now...
			},
			dt :{
				origin   : dt_now_epoch/*Date.now()*/,
				accepted : dt_now_epoch
			},
			
			// comment: order.comment,
			order_from: 'pos',
			payment_list: state.paymentListArray,
			delivery_option: state.info.order.type,
			user_info: state.info.cust,
			delivery_duration: state.info.duration,
			
			//
			table_no: "dinein" === state.info.order.type ? state.info.order.tbl_no : 'na',
			
			//
			trans_uid: props.ouid ? props.ouid : AStorage.MD5( [props._bid, props._oid].join( ':' ) ),
			
			//
			stage: props.biz_info.stage,
			
			alpha: {
				cuisine_name: props.biz_info.cusine_name,
				biz_name: props.biz_info.biz_name,
				service_name: props.biz_info.service_name,
			}
		};
		
		return invoice;
	}
	
	/**
	 */
	// static Order_Upd( props, cart_sects/*order*/, dt_now_epoch )
	// {
	// 	return new Promise( async (res, rej) => {
	// 		// console.log( 'ONT: Order_Upd: props: ', props );
	// 		// console.log( 'ONT: Order_Upd: cart_sects: ', cart_sects );
	// 		// console.log( 'ONT: Order_Upd: dt_now_epoch: ', dt_now_epoch );
	
	// 		const dt_str = Date();
	
	// 		try{
	// 			const order_total = ONT.GetTotal( props );
	
	// 			// console.log( 'ONT: Order_Upd: order_total: ', order_total );
	
	// 			let params = {
	// 	                _topic_name: "pos",
	
	// 	                dt_pos: dt_now_epoch,
	
	// 	                invoice : invoice,
	// 	                invoice_history: save_obj.diff/*invoice_history*/,
	
	// 	                transid : invoice.transid,
	// 	                beta_user_id: beta_user_id,
	// 	                // beta_user_id : "eu-west-1:c3316708-c5af-4531-b283-b457b80f48d7",
	
	// 	                stage: biz_info.stage
	// 	            };
	
	// 	        //
	// 	        if( !this.props.edit_order )
	// 	        {
	// 	            params = { ...params
	// 	                ,   biz_id : this.props.biz_info.biz_name
	
	// 	                ,   order_from: 'pos'
	
	// 	                ,   respond : 'accepted'/*"pos"*/
	// 	                ,   respond_dt: dt_now_epoch
	// 	                ,   dt_accepted : dt_now_epoch
	// 	                ,   dt_accepted_duration : this.state.info.duration
	
	// 	                ,   dt_new : dt_str
	
	// 	                ,   b_edit: 'false'
	// 	            };
	// 	        }
	// 	        else
	// 	        {
	// 	            params.b_edit = 'true';
	// 	        }
	
	// 	        // console.log( 'ONT: Order_Upd: params: ', params );
	
	// 	        //
	// 	        const data_lambda = await this.props._lambda.Invoke( params );
	
	// 	        // console.log( 'ONT: Order_Upd: data_lambda: ', data_lambda );
	
	// 	        //
	// 	        return res( data_lambda );
	// 		}
	// 		catch( err ){
	// 			console.warn( 'ONT: Order_Upd: err: ', err );
	
	// 			return rej( err );
	// 		}
	// 	} );
	// }	// Order_Upd
	
	/**
	 */
	static Order_Init( state, props, save_obj/*, dt_now_epoch, paid_amount*/ )
	{
		return new Promise( async (res, rej) => {
			try{
				const dt_str = Date();
				const dt_now_epoch = Time.Epoch( dt_str );
				
				const invoice = ONT.Order_Invoice( state, props, save_obj, dt_str, dt_now_epoch );
				
				const params = {
					_topic_name: "pos",
					
					dt_pos: dt_now_epoch,
					
					invoice : invoice,
					invoice_history: save_obj.diff/*invoice_history*/,
					
					transid : props._oid,
					beta_user_id: props._bid,
					// beta_user_id : "eu-west-1:c3316708-c5af-4531-b283-b457b80f48d7",
					
					stage: props.biz_info.stage

					,   biz_id : props.biz_info.biz_name

					,   order_from: 'pos'

					,   respond : 'accepted'/*"pos"*/
					,   respond_dt: dt_now_epoch
					,   dt_accepted : dt_now_epoch
					,   dt_accepted_duration : state.info.duration

					,   dt_new : dt_str

					,   b_edit: 'false'
				};

				// // console.log( 'ONT: Order_Init: params: ', params );

				//
				const data_lambda = await props._lambda.Invoke( params );

				// // console.log( 'ONT: Order_Init: data_lambda: ', data_lambda );
				
				//
				// return res( data_lambda );
				return res( {msg: 'OK', invoice: invoice} );
			}
			catch( err ){
				console.warn( 'ONT: Order_Init: err: ', err );
				
				return rej( err );
			}
		} );
	}	// Order_Init
	
	/**
	 */
	// static Order_Save = async () =>
	// {
	// 	//
	// }	// Order_Save
	
	//
	static GetTotal_ModEx( item , opt_obj )
	{
		let tot_opt = ItemPrice(item);

		// // console.log( '************* opt_obj: ', opt_obj );

		// let tot_opt = opt_obj.selMod.length ? 0 : ItemPrice(item , order_type);

		// // console.log( '************* tot_opt 1: ', tot_opt );

		tot_opt = opt_obj.selMod.reduce( (acc, cur) => {
			return acc + ItemPrice(cur.obj);
		}, tot_opt );

		// // console.log( '************* tot_opt 2: ', tot_opt );

		tot_opt = opt_obj.selSecMods.reduce( (acc, cur) => {
			return acc + ItemPrice(cur.obj);
		}, tot_opt );

		// // console.log( '************* tot_opt 3: ', tot_opt );

		tot_opt = opt_obj.selAddons.reduce( (acc, cur) => {
			return acc + ItemPrice(cur.obj);
		}, tot_opt );

		return Number(opt_obj._cnt * tot_opt );
	}
	
	/**
	 */
	static GetTotal( cart_sects )
	{
		let _total = 0 ;
		let itemCount = 0;


		cart_sects.map( _sec => {
			_sec.data.map( item => {
				itemCount += item._cnt ;

				// // console.log( '************* item: ', item );

				if(item._type === "basic")
				{
					_total += Number(item._cnt * ItemPrice(item));
				}
				// if(item._type === "custom_item")
				// {
				// 	_total += parseFloat(item._cnt * item.price );
				// }

				if(item._type === "mod")
				{
					_total += item._mods.opts.reduce( (acc, cur) => {
						// // console.log( '************* item: ', cur );
						// const mod_obj = item._mods.Options[];
						// acc += ItemPrice(item)

						return acc + Number(cur._cnt * (ItemPrice(item) + ItemPrice(cur) ) );
					}, 0 );
				}

				if( item._type === "mod_ex" )
				{
					_total += Object.keys(item._opts).reduce( (acc_opt, cur_key) => {
						const opt_obj = item._opts[cur_key];

						return acc_opt + ONT.GetTotal_ModEx(item, opt_obj );
					}, 0 );
				}	// if mod_ex

				if( item._type === "setmeal" && item._setm )
				{
					--itemCount;

					const tot_setm = Object.keys(item._setm).reduce( (acc, cur_key) => {
						const opt_obj = item._setm[cur_key];

						acc.total += Number(opt_obj._cnt *  ItemPrice(item));
						acc.n_items += opt_obj._cnt;

						return acc;
					}, {total: 0, n_items: 0} );

					_total += tot_setm.total;
					itemCount += tot_setm.n_items;
				}	// if ...
			});
		});

		// // console.log("itemCount", itemCount);

		return {total: _total, item_cnt: itemCount};
	}   // function GetTotal
}	// class ONT

/**
 */
export default ONT;
