/**
 *	@copyright	Elmelo Ltd
 */

///
const elml_biz = {
    rat_id: 'rat_indian_currykingplymouth',
    biz_id: 'rat_indian_currykingplymouth',
    service_name: 'rat',
    cuisine_name: 'indian',
    biz_name: 'currykingplymouth',

    title: 'Curry King',
    subTitle: '',
    desc: [
        'Curry King is the place to be if you are looking to experience the very best Indian cuisine. Curry King has established itself as a bustling and vibrant place.'
    ],
    homePageMsg: [
        "ORDER ONLINE AND ENJOY 15% DISCOUNT."
    ],
    showAllergenMsg: "",

    fsOrg: 'FT1CR',
}

///
export default elml_biz