/**
 *	@copyright	Elmelo Ltd.
 */

// import elml_cfg from '../../_config/elml_cfg'
import {DDB,} from '../../api/AWS'
import {AStorage, Utils} from '../../api/Utils'
import {Colour} from '../../api/Utils_UI'
import Utils_Menu from '../../api/Utils_Menu';
import {Rdx_Order_AddToCart,Rdx_Old_Order} from './order'

// import * as order from './order'
// import {Rdx_Biz_Init} from './biz'

/**
 */
export const Rdx_Core_AuthStatus = ( auth_status ) =>
{
    return {
            type: 'core:auth_status'
        ,   payload: auth_status
        }
}   // Rdx_Core_AuthStatus

export const Rdx_BetaId = beta_id =>
{
    return {
        type: 'core:beta_id',
        payload: beta_id,
    }
}
/**
 */
export const Rdx_Core_bNetwork = ( b_network ) =>
{
    return {
            type: 'core:b_network'
        ,   payload: b_network
        }
}   // Rdx_Core_bNetwork

/**
 */
export const Rdx_Core_bInternet = ( b_internet ) =>
{
    return {
            type: 'core:b_internet'
        ,   payload: b_internet
        }
}   // Rdx_Core_bInternet

/**
 */
const Rdx_Core_Cfg = ( cfg ) =>
{
    return {
            type: 'core:cfg'
        ,   payload: cfg
        }
}

/**
 */
const Rdx_Core_Menu = ( menu_obj ) =>
{
    return {
            type: 'core:menu'
        ,   payload: menu_obj
        }
}

/**
 */
const Rdx_Core_InitStatus = ( init_status ) =>
{
    return {
            type: 'core:init-status'
        ,   payload: init_status
        }
}
/**
 */
export const Rdx_PubId = pub_id =>
{
    return {
        type: 'core:pub_id',
        payload: pub_id,
    }
}// /**
//  */
// const Rdx_Core_AlphaId = ( alpha_id ) =>
// {
//     return {
//             type: 'core:alpha_id'
//         ,   payload: alpha_id
//         }
// }

//
export const Rdx_Core_Init = () =>
{
    return async (dispatch, gs) =>
    {
        try
        {
            // // console.log( 'reducers/core: Rdx_Core_Init: gs(): ', gs() )

            if( 'inprogress' === gs().__core.initStatus )
                return {msg: 'OK'}

            dispatch( Rdx_Core_InitStatus('inprogress') )

            // // console.log( 'reducers/core: Rdx_Core_Init: inprogress' )


            const order_cart = await AStorage.Get( 'cart' )

            // // console.log( 'reducers/core: Rdx_Core_Init: cart_sects: ', cart_sects )

            const menu_obj_stored = localStorage.getItem( Core_StoreId(gs, 'menu') )
            // const menu_obj_stored = null

            // // console.log( 'reducers/core: Rdx_Core_Init: menu_obj_stored: ', menu_obj_stored )

            if( menu_obj_stored )
            {
                const fullMenu = JSON.parse( menu_obj_stored )

                const fullMenu_prep = Utils_Menu.Prep({...fullMenu}, gs().__biz.rat_id);

                // // console.log( 'reducers/core: Rdx_Core_Init: fullMenu_prep: ', fullMenu_prep )

                //
                const menu_sects = /*await */Core_PrepMenu(fullMenu, fullMenu_prep, 0/*data_menu.last_upd_menu*/);

                // // console.log( 'reducers/core: Rdx_Core_Init: menu_sects: ', menu_sects )

                const menu_obj_new = {menu: menu_sects, menu_prep: fullMenu_prep, menu_full: fullMenu}

                // // console.log( 'reducers/core: Rdx_Core_Init: gs(): ', gs() )

                // if(cart_sects) {
                //     cart_sects.forEach(sect => {
                //         sect.data.forEach(item => {
                //             // gs().__core.menu_prep.items[item._id].
                //             // // console.log( 'reducers/core: Rdx_Core_Init: item: ', item )
                //
                //             // const obj = gs().__core.menu.menu_prep.items[item._id]
                //
                //             fullMenu_prep.items[item._id]._cnt = item._cnt
                //
                //             if (item._mods)
                //                 fullMenu_prep.items[item._id]._mods = item._mods
                //
                //             if (item._modex)
                //                 fullMenu_prep.items[item._id]._modex = item._modex
                //
                //             if (item._opts)
                //                 fullMenu_prep.items[item._id]._opts = item._opts
                //
                //             // // console.log( 'reducers/core: Rdx_Core_Init: obj: ', obj )
                //             // gs().
                //             dispatch(order.Rdx_Order_AddToCart(item, sect, item._cnt))
                //         })
                //     })
                // }

                // // console.log( 'reducers/core: Rdx_Core_Init: menu_obj_new: ', menu_obj_new )

                dispatch( Rdx_Core_Menu( menu_obj_new ) )
            }   // if menu_obj_stored

            // return {msg: 'OK'}

            //
            const aws_ddb = new DDB( {} )

            let arr_resp = [];

            let p_query = {
                    TableName: 'test' === gs().__cfg.stage ? 'test_elmelo_menu_1' : 'elmelo_menu_1',
                    // IndexName: 'rat_id-index',
                    KeyConditionExpression: '#a = :a',
                    // ProjectionExpression: '',
                    ExpressionAttributeNames: {'#a': 'biz_id'},
                    ExpressionAttributeValues: {':a': gs().__biz.rat_id},
                }

            while( true )
            {
                // // console.log( 'reducers/core: Rdx_Core_Init: p_query: ', p_query )

                const resp_query = await aws_ddb.Query( p_query )

                // // console.log( 'reducers/core: Rdx_Core_Init: resp_query: ', resp_query );

                arr_resp = [...arr_resp, ...resp_query.Items]

                if( !resp_query.LastEvaluatedKey )
                    break

                p_query.ExclusiveStartKey = resp_query.LastEvaluatedKey
            }   // while true

            // // console.log( 'reducers/core: Rdx_Core_Init: arr_resp: ', arr_resp )

            const fullMenu = arr_resp.reduce( (a, c) => {
                    const key_ = 'item' === c._t ? 'items'
                        :   'cat' === c._t ? 'cats'
                        :   'subcat' === c._t ? 'subcats'
                        :   'mod' === c._t ? 'mods'
                        :   'addon' === c._t ? 'addons'
                        :   'tag' === c._t ? 'tags'
                        :   'allergen' === c._t ? 'allergens'
                        :   'ingredient' === c._t ? 'ingredients'
                        :   'na'

                    if( !a[key_] )
                    {
                        // a[key_] = []
                        return a;
                    }

                    a[key_] = [...a[key_], c]

                    return a;
                }, {format_st: 'v1', biz_id: gs().__biz.rat_id, items: [], cats: [], subcats: [], mods: [], addons: [], tags: [], allergens: [], ingredients: {}} );

            // Object.keys( menu_obj ).forEach( x => x.filter( (a, b) => (a-b) ) )

            // // console.log( 'reducers/core: Rdx_Core_Init: menu_obj: ', fullMenu )

            //
            const fullMenu_prep = Utils_Menu.Prep({...fullMenu}, gs().__biz.rat_id);

            // // console.log( 'reducers/core: Rdx_Core_Init: fullMenu_prep: ', fullMenu_prep )

            //
            const menu_sects = /*await */Core_PrepMenu(fullMenu, fullMenu_prep, 0/*data_menu.last_upd_menu*/);

            // // console.log( 'reducers/core: Rdx_Core_Init: menu_sects: ', menu_sects )

            const menu_obj_new = {menu: menu_sects, menu_prep: fullMenu_prep, fullMenu: fullMenu}

            // console.log( 'reducers/core: Rdx_Core_Init: menu_obj_new: ', menu_obj_new )

            // // console.log( 'reducers/core: Rdx_Core_Init: gs(): ', gs() )

            //
            // gs().__order.forEach( sect => {
            gs().__order.cart_sects.forEach( sect => {
                    sect.data.forEach( item => {
                            // gs().__core.menu_prep.items[item._id].
                            // // console.log( 'reducers/core: Rdx_Core_Init: item: ', item );

                            // const obj = gs().__core.menu.menu_prep.items[item._id]

                            fullMenu_prep.items[item._id]._cnt = item._cnt
                            
                            if( item._mods )
                                fullMenu_prep.items[item._id]._mods = item._mods

                            if( item._modex )
                                fullMenu_prep.items[item._id]._modex = item._modex

                            if( item._opts )
                                fullMenu_prep.items[item._id]._opts = item._opts

                            // // console.log( 'reducers/core: Rdx_Core_Init: obj: ', obj )
                            // gs().
                            // dispatch( order.Rdx_Order_AddToCart( item, sect, item._cnt ) )
                        } )
                } )

            let cart_obj = {} , cart_sects = [] ,  counter = 0 ;

            if(order_cart)
            {

                order_cart.forEach( sect => {

                    cart_obj[sect.cat_id] = {
                          cat_id: sect.cat_id
                        , cat_idx: sect.cat_idx
                        , cat: sect.cat
                        , data: []
                        , data_obj: {}
                    };
                    sect.data.forEach( item => {
                        // gs().__core.menu_prep.items[item._id].
                        // // console.log( 'actions/_utils: Rdx_Core_Init: item: ', item );

                        // const obj = gs().__core.menu.menu_prep.items[item._id]

                        fullMenu_prep.items[item._id]._cnt = item._cnt

                        if( item._mods )
                            fullMenu_prep.items[item._id]._mods = item._mods

                        if( item._modex )
                            fullMenu_prep.items[item._id]._modex = item._modex

                        if( item._opts )
                            fullMenu_prep.items[item._id]._opts = item._opts

                        if( item._setm )
                            fullMenu_prep.items[item._id]._setm = item._setm

                        cart_obj[sect.cat_id].data_obj[item._id] = fullMenu_prep.items[item._id];
                        cart_obj[sect.cat_id].data.push(cart_obj[sect.cat_id].data_obj[item._id])

                        dispatch(Rdx_Order_AddToCart(item, sect, item._cnt))
                        // // console.log( 'actions/_utils: Rdx_Core_Init: obj: ', obj )
                        // gs().
                        // dispatch( order.Rdx_Order_AddToCart( item, sect, item._cnt ) )
                    } )

                    cart_sects.push(cart_obj[sect.cat_id]);

                })

                dispatch( Rdx_Old_Order( {
                    cart_obj: cart_obj ,
                    cart_sects: cart_sects
                } ) )

            }

            //
            // localStorage.setItem( Core_StoreId(gs, 'menu'), JSON.stringify(menu_obj_new) )
            localStorage.setItem( Core_StoreId(gs, 'menu'), JSON.stringify(fullMenu) )

            //
            dispatch( Rdx_Core_Menu( menu_obj_new ) )
            dispatch( Rdx_Core_InitStatus('stale') )

            //
            return { msg: 'OK' }
        }
        catch( err )
        {
            dispatch( Rdx_Core_InitStatus('stale') )

            console.warn( 'reducers/core: Rdx_Core_Init: err: ', err )

            return { err }
        }
    }   // return ...
}   // Rdx_Core_Init

// const export Rdx_Core_ClearMenu()
// {
//     //
// }

/**
 */
const Core_StoreId = (gs, id) =>
{
    return [gs().__biz.rat_id, id].join( ':' )
}

/**
 */
const Core_PrepMenu = (menu, menu_prep) =>
{
    let menu_obj = [], tmp_menu_obj = {};

    //
    menu.items.forEach(item => {
        try
        {
            // console.log(" Core_PrepMenu : item : ", item);

            const sec_id = [item.cat.id, item.subcat.id].join(':');

            if (!tmp_menu_obj[sec_id])
            {
                // console.log( 'sec_id: ', item );
                const col_ = Colour.FromStr([item.cat.id, item.subcat.id].join(':'));

                // const cat_obj = menu.

                tmp_menu_obj[sec_id] = {
                    data: []
                    , title: [item.cat.name, item.subcat.name].join(':')
                    , cat: item.cat.name
                    , sub_cat: item.subcat.name
                    , cat_id: item.cat.id
                    , sub_cat_id: item.subcat.id
                    , desc: menu_prep.subcats[item.subcat.id].desc
                    , cat_idx: menu_prep.cats[item.cat.id].idx
                    , sub_cat_idx: menu_prep.subcats[item.subcat.id].idx
                    , col: col_
                    , col_inv: Colour.Invert(col_)
                    // , tag: Core_CreateTag(item.subcat)
                    };

                // // console.log( sec_id );
            }

            //
            tmp_menu_obj[sec_id].data.push(item);
        }
        catch( err )
        {
            console.warn( 'reducers/core: Core_PrepMenu: err: ', err )
        }
    });	// menu.items

    // console.log( 'PrepMenu: tmp_menu_obj', tmp_menu_obj );

    //
    Object.keys(tmp_menu_obj).forEach(x => {
        menu_obj.push(tmp_menu_obj[x]);
    });

    // // console.log( 'PrepMenu: menu_obj 1', menu_obj );

    // sort menu_obj
    menu_obj.sort((a, b) => {
        if (a.cat_idx < b.cat_idx)
            return -1;
        else if (a.cat_idx > b.cat_idx)
            return 1;
        else if (a.sub_cat_idx < b.sub_cat_idx)
            return -1;
        else if (a.sub_cat_idx > b.sub_cat_idx)
            return 1;
        else
            return 0;
    });

    // sort data in menu obj
    menu_obj = menu_obj.map(sect => {
        // // console.log( 'sect: ', sect );

        sect.data.sort((a, b) => (a.idx - b.idx));

        // // console.log( 'sect.data: ', sect.data );

        sect.data = sect.data.map((x, idx) => {
            x._v_idx = idx;
            x._cnt = 0;

            // // console.log('x.Name: ', x, ', x.Mods', x.Mods ? 'true' : 'false', ', x.Mods.length: ');

            //
            if (x.setm_items)
                x._type = 'setmeal';
            else if (x.mod.name)
                x._type = 'mod';
            else
                x._type = 'basic';

            /// @todo check for mod_ex
            if ( x.secmods.length || x.addons.length )
            {
                x._type = 'mod_ex'
            }
            // if (x.Addons && x.Addons.length)
            // {
            //     x._type = 'mod_ex'
            // }

            /// @todo check for setmeal

            // modifiers
            if (x._type === 'mod')
            {
                // // console.log( '+++++++++++++ mod_ex: x: ', x );

                if (menu_prep.mods)
                {
                    x._mods = menu_prep.mods[x.mod.id] ?
                        JSON.parse(JSON.stringify(menu_prep.mods[x.mod.id]))
                        : null
                    ;

                    // if( !x._mods )
                    //     return mod;

                    // Making deep copies and initiating values ...
                    if (x._mods)
                    {
                        x._mods = {...x._mods};
                        x._mods.opts = [...x._mods.opts];
                        x._mods.opts = x._mods.opts.map( opt => {
                            opt._cnt = 0;
                            return opt;
                        });
                    }   // if x._mods
                }   // if menu_prep.mods
            }   // if x._type === mod

            // secondary modifiers and addons
            if (x._type === 'mod_ex')
            {
                if (x.mod)
                {
                    x._mods = menu_prep.mods[x.mod.id] ?
                        JSON.parse(JSON.stringify(menu_prep.mods[x.mod.id]))
                        : null
                    ;

                    // if( !x._mods )
                    //     return mod;

                    // Making deep copies and initiating values ...
                    if (x._mods)
                    {
                        x._mods = {...x._mods};
                        x._mods.opts = [...x._mods.opts];
                        x._mods.opts = x._mods.opts.map( opt => {
                            opt._cnt = 0;
                            return opt;
                        });
                    }   // if x._mods
                }   // if menu_prep.mods

                if (/*menu_prep.mods &&*/ x.secmods && x.secmods.length)
                {
                    x._secmods = x.secmods.reduce((acc, sec_id) => {
                        if (!menu_prep.mods[sec_id.val])
                            return acc;

                        const sec_mod = {...menu_prep.mods[sec_id.val]};

                        // deep copy ...
                        sec_mod.opts = [...sec_mod.opts];
                        sec_mod.opts = sec_mod.opts.map( opt => {
                            opt._cnt = 0;
                            return opt;
                        });

                        // return acc.push( sec_mod );
                        return [...acc, sec_mod];
                    }, []);
                }   // if menu_prep.sec_mods

                if (x.addons && x.addons.length)
                {
                    x._addons = x.addons.reduce((acc, addon) => {
                        // addon = Utils.MD5([menu_prep.biz_id, addon, '0'].join(':'));

                        if (!menu_prep.addons[addon.val])
                            return acc;

                        const addon_obj = {...menu_prep.addons[addon.val]};
                        addon_obj.opts = [...addon_obj.opts];
                        addon_obj.opts = addon_obj.opts.map( opt => {
                            opt._cnt = 0;
                            return opt;
                        });

                        // return acc.push( addon_obj );
                        return [...acc, addon_obj];
                    }, []);
                }   // if menu_prep.addons
                //Tags And Allergens

                if ( x.tags && x.tags.length)
                {
                    x._tags = x.tags.reduce((acc, allergen) => {

                        // addon = Utils.MD5([menu_prep.biz_id, ;addon, '0'].join(':'))

                        if (!menu_prep.tags[allergen.val])
                            return acc;

                        const tags_obj = {...menu_prep.tags[allergen.val]};
                        //tags_obj.img_url =  require({tags_obj.img_url});
                        //// console.log("all:",allergen_obj)

                        // return acc.push( addon_obj );
                        return [...acc, tags_obj];
                    }, []);
                }

            }   // x._type === 'mod_ex'
            //
            return x;
        });

        // console.log( 'sect: ', sect );
        return sect;
    });

    menu_obj = menu_obj.map((x, idx) => {
        x.sect_idx = idx;

        return x;
    });

    return menu_obj;
}	// Core_PrepMenu

/**
 */
const Core_CreateTag = (name) =>
{
    const word_arr = name.split(' ');
    let _tag;

    if (1 === word_arr.length) {
        if (1 < word_arr[0].length)
            _tag = word_arr[0].substring(0, 2);
        else if (1 === word_arr[0].length)
            _tag = word_arr[0].substring(0, 1);
        else
            _tag = '';
    }
    else if (1 < word_arr.length) {
        _tag = word_arr[0].substring(0, 1) + word_arr[1].substring(0, 1);
    }
    else {
        _tag = '';
    }

    return _tag;
    // item._tag = Colour.FromStr( item._id );
}	// Core_CreateTag

/**
 */
function ParseStringArray(arr) {
    if (!arr)
        return arr;

    return arr.map(x => {
        x = Utils.ParseString(x);
        return x;
    });
}



