/**
 *  @copyright   Elmelo Ltd
 */

/**
 */
export const Rdx_Checkout_Confirm = ( type ) =>
{
	return {
		type: 'checkout:confirm'
		,   payload: type
	};
}

/**NEW */
export const Rdx_Checkout_SetSubtotal = data => {
	return {
		type: "checkout:set-subtotal",
		payload: data
	}
};

export const Rdx_Checkout_SetGenericDiscount = payload =>
{
	return{
		type: "checkout:set-generic-discount",
		payload: payload
	}
}

/**
 */
export const Rdx_Checkout_SetSpecialDiscount = payload => {
	return {
		type: "checkout:set-special-discount",
		payload: payload
	}
};
/**
 */
export const Rdx_Checkout_SetLoyalty = payload =>
{
	return {
		type: "checkout:set-loyalty",
		payload: payload
	}
};
/**
 */
export const Rdx_Checkout_SetCouponDiscount = payload => {
	return {
		type: "checkout:set-coupon-discount",
		payload: payload
	}
};

/**
 */
export const Rdx_Checkout_SetItemCount = payload => {
	return {
		type: "checkout:set-item-count",
		payload: payload
	}
};
/**
 */
export const Rdx_Checkout_info = payload => {
	return {
		type: "checkout:update-user-info",
		payload: payload
	}
};
/**
 */
export const Rdx_Checkout_delivery = payload => {
	return {
		type: "checkout:update-delivery-info",
		payload: payload
	}
};
/**
 */
export const setDeliveryCharge = payload => {
	return {
		type: "checkout:set-delivery-charge",
		payload: payload
	}
};
/**
 */
export const Rdx_Checkout_Payment = payload => {
	return {
		type: "checkout:set-payment",
		payload: payload
	}
};
/**
 */
export const Rdx_Checkout_Clear = payload => {
	return {
		type: "checkout:clear-info",
		payload: payload
	}
};
