/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Container,
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import NavHdr from '../_common/nav_hdr'

/**
 */
class Menu_Home extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <div style={{paddingTop: 0}}>
                <NavHdr />
                
                <Container>
                {
                    this.props.__core.menu && this.props.__core.menu.menu
                ?   this.props.__core.menu.menu.map( ( sect, sect_idx ) => (
                        <div key={[sect.cat_id, sect.sub_cat_id].join(':')}>
                            <h3>{sect.title}</h3>
                            {
                                sect.data.map( (item, item_idx) => (
                                    <div key={item._id}>
                                        <a>{item.Name}</a>
                                    </div>
                                ) )
                            }
                        </div>
                    ) )
                :   null
                }
                </Container>
            </div>
            )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Menu_Home

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Menu_Home )


