/**
 *	@copyright	Elmelo Ltd
 */

import {combineReducers} from 'redux'

import Cfg from './cfg'
import Biz from './biz'
import Core from './core'
import Rsrv from './rsrv'
import Order from './order'
import checkout from "./checkout";
import Chat from './Chat'


export default combineReducers({
        __cfg: Cfg,
        __checkout: checkout,
        __biz: Biz,
        __core: Core,
        __rsrv: Rsrv,
        __order: Order,
        __chat: Chat,

})

