/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'
import {
    Container,
    Button,
    Form, Alert, Spinner,
} from 'react-bootstrap'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPlusSquare,
    faTrashAlt,
    faCheck,
    faCircle,
    faAlignJustify,
    faTimes,
    faTruckMoving,
    faShoppingBag,
    faMoneyBill,
    faArrowDown,
    faCreditCard,
    faPlus,
    faMinus,
    faTicketAlt
} from '@fortawesome/free-solid-svg-icons'

import {navigate} from "@reach/router";

// import elml_cfg from '../../_config/elml_cfg'
import '../../css/_common.css'
import {Core, DDB, Lambda, Utils,Common} from "../../api";

// import NavHdr from '../_common/nav_hdr'
import color from '../_common/colors.json'
import ONT from '../order/ont'

import Coupon_Code from '../wallet/coupon'
import Loyalty from './Loyalty'

import {Invoice_Save,Invoice_Process} from './checkout_invoice'
import Checkout_Confirmation from './confirmation'
import {Init, Msg_Send} from '../../api/chat/chat_engine'
import cuid from 'cuid'

/**
 */
class Checkout_Summary extends React.PureComponent
{
    constructor(props)
    {
        super(props)

        this.state={
                coupon: false,
                s_loyalty: false,
                ip_address: '0.0.0.0',
                geolocation_coord: {lat: 1, lan: 1},
                errMsg: "",
                buttonActive: true,

                // orderId: '',
                // transId: '',
                bModal_Confirmation: false,

                comment: ""
            }

        this._order = null
        this._charge = null

    }   // cstr

    /**
     */
    render()
    {
        //  console.log( "Checkout_Summary : render : state : ", this.state );
        //  console.log( "Checkout_Summary : render : props : ", this.props );

        const {__checkout,__order} = this.props

        return(
            <div >
                <div className="processTitle">
                    <div className="processIcon">
                        <FontAwesomeIcon icon={faAlignJustify} size="lg" color={color.colors.light}/>
                    </div>
                    <div className="summeryText" style={{color:"#009432"}}>
                        Summary
                    </div>
                </div>

                <div className="summeryInfo">

                    {
                        this.state.errMsg
                    ?   <Alert variant="danger" onClose={() => this.setState({errMsg: ""})} dismissible>
                            {this.state.errMsg}
                        </Alert>
                    :   null
                    }


                    <div>

                        {
                            __checkout.ListCharges().map(ch => !ch.amount ? null : (
                                <div className="summeryDiv" key={ch._idx}>
                                    <div className="summeryItem" style={{color: color.colors.light}}>{ch.name ? ch.name : ch.type}</div>
                                    <div className="summeryValue" style={{color: color.colors.black}}>£{ch.amount.toFixed(2)}</div>
                                </div>
                            ))
                        }


                        {
                            __checkout.ListDiscounts().map((disc, i) => !disc.amount ? null : (
                                <div className="summeryDiv"  key={i}>
                                    <div className="summeryItem" style={{color: color.colors.light}}>Discount ({disc.name ? disc.name : disc.type})</div>
                                    <div className="summeryValue" style={{color: color.colors.black}}>(-) £{parseFloat(disc.amount).toFixed(2)}</div>
                                </div>
                            ))
                        }

                        <div className="summeryDiv" style={{borderTop: "1px solid", borderColor: color.colors.light}}>
                            <div className="summeryItem" style={{color: color.colors.light,fontSize:22}}>Total</div>
                            <div className="summeryValue"
                                 style={{color: color.colors.black, fontSize:22, fontWeight: 700}}>£{parseFloat(__checkout.Total()).toFixed(2)}
                            </div>
                        </div>
                    </div>

                    <div>
                        {__checkout.delivery.deliveryType ?
                            <div className="processDetailsDiv">
                                <div className="processDetailText">
                                    Type
                                </div>
                                <div className="processDetailText" style={{color:"#009432",fontWeight:700}}>
                                    {__checkout.delivery.deliveryType}
                                </div>
                                <div onClick={() => this.props.showDelivery(true, true)} className="processButtonText">
                                    Change
                                </div>
                            </div> :
                            null
                        }

                        {
                            __checkout.delivery.deliveryType.toLowerCase() === "home"
                        &&  __checkout.delivery.deliveryAddress
                        ?   <div className="summeyInfoText">
                                {__checkout.delivery.deliveryAddress.line1} {__checkout.delivery.deliveryAddress.line2},
                                {__checkout.delivery.deliveryAddress.city}, {__checkout.delivery.deliveryAddress.postal_code}
                            </div>
                        :   null
                        }

                    </div>

                    {__checkout.payment.paymentType ?
                        <div>
                            <div className="processDetailsDiv">
                                <div className="processDetailText">
                                    Payment Info
                                </div>
                                <div className="processDetailText" style={{color:"#009432",fontWeight:700}}>
                                    {__checkout.payment.paymentType}
                                </div>
                                <div onClick={() => this.props.showPayment(true, true)} className="processButtonText">
                                    Change
                                </div>
                            </div>
                            <div className="processDetailsDiv">
                                {
                                    __checkout.payment.creditInfo
                                        ?
                                        <div className="processDetailsDiv">
                                            <div className="processDetailText">
                                                Card Info
                                            </div>
                                            <div style={{color:"#009432",fontWeight:700,marginLeft:16}}>
                                                {"ends with " + __checkout.payment.creditInfo.card.last4}
                                            </div>
                                        </div>
                                        : null
                                }

                            </div>
                        </div> :
                        null
                    }

                    {
                        this.state.bModal_Confirmation
                    ?   <Checkout_Confirmation
                            pi={this._charge.charge}
                            OnConfirm={this.OnConfirm}
                            OnClose={ () => {
                                    this.setState( {bModal_Confirmation: false} )
                                    // proceed with payment ...
                                } }
                            ShowError={ (errMsg) => {
                                this.setState({errMsg: errMsg, buttonActive: true});
                                alert( errMsg )
                            } }
                        />

                    :   null
                    }

                    {
                    !this.state.buttonActive
                        ? <div style={{
                        backgroundColor: "#BDBDBD",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        padding: 12,
                        color: color.colors.primary,
                    }}
                    >
                        Processing
                        <span style={{marginLeft: 8}}>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            variant="primary"
                                        />
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            variant="secondary"
                                        />
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            variant="success"
                                        />
                                    </span>
                    </div>

                        :

                        !this.props.confirmPayment ?

                            <div>
                                <Alert  variant= 'danger'>
                                    Provide
                                    <Alert.Link href="#"> Customer, Delivery and Payment</Alert.Link> details to Order
                                </Alert>
                                <Button disabled className="orderButtonDiv"
                                        style={{cursor: "no-drop", background: "#b7b7b7"}}>
                                    <div>Place your Order</div>
                                </Button>
                            </div>
                            :
                            <div>
                                <Form.Group >
                                    <div className="extraDivText">Any Special Comment</div>
                                    <Form.Control style={{resize: "none"}} as="textarea" rows={3} value={this.state.comment} onChange={ e => this.setState({comment: e.target.value}) }/>
                                </Form.Group>
                                <Button onClick={() => this.UI_PlaceOrder()} className="orderButtonDiv">
                                    <div>Place your Order</div>
                                </Button>
                            </div>
                }

                {

                    this.props.confirmPayment ?
                    <div className="otherOffers">
                        <div className="offerDecs">
                            Check If You Have Special Discount Or Add Coupon Code
                        </div>
                        <div className="offerDecsButton">
                            {/*<div onClick={()=>this.showLoyalty(true)}>Special Discount</div>*/}
                            <div onClick={()=>this.showCoupon(true)}>Coupon Code</div>
                        </div>
                    </div>
                    :
                    null
                }

                    {
                        this.state.coupon
                    ?   <Coupon_Code showCoupon={this.showCoupon} applyCoupon={this.applyCoupon}/>
                    :   null
                    }



                    {
                        this.state.s_loyalty
                    ?   <Loyalty showLoyalty={this.showLoyalty} />
                    :   null
                    }

                </div>
            </div>
            )
    }   // render

    /**
     */
    componentDidMount()
    {
        // // console.log("this.props.__chat.chat_channel", this.props.__chat.chat_channel)

        if(!this.props.__chat.chat_channel)
        {
            this.chatInit()
                .then(data => {
                })
                .catch(err => {
                })
        }
    }

    /**
     */
    chatInit = async () =>
    {
        try
        {

            const aws_core = new Core( {} )
            const cfg = this.props.__cfg

            // // console.log("this.props.__chat.user_id", this.props.__chat.user_id)

            if(!this.props.__chat.user_id)
                await this.props.Rdx_Pubid_Init()

            const aws_lambda = new Lambda()

            const {title , biz_id } = this.props.__biz

            let ch_id = [   biz_id , this.props.__chat.user_id ].join( '<>' )

            const name_obj = await aws_core.Name()
            const name_str = [name_obj.first, name_obj.last].join( ' ' )

            const ch_t =  'ind'

            const p_new = {
                stage: cfg.stage,
                pf: cfg.platform,
                usr: cfg.usr_t ? cfg.usr_t : 'beta',

                actType: 'channels',
                act: 'new:chnl',

                topic: 'chat',
                ch_id: ch_id,
                ch_t: ch_t,
                // ch_grp_t: ch_grp_t,

                //
                b_db: cfg.chat.bDb,
                b_iot: cfg.chat.bIoT,
                b_push: cfg.chat.bPush,
            }

            if(ch_t === 'ind')
            {
                p_new.meta = {
                    [this.props.__chat.user_id] : {	name: name_str },
                    [biz_id] : {	name: title ? title : 'N/A' },
                }

                p_new.usr_list = [
                    {
                        ch_id: ch_id,
                        usr_id: this.props.__chat.user_id,
                        usr_t: 'beta',
                        mship_t: 'admin'
                    },
                    {
                        ch_id: ch_id,
                        usr_id: biz_id,
                        usr_t: 'alpha' ,
                        mship_t: 'admin'
                    },
                ]
            }
            else
            {
                p_new.meta = {}
                p_new.usr_list = []
            }

            console.log( "Chat_Inst: Init: p_get: ", p_new )

            const resp_get = await aws_lambda.Invoke( p_new, cfg.lambda('chat', cfg.stage) )

            console.log( "Chat_Inst: Init: resp_get: ", resp_get )

            // localStorage.setItem( 'channel:'+cfg.stage+user.attributes.phone_number , JSON.stringify(resp_get.resp) )

            this.props.Rdx_Chat_Channel(resp_get.resp)

            return {msg: "OK"}
        }
        catch( err )
        {
            this.setState( {bInit: true, bRefreshing: false} )

            console.warn( 'Checkout_Summary: send_chat_msg: err: ', err )

            // return Promise.reject( err )
            return {err}
        }
    }   // send_chat_msg

    /**
     */
    OnConfirm = async ( charge ) =>
    {
        try
        {
            // // console.log( 'Checkout_Summary: OnConfirm: charge: ', charge )

            this._charge.charge = charge

            if( 'succeeded' === charge.status )
            {
                await this.FinaliseOrder()
            }
            else
            {
                // Alert.alert(
                //     'Card Payment failed.',
                //     'Your card payment failed. Please try again with another card.',
                //     [
                //         { text: 'OK', onPress: () => {
                //             this.setState({buttonActive: true})
                //         } },
                //     ],
                //     {cancelable: false}
                //     )
                this.setState({buttonActive: true})
                navigate( 'Checkout_Payment' )
            }

            return {}
        }
        catch( err )
        {
            return {err}
        }
    }   // OnConfirm

    // /**
    //  */
    // componentDidMount()
    // {
    // }

    /**
     */
    applyCoupon = () =>
    {
        //
    }

    /**
     */
    showCoupon = (val) =>
    {
        this.setState({coupon: (val), s_loyalty:false});
    }

    /**
     */
    showLoyalty = (val) =>
    {
        this.setState({s_loyalty: (val),coupon:false});
    }

    /**
     */
    UI_PlaceOrder = async () =>
    {
        try
        {
            console.log( "this.props.__checkout : ", this.props.__checkout );

            // console.log("this.props.__order.cart_sects.length",this.props.__order.cart_sects.length)


            if(!this.props.__order.cart_sects.length)
            {
                return this.setState({errMsg: "Please select an item." });
            }

            if(!this.props.__checkout.delivery.deliveryType)
            {
                return this.setState({errMsg: "Please select delivery type." });
            }

            if(this.props.__checkout.delivery.deliveryType.toLowerCase() === "home" && !this.props.__checkout.delivery.deliveryAddress )
            {
                return this.setState({errMsg: "Please select a delivery address." });
            }

            if(!this.props.__checkout.payment.paymentType )
            {
                return this.setState({errMsg: "Please select payment type." });
            }

            if(this.props.__checkout.payment.paymentType === "card" && !this.props.__checkout.payment.creditInfo )
            {
                return this.setState({errMsg: "Please select a card." });
            }


            this.setState({ buttonActive: false })

            this._order = await this.Beta_Order()



            const pay_data =  await this.Beta_Charge();

            console.log( 'Checkout_Summary: UI_PlaceOrder: pay_data: ', pay_data )


            if( pay_data && pay_data.charge && pay_data.charge.status && 'requires_confirmation' === pay_data.charge.status )//@todo
            {
                this._charge = pay_data
                this.setState( {bModal_Confirmation: true} )
            }
            else
            {
                //
            }

            if( 'succeeded' === pay_data.charge.status || 'successful' === pay_data.charge.status)
            {
                await this.FinaliseOrder()
            }
            else if( 'requires_confirmation' === pay_data.charge.status )
            {
                console.log( 'Checkout_Summary: UI_PlaceOrder: requires confirmation' )
            }
            else if( 'failed' === pay_data.charge.status )
            {
                alert(
                    'Card Payment failed.',
                    'Your card payment failed. Please try again with another card.',
                    [
                        { text: 'OK', onPress: () => {
                                this.setState( {buttonActive: true} )
                            } },
                    ],
                    {cancelable: false}
                )
            }

            // this.setState({ buttonActive: true })

            return {}

        }
        catch( err )
        {
            this.setState({ buttonActive: true })

            console.error( 'Checkout_Summary: UI_PlaceOrder: err: ', err )
            return {err}
        }
    }   // UI_PlaceOrder

    /**
     */
    Beta_Order = async () =>
    {
        try
        {

            const rdx_biz = this.props.__biz;
            const cfg = this.props.__cfg;
            //
            const invoice_obj = await Invoice_Save( this.props)

            const order_items = invoice_obj.invoice_data.map( cat => {
                return { cat_id: cat.cat_id, cat_idx: cat.cat_idx, cat: cat.cat, data: cat.data }
            })

            // // console.log( 'Checkout_Summary: Beta_Order: invoice_obj: ', invoice_obj )

            const p_order = {
                usr: 'beta',
                actType: 'order',
                act: 'create',
                order_from: 'web',
                alpha: {
                    biz_id: rdx_biz.biz_id,
                    biz_title: rdx_biz.biz_title ? rdx_biz.biz_title : rdx_biz.biz_name
                },

                user_info: invoice_obj.user_info,
                delivery_option: invoice_obj.delivery_option,
                items: order_items,
                items_history: [],
                invoice: {
                    ...invoice_obj.order_amount_info,
                    save_status: invoice_obj.save_status,
                },
                // payment_list: [],
                paid_info: 'not_paid',
                comments: [this.state.comment],    // {msg: '', by: '', pub_id, dt_create: 0}

                stage: cfg.stage,
            }


            // console.log( 'Checkout_Summary: Beta_Order: p_order: ', p_order )

            const aws_lambda = new Lambda({})

            const resp_lambda =  await aws_lambda.Invoke(
                    p_order,
                    cfg.lambda( 'beta', cfg.stage )
                )

            // console.log( 'Checkout_Summary: Beta_Order: resp_lambda: ', resp_lambda )

            if( resp_lambda.err )
            {
                //
                throw new Error( resp_lambda.err )

            }

            this._orderId = resp_lambda.order_id;

            return {...p_order,
                order_id: resp_lambda.order_id,
                order_uid: resp_lambda.order_uid,
                dt_create: resp_lambda.dt_create,
            };
        }
        catch( err )
        {
            console.error( 'Checkout_Summary: Beta_Order: err: ', err )

            return Promise.reject( err );
        }
    }   // Beta_Order

    /**
     */
    Beta_Charge = async ( /*p*/ ) =>
    {
        try
        {
            // console.log( "Checkout_Summary : Beta_Charge : props : ", this.props );

            const { __checkout } = this.props
            const rdx_biz = this.props.__biz
            const cfg = this.props.__cfg

            const aws_lambda = new Lambda({})

            if( !this._orderId )
            {
                throw new Error( 'New order was not created.' )
            }

            const order_obj = this._order

            const dt_now = Date.now()
            const totalAmount = Math.round(__checkout.Total() * 100)

            let payment_type = __checkout.payment.paymentType

            let pay_param = {
                amount: totalAmount,
                dt_now: dt_now,
                payment_type: payment_type
            }

            if( payment_type === 'card' || payment_type === 'credit card' )
            {
                pay_param.card = __checkout.payment.creditInfo
            }

            pay_param.pay = {
                loyalty: {
                    schemes: __checkout.discounts.loyalty
                },
            }

            let params_charge = {
                stage: cfg.stage,
                usr: "beta",
                actType: "pay",
                act: "charge_2s_shared",

                dt_origin: Date.now(),
                order_id: this._orderId,

                alpha: {
                    biz_id: rdx_biz.biz_id ? rdx_biz.biz_id : rdx_biz.rat_id,
                    biz_title: this.props.__biz.title
                },

                trans: {
                    amount: pay_param.amount,
                    currency: rdx_biz.biz_currency ? rdx_biz.biz_currency : 'GBP',
                    desc: this.props.__biz.title
                },

                payment_type: pay_param.payment_type,
                invoice: order_obj.invoice,
                ext: {
                    'ip': this.state.ip_address,
                    'location': this.state.geolocation_coord,
                    'device': 'mob'
                },

                pay: pay_param.pay,
            };

            if( pay_param.card )
            {
                params_charge.card = pay_param.card;
            }

            // console.log( 'Checkout_Summary: Beta_Charge: params_charge: ', params_charge )


            const resp_lambda = await aws_lambda.Invoke( params_charge, cfg.lambda( 'beta', cfg.stage ) )

            // console.log( 'Checkout_Summary: Beta_Charge: resp_lambda: ', resp_lambda )

            if (resp_lambda.errorMessage)
            {
                // console.warn(JSON.parse(pay_data.errorMessage))

                throw new Error( resp_lambda.errorMessage )
            }

            //
            return resp_lambda//.resp
        }
        catch( err )
        {
            console.error( 'Checkout_Summary: Beta_Charge: err: ', err )

            return Promise.reject( err )
        }
    }   // Beta_Charge

    /**
     */
    FinaliseOrder = async () =>
    {
        try
        {

            /**trans Update */

            if(this._charge)
            {
                await this.Beta_Order_Complete()
            }

            // console.log("this.props.__chat.chat_channel", this.props.__chat.chat_channel)

            if(!this.props.__chat.chat_channel)
                await this.chatInit()

            /**Order Status Update */

            await this.Order_Status_Update()

            /** Coupon Useage Update*/
            if (this.props.__checkout.discounts.coupon.length > 0) {
                await this.CouponUsage();
            }
            /**Send Email */

            const SES_Email =  await this.SES_Email()

            // if (SES_Email.errorMessage /*|| SES_Email.message */ )
            // {
            //     throw new Error( SES_Email.errorMessage )
            // }

            /**Send Chat */

            const send_chat =  await this.send_chat_msg();

            console.log("send_chat" , send_chat)

            if( send_chat )
            {
                //
            }

            this.props.Rdx_Order_ClearCart()
            this.props.Rdx_Checkout_SetSubtotal(0)
            this.props.Rdx_Checkout_SetItemCount(0)
            this.props.Rdx_Checkout_Clear()
            this.props.Rdx_Core_Init()


            const aws_core = new Core( {} );

            navigate('success', {
                    state: {
                        beta_user_id: await aws_core.Id(),
                        order_id: this._orderId
                    }
            });


            return {msg: 'OK'}
        }
        catch( err )
        {
            this.setState({ buttonActive: true })

            console.warn( 'Checkout_Summary: FinaliseOrder: err: ', err )

            return Promise.reject( err )
        }
    }   // FinaliseOrder
    /**
     */


    CouponUsage = async ()=>
    {
        try
        {
            const { __checkout,__cfg ,__biz} = this.props;
            const  coupon_object = __checkout.discounts.coupon[0].coupon;
            const aws_lambda = new Lambda({});

            const params_usage = {
                stage: __cfg.stage,
                usr: "beta",
                actType: "promo",
                act: "coupon:usage",
                alpha: {
                    biz_id: __biz.biz_id ? __biz.biz_id : __biz.rat_id
                },
                cpn_code : coupon_object.cpn_code,
            };


            // console.log( 'Checkout_Summary: CouponUsage: params_usage: ', params_usage );

            const resp_lambda = await aws_lambda.Invoke( params_usage, __cfg.lambda('beta', __cfg.stage) );

            // console.log( 'Checkout_Summary: CouponUsage: resp_lambda: ', resp_lambda );
            return {msg:"OK"};
        }
        catch( err )
        {
            console.warn( "Checkout_Summary : CouponUsage : err : ", err );

            return{ err };
        }
    };

    Beta_Order_Complete = async () =>
    {
        try
        {
            // const rdx_biz = this.props.__core.bizInfo;
            const cfg = this.props.__cfg;

            const aws_lambda = new Lambda({})

            const p_charge = {
                stage: cfg.stage,
                usr: 'beta',
                actType: 'pay',
                act: 'card:charge:upd',
                trans_id: this._charge.success.trans_id,
                charge: this._charge.charge,
            }

            // console.log( 'Beta_Order_Complete: p_charge: ', p_charge )

            const resp_charge = await aws_lambda.Invoke(
                    p_charge,
                    cfg.lambda( 'beta', cfg.stage )
                )

            // console.log( 'Beta_Order_Complete: resp_charge: ', resp_charge )

            if( resp_charge.errorMessage )
            {
                throw new Error( resp_charge.errorMessage )
            }

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'Checkout_Summary: Beta_Order_Complete: err: ', err )

            return Promise.reject( err )
        }
    }   // Beta_Order_Complete
    /**
     */
    Order_Status_Update = async () =>
    {
        try
        {

            const cfg = this.props.__cfg;

            const aws_core = new Core( {} );

            const aws_lambda = new Lambda({})

            // console.log("this._order", this._order)


            let p_order = {
                usr: 'beta',
                actType: 'order',
                act: 'order:update',
                stage: cfg.stage,
                key : {
                    beta_user_id: await aws_core.Id(),
                    order_id: this._order.order_id,
                },
                data: {
                    paid_info : this.props.__checkout.payment.paymentType.toLowerCase() === 'cash' ? 'not-paid' : 'paid',
                    respond   : 'new',
                    ch_id:  this.props.__chat.chat_channel.ch_id,
                    usr_id: this.props.__chat.user_id
                }
            }
            if(this.props.__checkout.payment.paymentType.toLowerCase() === "card")
                p_order.data.paid_by = [{paid_by: 'card' , amount: this._order.invoice.Total , cardno: this.props.__checkout.payment.creditInfo.card.last4 }]
            if(this.props.__checkout.payment.paymentType.toLowerCase() === "cash")
                p_order.data.paid_by = [{paid_by: 'cash' , amount: this._order.invoice.Total }]

            // console.log( 'Order_Status_Update: params ', p_order )

            const resp_order = await aws_lambda.Invoke(
                p_order,
                cfg.lambda( 'beta', cfg.stage ),
            )

            // console.log( 'Order_Status_Update: resp ', resp_order )

            if( resp_order.errorMessage )
            {
                throw new Error( resp_order.errorMessage )
            }
            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'Checkout_Summary: Beta_Order_Complete: err: ', err )

            return Promise.reject( err )
        }
    }   // Beta_Order_Complete

    /**
     */
    SES_Email = async () =>
    {
        try
        {
            const rdx_biz = this.props.__biz

            const {__checkout} = this.props
            const cfg = this.props.__cfg

            const order_obj = this._order

            const dt_now = Date.now();

            let params = {
                usr: "beta",
                actType: "notify",
                act: "order",
                dt: dt_now,
                trans_id: order_obj.order_id,
                email_from: rdx_biz.contact.email.def,  // @todo check if contact email exist first
                order_from: 'web',
                email_to: [__checkout.info.email],
                msg_sub: "You've placed an order at " + rdx_biz.title + ".",
                alpha: {
                    biz_id: rdx_biz.biz_id ? rdx_biz.biz_id : rdx_biz.rat_id,
                    biz_title: rdx_biz.title
                },
                msg_body: await Invoice_Process(order_obj, this.props),
                stage: cfg.stage,
            };

            params.v_iot_order = cfg.elmelo_v_iot_order;

            const aws_lambda = new Lambda({})

            const resp_lambda = await aws_lambda.Invoke( params, cfg.lambda( 'beta', cfg.stage ) );

            if( resp_lambda.errorMessage )
            {
                throw new Error( resp_lambda.errorMessage )
            }

            return resp_lambda
        }
        catch (err)
        {
            console.error( 'Checkout_Summary: SES_Email: err: ', err )
            return Promise.reject( err )
        }
    }   // SES_Email

    /**
     */
    send_chat_msg = async () =>
    {
        try
        {
            const cfg = this.props.__cfg

            // const ch_channel = JSON.parse( localStorage.getItem( 'channel:'+ cfg ) )
            //
            // let pubId = localStorage.getItem( 'pubId:'+cfg.stage )

            if(!this.props.__chat.chat_channel)
                return

            const order_data = this._order

            const aws_core = new Core( {} )

            const name_obj = await aws_core.Name()

            if(!order_data.beta_user_id)
                order_data.beta_user_id = await aws_core.Id()

            const p_msg = {
                msg: 'Place a New order from '+ this.props.__biz.title,
                ch_id: this.props.__chat.chat_channel.ch_id,
                meta:  {
                    order_uid: order_data.order_uid,
                    order_id: order_data.order_id,
                    Total: order_data.invoice.Total,
                    dt_create: order_data.dt_create,
                    biz_id: order_data.alpha.biz_id,
                    status: 'Order Placed'
                } ,
                type: 'order',
                pub_id_list: Object.keys(this.props.__chat.chat_channel.meta),

                sender: [name_obj.title, name_obj.first, name_obj.last].join( ' ' ),
                usr_id: this.props.__chat.user_id,
                dev_id: cuid(),
                l_id: Date.now(),
            }

            console.log( 'Checkout_Summary: send_chat_msg: Chat_Engine: p_msg: ', p_msg )

            const msg_obj = await Msg_Send( p_msg, cfg)

            console.log( 'Checkout_Summary: send_chat_msg: Chat_Engine: msg_obj: ', msg_obj )


            return {msg: 'OK'}
        }
        catch( err )
        {
            this.setState( {bInit: true, bRefreshing: false} )

            console.warn( 'Checkout_Summary: send_chat_msg: err: ', err )

            // return Promise.reject( err )
            return {err}
        }
    }   // send_chat_msg


}   // class Checkout_Summary

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Checkout_Summary )


