import React from 'react'
import {
    Image,
    Button,
    Row,
    Col,
    Form,
    Modal, Alert
} from 'react-bootstrap'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import color from '../_common/colors.json'
import '../../css/_common.css'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {Lambda, Utils} from "../../api"
// import elml_cfg from "../../_config/elml_cfg"
import Geocode from "react-geocode"
import {TextInput} from '../_common/components'

class AddNew_Address extends React.PureComponent {
    /**
     */
    constructor(props) {

        super(props)

        this.state ={

            line1: '',
            line2: '',
            city:  '',
            county: '',
            postcode: '',
            errMsg: ''
        }
    }
    /**
     */
    render() {
        // console.log('add new address props: ', this.props)
        return (

            <div className="overlayModal">
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={true}
                    onHide={() => this.props.hideModal()}
                    animation={true}
                >
                    <div style={{padding: 32}}>
                        {
                            this.state.errMsg ?
                                <Alert variant="danger" onClose={() => this.setState({errMsg: ""})} dismissible>
                                    {this.state.errMsg}
                                </Alert> : null
                        }
                        <div>
                            <h4 style={{color: color.colors.primary, flex: 1}}>Add New Address</h4>
                            <div
                                onClick={() => this.props.hideModal(false)}
                                className="overlayClose">
                                <FontAwesomeIcon
                                    style={{marginLeft: 4, marginBottom: 4}}
                                    icon={faTimes} size="lg" color="#ef5350"/>
                            </div>
                        </div>
                        <div>
                            <Form.Group>
                                <TextInput
                                    placeholder="Line 1*"
                                    type="text"
                                    size="lg"
                                    val={this.state.line1}
                                    onChange={(val) => this.setState({line1: val.target.value})}
                                    className=""
                                />
                            </Form.Group>
                            <Form.Group>
                                <TextInput
                                    placeholder="Line 2"
                                    type="text"
                                    size="lg"
                                    val={this.state.line2}
                                    onChange={(val) => this.setState({line2: val.target.value})}
                                    className=""
                                />
                            </Form.Group>
                            <Form.Group>
                                <TextInput
                                    placeholder="County*"
                                    type="text"
                                    size="lg"
                                    val={this.state.county}
                                    onChange={(val) => this.setState({county: val.target.value})}
                                    className=""
                                />
                            </Form.Group>
                            <Form.Group>
                                <TextInput
                                    placeholder="City*"
                                    type="text"
                                    size="lg"
                                    val={this.state.city}
                                    onChange={(val) => this.setState({city: val.target.value})}
                                    className=""
                                />
                            </Form.Group>
                            <Form.Group>
                                <TextInput
                                    placeholder="Postcode*"
                                    type="text"
                                    size="lg"
                                    val={this.state.postcode}
                                    onChange={(val) => this.setState({postcode: val.target.value})}
                                    className=""
                                />
                            </Form.Group>
                            <Form.Group>
                                <Button onClick={() => this.newAddressAdd()} variant="success">
                                    Add Address
                                </Button>
                            </Form.Group>
                        </div>
                    </div>
                </Modal>
            </div>

        )
    }

    isValidPostcode = p => 
    {
        var postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i;
        return postcodeRegEx.test(p);
    }

    /**
     */
    newAddressAdd = async () =>
    {
        try {

            const aws_lambda = new Lambda({})

            const {line1, line2, city, county, postcode} = this.state;
            const {__cfg} = this.props;

            if( !line1 || !city || !county || !postcode )
            {
                // throw new Error( 'Required Fields can not be empty' )
                alert( "Fill up all required fields." );
                return;
            }

            let _postcode = "";

            if (this.isValidPostcode(postcode)) 
            {
                var postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i;
                _postcode = postcode.replace(postcodeRegEx,"$1 $2");
            } 
            else 
            {
                alert( "Enter a valid postcode." );
                return;
            }

            const addressString = line1 + " " + line2 + ", " + city + ", " + county + ", " + _postcode;

            Geocode.setApiKey("AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M")
            Geocode.enableDebug()

            const addr_longlat = await Geocode.fromAddress( addressString )
            const { lat, lng } = addr_longlat.results[0].geometry.location

            const address = {
                line1: line1,
                line2: line2.length ? line2 : ' ',
                city: city,
                county: county.length ? county: ' ',
                postal_code: _postcode.toUpperCase(),
                longlat: {
                    latitude: lat,
                    longitude: lng
                }
            };

            console.log( "AddNew_Address : newAddressAdd : address : ", address );

            const params = {
                    actType: 'profile'
                ,	act: 'address:add'
                ,	stage: __cfg.stage
                ,	usr: 'beta'
                ,	address: address
                ,	b_shared: true
            }

            const data =  await aws_lambda.Invoke( params, __cfg.lambda( 'beta', __cfg.stage ) );

            console.log( "AddNew_Address : newAddressAdd : data : ", data );

            if (data.errorMessage) {

                this.setState({errMsg:data.errorMessage})
                return;
            }
            if (data === 'Already exists.') 
            {
                this.setState({errMsg:"Already Exists!"})
                return;
            }

            if(this.props.loadUserAddress)
                await this.props.loadUserAddress();
            this.props.hideModal()

        }
        catch (err) {

            this.setState({errMsg:err.message})
            return Promise.reject(err)

        }

    };


}

export default AddNew_Address
