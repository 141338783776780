/**
 *	@copyright	Elmelo Ltd.
 */

export default ( state = {
    bizId: null,
    initStatus: 'stale',
    servMenu: null,

    cart_sects: [],

    custName: '',
    custAddr: '',
    custPhone: '',
    custEmail: '',
}, action ) =>
{
switch( action.type )
{
    case 'reserve:':
        return state

    case 'rsrv:biz_id':
        return {...state, bizId: action.payload}

    case 'rsrv:init-status':
        return {...state, initStatus: action.payload}

    case 'rsrv:menu':
        return {...state, servMenu: action.payload}

    case 'rsrv:cust':
        switch( action.payload.type )
        {
        case 'name':
            return {...state, custName: action.payload.data}
        case 'addr':
            return {...state, custAddr: action.payload.data}
        case 'phone':
            return {...state, custPhone: action.payload.data}
        case 'email':
            return {...state, custEmail: action.payload.data}
        default:
            return state;
        }

    default:
        return state;
}	// switch action.type
}

