/**
 *    @copyright    Elmelo Ltd
 */

import {Currency} from '../../api'
import ONT from '../order/ont'
import {ItemPrice} from "../order/itemPrice";

// import Social_Core from "../../api/social/core"

/**
 */
async function Invoice_Save( props )
{
    try
    {

        const {__checkout, __order} = props

        // console.log("saveInvoice: invoice data", __checkout);

        const cfg = props.__cfg

        // Calculate VAT
        const _vat = (__checkout.charges.subtotal.amount * 0.1667).toFixed(2)

        // console.log("saveInvoice: _vat", _vat);

        let invoice = {

            invoice_data: __order.cart_sects,
            order_amount_info: {
                Total: __checkout.Total(),//this.getTotal(),
                SubTotal: __checkout.charges.subtotal.amount.toFixed(2),
                vat: _vat,
                charge: __checkout.ListCharges().filter(x => ('subtotal' != x.type && x.amount)),
                discount: __checkout.ListDiscounts().filter(x => x.amount),
            },

            delivery_option: __checkout.delivery.deliveryType,
            user_info: {

                username: { title: __checkout.info.title , first: __checkout.info.firstName , last: __checkout.info.lastName },
                mobile: __checkout.info.phone,
                email: __checkout.info.email
            },
            // pub_id:  await Social_Core.PubId( cfg ),
            stage:   cfg.stage,
        }

        if (invoice.delivery_option.toLowerCase() === "delivery")
        {
            invoice.user_info.Address = __checkout.delivery.deliveryAddress
        }

        invoice.save_status = true

        return invoice
    }
    catch( err )
    {
        console.warn( 'checkout_invoice/Invoice_Save: err: ', err )
        return Promise.reject( err )
    }
}   // Invoice_Save

/**
 */
async function Invoice_Process( order_obj, props )
{
    try
    {
        const transid = order_obj.order_id.split('_')[3]
        const pr_bi = props.__biz
        const c_sign = Currency.Sign( pr_bi.biz_currency )

        const invoice_data = order_obj.items

        // console.log("invoice_data",invoice_data)

        let date = new Date();

        let month = date.getMonth();
        let day = date.getDate();
        let year = date.getFullYear();
        let dayname = date.getDay();

        let monthNames = [ "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December" ];

        let week=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';

        // // console.log("process_invoice", invoice);

        let html = "<body class ='body' bgcolor='#eeeeee'>" +
            "<table border='0' cellpadding='0' cellspacing='0' width='100%'>" +
            "    <tr>" +
            "        <td align='center'>" +
            "            <!--[if (gte mso 9)|(IE)]>" +
            "            <table align='center' border='0' cellspacing='0' cellpadding='0' width='600'>" +
            "                <tr>" +
            "                    <td align='center' valign='top' width='600'>" +
            "            <![endif]-->" +
            "            <table align='center' border='0' cellpadding='0' cellspacing='0' width='100%' class='main-table'  bgcolor='#044767;'>" +
            "                <tr>" +
            "                    <td align='center' valign='top' class='table-header' >" +
            "                        <!--[if (gte mso 9)|(IE)]>" +
            "                        <table align='center' border='0' cellspacing='0' cellpadding='0' width='600'>" +
            "                            <tr>" +
            "                                <td align='left' valign='top' width='300'>" +
            "                        <![endif]-->" +
            "                        <div class='first-section'>" +
            "                            <table align='left' border='0' cellpadding='0' cellspacing='0' width='100%' >" +
            "                                <tr>" +
            "                                    <td align='left' valign='top'  class='mobile-center'>" +
            "                                        <h1 class='system-name'>"+props.__biz.title+"</h1>" +
            "                                    </td>" +
            "                                </tr>" +
            "                            </table>" +
            "                        </div>" +
            "                        <!--[if (gte mso 9)|(IE)]>" +
            "                        </td>" +
            "                        <td align='right' width='300'>" +
            "                        <![endif]-->" +
            "                        <div class='first-section-left mobile-hide'>" +
            "                            <table align='left' border='0' cellpadding='0' cellspacing='0' width='100%'>" +
            "                                <tr>" +
            "                                    <td align='right' valign='top'>" +
            "                                        <table cellspacing='0' cellpadding='0' border='0' align='right'>" +
            "                                            <tr>" +
            "                                                <td class='first-section-left-details'>" +
            "                                                    <p class='date-time'><a class='date-time-link'> " + day+" "+monthNames[month]+","+year +"</a></p>" +
            "                                                    <p class='date-time'><a class='date-time-link'>" + hours+"."+minutes+ampm +"  " + week[dayname] + "</a></p>" +
            "                                                </td>" +
            "                                            </tr>" +
            "                                        </table>" +
            "                                    </td>" +
            "                                </tr>" +
            "                            </table>" +
            "                        </div>" +
            "                        <!--[if (gte mso 9)|(IE)]>" +
            "                        </td>" +
            "                        </tr>" +
            "                        </table>" +
            "                        <![endif]-->" +
            "                    </td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td align='center' height='100%' valign='top' width='100%' class='second-section' bgcolor='#ffffff'>" +
            "                        <!--[if (gte mso 9)|(IE)]>" +
            "                        <table align='center' border='0' cellspacing='0' cellpadding='0' width='600'>" +
            "                            <tr>" +
            "                                <td align='center' valign='top' width='600'>" +
            "                        <![endif]-->" +
            "                        <table align='center' border='0' cellpadding='0' cellspacing='0' width='100%' class='second-section-table'>" +
            "                            <tr>" +
            "                                <td align='center' valign='top' class='order-td'>" +
            "                                    <!--[if (gte mso 9)|(IE)]>" +
            "                                    <table align='center' border='0' cellspacing='0' cellpadding='0' width='600'>" +
            "                                        <tr>" +
            "                                            <td align='left' valign='top' width='300'>" +
            "                                    <![endif]-->" +
            "                                    <div class='second-section-text'>" +
            "                                        <table align='left' border='0' cellpadding='0' cellspacing='0' width='100%' class='table-width'>" +
            "                                            <tr>" +
            "                                                <td class='second-table-td' align='left' valign='top'>" +
            "                                                    <p class='title-heading'>User Information</p>" +
            "                                                    <p><span>"+ [order_obj.user_info.username.title , order_obj.user_info.username.first , order_obj.user_info.username.last ].join(' ')  +"</span> <br/><span>"+ props.__checkout.info.email +" </span> </p>" +
            "                                                </td>" +
            "                                            </tr>" +
            "                                        </table>" +
            "                                    </div>" +
            "                                    <!--[if (gte mso 9)|(IE)]>" +
            "                                    </td>" +
            "                                    <td align='left' valign='top' width='300'>" +
            "                                    <![endif]-->" +
            "                                    <div class='second-section-text'>" +
            "                                        <table align='right' border='0' cellpadding='0' cellspacing='0' width='100%' class='table-width'>" +
            "                                            <tr>" +
            "                                                <td class='second-table-td' align='right' valign='top'>" +
            "                                                    <p class='title-heading'>Delivery Type</p>" +
            "                                                    <p>"+ order_obj.delivery_option +"</p>" +
            "                                                </td>" +
            "                                            </tr>" +
            "                                        </table>" +
            "                                    </div>" +
            "                                    <!--[if (gte mso 9)|(IE)]>" +
            "                                    </td>" +
            "                                    </tr>" +
            "                                    </table>" +
            "                                    <![endif]-->" +
            "                                </td>" +
            "                            </tr>" +
            "                        </table>" +
            "                        <!--[if (gte mso 9)|(IE)]>" +
            "                        </td>" +
            "                        </tr>" +
            "                        </table>" +
            "                        <![endif]-->" +
            "                    </td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td  class='third-section' align='center' bgcolor='#ffffff'>" +
            "                        <!--[if (gte mso 9)|(IE)]>" +
            "                        <table align='center' border='0' cellspacing='0' cellpadding='0' width='600'>" +
            "                            <tr>" +
            "                                <td align='center' valign='top' width='600'>" +
            "                        <![endif]-->" +
            "                        <table align='center' border='0' cellpadding='0' cellspacing='0' width='100%' class='third-section-width'>" +
            "                            <tr>" +
            "                                <td align='center' clsss='third-section-text' >" +
            "                                    <h2 class='third-section-thank'>" +
            "                                        Thank You For Your Order!" +
            "                                    </h2>" +
            "                                </td>" +
            "                            </tr>" +
            "                            <tr>" +
            "                                <td align='left' clsss='data-table'>" +
            "                                    <table cellspacing='0' cellpadding='0' border='0' width='100%'>" +
            "                                        <tr>" +
            "                                            <td width='100%' colspan='3' align='left' bgcolor='#eeeeee' class='data-table-header'>" +
            "                                                " + transid +
            "                                            </td>" +
            "                                        </tr>";

        invoice_data.map( sect_data=>{
            sect_data.data.map( item=>{
                if(item._type === "setmeal")
                {
                    let setmeal_html = "";

                    Object.keys(item._setm).map( (setm_key, idx) => {
                        const opt_obj = item._setm[setm_key];
                        opt_obj.items.map((_item, index) => (

                            setmeal_html += "&emsp;" + _item.quantity + " × " + _item.name  + "<br>"
                        ))
                    })

                    html += "<tr>" +
                        "                                            <td width='50%' align='left' class='data-row'>" +
                        item.name +"<br>" + setmeal_html +
                        "                                            </td>" +
                        "                                            <td width='25%' align='left' class='data-row'>" +
                        item._cnt +
                        "                                            </td>" +
                        "                                            <td width='25%' align='right' class='data-row'>" +
                        c_sign+ (item._cnt * item.price.def).toFixed(2)  +
                        "                                            </td>";
                }

                if(item._type === "mod")
                {
                    item._mods.opts.map( (x, idx) => (
                        x._cnt
                            ?
                            html += "                                        <tr>" +
                                "                                            <td width='50%' align='left' class='data-row'>" +
                                item.name + " " + x.name +
                                "                                            </td>" +
                                "                                            <td width='25%' align='left' class='data-row'>" +
                                x._cnt +
                                "                                            </td>" +
                                "                                            <td width='25%' align='right' class='data-row'>"
                                + c_sign+ ( x._cnt * ( ItemPrice(item) + ItemPrice(x) ) ).toFixed(2)  +
                                "                                            </td>"
                            : 	null
                    ) )
                }

                if (item._type === "mod_ex")
                {
                    let _html = ""
                    Object.keys(item._opts).map((opt_key, idx) => {
                        const opt_obj = item._opts[opt_key];

                        if (opt_obj._cnt)
                        {
                            if (opt_obj.selSecMods.length)
                            {
                                opt_obj.selSecMods.map((s_m, idx) => (
                                    s_m._cnt ? _html += "&emsp;" + " - " + s_m.obj.name + "<br>" : null

                                ))
                            }

                            if (opt_obj.selMod.length)
                            {
                                opt_obj.selMod.map((s_m, idx) => (
                                    s_m._cnt ? _html += "&emsp;" + " - " + s_m.obj.name + "<br>" : null
                                ))
                            }

                            if (opt_obj.selAddons.length)
                            {
                                opt_obj.selAddons.map((s_m, idx) => (
                                    s_m._cnt ? _html += "&emsp;" + " - " + s_m.obj.name + "<br>" : null
                                ))
                            }
                        }

                        html += "<tr>" +
                            "                                            <td width='50%' align='left' class='data-row'>" +
                            item.name +"<br>" + _html +
                            "                                            </td>" +
                            "                                            <td width='25%' align='left' class='data-row'>" +
                            item._cnt +
                            "                                            </td>" +
                            "                                            <td width='25%' align='right' class='data-row'>"
                            + c_sign+  ONT.GetTotal_ModEx( item, opt_obj ).toFixed(2)   +
                            "</td>";
                    })
                }

                if(item._type === "basic")
                {
                    html += "                                        <tr>" +
                        "                                            <td width='50%' align='left' class='data-row'>" +
                        item.name +
                        "                                            </td>" +
                        "                                            <td width='25%' align='left' class='data-row'>" +
                        item._cnt +
                        "                                            </td>" +
                        "                                            <td width='25%' align='right' class='data-row'>" +
                        c_sign+( item._cnt * item.price.def).toFixed(2)  +
                        "                                            </td>";
                }
            })
        })

        html += "                                        </tr>" +
            "                                    </table>" +
            "                                </td>" +
            "                            </tr>" +
            "                            <tr>" +
            "                                <td align='left' class='table-gap'>" +
            "                                    <table cellspacing='0' cellpadding='0' border='0' width='100%'>" +
            "                                        <tr>" +
            "                                            <td width='75%' align='left' class='data-table-bold'>" +
            "                                                Subtotal" +
            "                                            </td>" +
            "                                            <td width='25%' align='right' class='data-table-bold'>" +
                                                           c_sign+ parseFloat(order_obj.invoice.SubTotal).toFixed(2) +
            "                                            </td>" +
            "                                        </tr>" +
            "                                    </table>" +
            "                                </td>" +
            "                            </tr>" ;


        if(props.__checkout.ListCharges().length)
        {
            props.__checkout.ListCharges().map( ch =>  {
                // // console.log("ch",ch);
                if(ch.amount && ch.type !=='subtotal' )
                {
                    html +=
                        "                            <tr>" +
                        "                                <td align='left'>" +
                        "                                    <table cellspacing='0' cellpadding='0' border='0' width='100%'>" +
                        "                                        <tr>" +
                        "                                            <td width='75%' align='left' class='data-row'>" +  ch.name +
                        "                                            </td>" +
                        "                                            <td width='25%' align='right' class='data-row'>" + c_sign + ch.amount +
                        "                                            </td>" +
                        "                                        </tr>" +
                        "                                    </table>" +
                        "                                </td>" +
                        "                            </tr>"


                }
            })
        }

        if(props.__checkout.ListDiscounts().length)
        {
            props.__checkout.ListDiscounts().map((disc) => !disc.amount ? null : (
                html +=
                    "<tr>" +
                    "    <td align='left'>" +
                    "         <table cellspacing='0' cellpadding='0' border='0' width='100%'>" +
                    "              <tr>" +
                    "                  <td width='75%' align='left' class='data-row'>" +
                    "                       Discount ("+  disc.type+ ")"+
                    "                  </td>" +
                    "                  <td width='25%' align='right' class='data-row'>" +
                    "                       (-)"+c_sign+disc.amount+
                    "                  </td>" +
                    "              </tr>" +
                    "        </table>" +
                    "     </td>" +
                    "</tr>"
            ))
        }

        html +=
            "<tr>" +
            "                                <td align='left' style='padding-top: 10px;'>" +
            "                                    <table cellspacing='0' cellpadding='0' border='0' width='100%'>" +
            "                                        <tr>" +
            "                                            <td width='75%' align='left' class='data-table-bold'>" +
            "                                                TOTAL" +
            "                                            </td>" +
            "                                            <td width='25%' align='right' class='data-table-bold'>" +
            c_sign+parseFloat(order_obj.invoice.Total).toFixed(2) +
            "                                            </td>" +
            "                                        </tr>" +
            "                                    </table>" +
            "                                </td>" +
            "                            </tr>" +
            "                        </table>" +
            "                        <!--[if (gte mso 9)|(IE)]>" +
            "                        </td>" +
            "                        </tr>" +
            "                        </table>" +
            "                        <![endif]-->" +
            "                    </td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td align='center' height='100%' valign='top' width='100%' class='fourth-section'  bgcolor='#ffffff'>" +
            "                        <!--[if (gte mso 9)|(IE)]>" +
            "                        <table align='center' border='0' cellspacing='0' cellpadding='0' width='600'>" +
            "                            <tr>" +
            "                                <td align='center' valign='top' width='600'>" +
            "                        <![endif]-->" +
            "                        <table align='center' border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width:660px;'>" +
            "                            <tr>" +
            "                                    <td align='left' valign='top' width='600'>" +
            "                                    <![endif]-->" +
            "                                    <div class='fourth-section-div-collection'>" +
            "                                        <table align='right' border='0' cellpadding='0' cellspacing='0' width='100%' class='fourth-section-width'>"
        ;

        if("card" == props.__checkout.payment.paymentType )
        {
            html += "                                            <tr>" +
                "                                                <td align='right' valign='top' class='second-table-td'>" +
                "                                                    <p class='title-heading'> Payment Type </p>" +
                "                                                    <p><span>Card</span></p>" +
                "                                                </td>" +
                "                                            </tr>"+
                "                                            <tr>" +
                "                                                <td align='right' valign='top' class='second-table-td'>" +
                "                                                    <p class='title-heading'> Card No </p>" +
                "                                                    <p>**** **** **** <span>" + props.__checkout.payment.creditInfo.card.last4 + "</span></p>" +
                "                                                </td>" +
                "                                            </tr>"
            ;
        }
        else
        {
            html += "                                            <tr>" +
                "                                                <td align='right' valign='top' class='second-table-td'>" +
                "                                                    <p class='title-heading'> Payment Type </p>" +
                "                                                    <p><span>Cash</span></p>" +
                "                                                </td>" +
                "                                            </tr>"
            ;
        }

        html+= "                                        </table>" +
            "                                    </div>" +
            "                                    <!--[if (gte mso 9)|(IE)]>" +
            "                                    </td>" +
            "                                    </tr>" +
            "                                    </table>" +
            "                                    <![endif]-->" +
            "                                </td>" +
            "                            </tr>" +
            "                        </table>" +
            "                        <!--[if (gte mso 9)|(IE)]>" +
            "                        </td>" +
            "                        </tr>" +
            "                        </table>" +
            "                        <![endif]-->" +
            "                    </td>" +
            "                </tr>" +
            "</table>"+
            "</td>"+
            "</tr>"+
            "</table>"+
            "</body>"

        // // console.log(html);

        let __html = "<!DOCTYPE html>" +
            "<html>" +
            "<head>" +
            "    <title></title>" +
            "    <meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\" />" +
            "    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1\">" +
            "    <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge\" />" +
            "    <style>" +
            "        /* MEDIA QUERIES */" +
            "        .body{" +
            "            margin: 0 !important;" +
            "            padding: 0 !important;" +
            "            background-color: #f5f5f5;" +
            "            font-family: Open Sans, Helvetica, Arial, sans-serif;" +
            "        }" +
            "        .table-shadow{" +
            "            -webkit-box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.75);"+
            "           -moz-box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.75);"+
            "            box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.75);"+
            "        }" +
            "        .main-table{" +
            "            max-width:600px;" +
            "            background-color: #34495e;" +
            "        }" +
            "        .table-header{" +
            "            padding: 35px 20px;font-size:0;" +
            "        }" +
            "        .first-section{" +
            "            display:inline-block; max-width:50%; min-width:100px; vertical-align:top; width:100%;" +
            "        }" +
            "        .first-section > table{" +
            "            max-width:300px;" +
            "        }" +
            "        .first-section > table >tr> td{" +
            "            font-size: 36px; font-weight: 800;" +
            "        }" +
            "        .system-name{" +
            "            font-size: 30px; font-weight: 800; margin: 0; color: #ffffff;" +
            "        }" +
            "        .first-section-left{" +
            "            display:inline-block; max-width:50%; min-width:100px; vertical-align:top; width:100%;" +
            "        }" +
            "        .first-section-left> table{" +
            "            max-width:300px;" +
            "        }" +
            "        .first-section-left-details{" +
            "            font-size: 16px; font-weight: 400; line-height: 30px;" +
            "        }" +
            "        .date-time {" +
            "            font-weight: 400; margin: 0; color: #ffffff;" +
            "        }" +
            "        .date-time-link{" +
            "            color: #ffffff; text-decoration: none;" +
            "        }" +
            "        .second-section{" +
            "            padding: 5px 20px 5px 20px; background-color: #ffffff;" +
            "        }" +
            "        .second-section-table{" +
            "            max-width:660px;" +
            "        }" +
            "        .order-td{" +
            "            font-size:0;" +
            "        }" +
            "        .second-section-text{" +
            "            display:inline-block; max-width:50%;width: 100%; vertical-align:top;" +
            "        }" +
            "        .table-width{" +
            "            max-width: 300px;" +
            "        }" +
            "        .second-table-td{" +
            "            font-size: 16px; font-weight: 400;" +
            "        }" +
            "        .title-heading{" +
            "            font-weight: 800;" +
            "        }" +
            "        .third-section{" +
            "            padding: 5px 20px 5px 20px;" +
            "            background-color: #ffffff;" +
            "        }" +
            "        .third-section-width{" +
            "            max-width:600px;" +
            "        }" +
            "        .third-section-thank{" +
            "            font-size: 30px; font-weight: 800; line-height: 36px; color: #333333; margin: 0;margin-bottom: 20px;" +
            "        }" +
            "        .third-section-text{" +
            "            font-size: 16px; font-weight: 400;" +
            "        }" +
            "        .data-table{" +
            "            padding-top: 20px;" +
            "        }" +
            "        .data-table-header{" +
            "            font-size: 16px; font-weight: 800;   padding: 10px;" +
            "        }" +
            "        .data-row{" +
            "            font-size: 16px; font-weight: 400;   padding: 15px 10px 5px 10px;" +
            "        }" +
            "        .table-gap{" +
            "            padding-top: 10px;" +
            "        }" +
            "        .data-table-bold{" +
            "            font-size: 16px; font-weight: 800;   padding: 10px; border-top: 3px solid #eeeeee; border-bottom: 3px solid #eeeeee;" +
            "        }" +
            "        .fourth-section{" +
            "            padding: 5px 20px 5px 20px; background-color: #ffffff;" +
            "        }" +
            "        .fourth-section-width{" +
            "            max-width: 300px;" +
            "        }" +
            "        .fourth-section-div{" +
            "            display:inline-block; max-width:50%;  vertical-align:top; width: 100%;" +
            "        }" +
            "        .fourth-section-div-collection{" +
            "            display:inline-block; vertical-align:top; width: 100%;" +
            "        }" +
            "        .last-section{" +
            "            padding: 5px; background-color: #1b9ba3;" +
            "        }" +
            "        .last-text{" +
            "            font-weight: 800; color: #ffffff!important;" +
            "        }" +
            "        .lastest-section{" +
            "            padding: 5px; background-color: #34495e;" +
            "        }" +
            "        /*!* ANDROID CENTER FIX *!*/" +
            "        /*div[style*=\"margin: 16px 0;\"] { margin: 0 !important; }*/" +
            "    </style>" +
            "</head>"
        ;

        __html += html;
        __html += "</html>";

        return __html;
    }
    catch( err )
    {
        console.warn( 'checkout_invoice/Invoice_Process: err: ', err )
        return Promise.reject( err )
    }
}   // Invoice_Process

export { Invoice_Save, Invoice_Process }
