/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    // Container,
    // Button,
    Image
} from 'react-bootstrap'

// import {connect} from 'react-redux'
// import * as actions from '../../rdx/actions'

// import NavHdr from '../_common/nav_hdr'
import color from '../_common/colors.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlusSquare,
    // faTimes,faTrashAlt,faCheck
} from '@fortawesome/free-solid-svg-icons'

import '../../css/_common.css'

// import Item from './items'
// import Order_Cart from './cart'

// import ONT from './ont'

/**
 */
class MenuPage_Box extends React.PureComponent
{
    constructor(props)
    {
        super(props);

    }

    render()
    {
        const items = this.props.title.split(':')[1];
        const main = this.props.title.split(':')[0];
        // // console.log("aaaaa",a);
        // // console.log("bbbbb",b);
        return (
            <div
                className={this.props.className}
                style={this.props.style}
                key={this.props.key}
                onClick={this.props.onPress}
                id={this.props.id}
            >
                {
                    this.props.id ?
                        <div>
                            <div className="categoryItemLittle">{main}</div>
                            <div className="categoryItemTitle">{items}</div>
                            <div className="itemDescription">{this.props.desc}</div>
                        </div>
                    :
                    <div>
                        <div className="categoryTitle" style={{color: color.colors.light,}}>{items}</div>
						<div className="itemDescription">{this.props.desc}</div>
                    </div>
                }
                <div>{this.props.item}</div>
            </div>
        )
    }
}
class Item_Box extends React.PureComponent
{
    constructor(props)
    {
        super(props)
    }

    render()
    {
        return (
            <div className="singleItem"
                 style={{borderColor:color.colors.borderLight, }}
                 onClick={this.props.onPress}
            >
                <div className="commonFlex">
                    {/*{*/}
                        {/*this.props.count ?*/}
                            {/*<FontAwesomeIcon*/}
                                {/*style={{margin:"4px 8px"}}*/}
                                {/*icon={faCheck} size="md" color="#43A047"/>*/}
                            {/*: null*/}
                    {/*}*/}
                    <div className="imageContainer">
                        <Image src={require('../assets/img/placeholder/skewer.png')} className="imageStyle"/>
                    </div>
                    <div style={{flex: 1}}>
                        <div className="commonFlex">
                            <div className="itemName">{this.props.itemName}</div>
                            {
                                this.props.count ?
                                    <div className="orderCount">
                                        {this.props.count}
                                    </div> :
                                    null
                            }
                        </div>
                        <div className="itemDescription">
                            {this.props.description}
                        </div>
                    </div>
                    {
                        this.props.modItem ?
                            <div>
                                {this.props.modItem}
                            </div> :
                            null
                    }
                    <div className="menuRight">
                        {
                            this.props.itemPrice ?
                            <div className="itemPrice">£{this.props.itemPrice}
                            </div> :
                            null
                        }
                        <div className="addtoCart"
                             // onClick={this.props.onPress}
                        >
                            <FontAwesomeIcon icon={faPlusSquare} size="lg"
                                             color={color.colors.primary}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {MenuPage_Box,Item_Box}


